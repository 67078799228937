import * as React from 'react'
import home_bg from "../../../../assets/icons/landing/homeBG.webp"
import { Button, shorthands, makeStyles, useId, useToastController, ToastTitle, Toast, Tooltip } from '@fluentui/react-components'
import { flyghtCloudData } from '../../common/FlyghtCloudData'
import FlyghtCloudCard from './FlyghtCloudCard'
import { WorksData } from '../../common/WorksData'
import OneClickReq from './OneClickReq'
import HeaderNavBar from '../../common/HeaderNavBar';
import flyghtcloud_logo from "../../../../assets/icons/landing/logo_white.svg"
import { AuthService } from '../../../../services/openapi'
import ToastErrorIcon from "../../../../assets/icons/toast_error.svg";
import { AuthContext } from '../../../../AuthContext'
import BookDemoModal from '../../common/BookDemoModal'
import { useNavigate } from 'react-router-dom'
import Footer from '../../common/Footer'
import processPoster from "../../../../assets/icons/landing/processPoster.svg"
import floatBtn from "../../../../assets/icons/landing/floatBtn.svg"
import { DataStrategy } from '../../common/DataStrategyData'
import DataStrategyCard from './DataStrategyCard'
import { HowWorksData } from './common/HowWorks'
import HowWorks from './HowWorks'
import BrandName from '../../../common/BrandName'
interface flyghtCloudDataProps {
    id: number,
    title: string,
    icon: string,
    info: string
}
interface worksDataProps {
    id: number,
    title: string,
    icon: string,
    info: string
}
interface CarouselDataProps {
    id: number,
    title: string,
    icon: string,
    info: string,
    tags: string[]
}
function Home() {
    const classes = useStyles();
    const navigate = useNavigate();
    const loggedMe = React.useContext(AuthContext);
    const toasterId = useId("toaster");
    const timeout = 2000;
    const { dispatchToast } = useToastController(toasterId);
    const [flyghtCloudDatas, setFlyghtCloudDatasProps] = React.useState<flyghtCloudDataProps[] | null>(null);
    const [worksDatas, setWorksDatas] = React.useState<worksDataProps[] | null>(null);
    const [howWorks, setHowWorks] = React.useState<worksDataProps[] | null>(null);
    const [bookDemoModalVisible, setBookDemoModalVisible] = React.useState(false);
    const [isLoginDisabled, setIsLoginDisabled] = React.useState(false);
    const [dataStrategyDatas, setDataStrategyDatas] = React.useState<CarouselDataProps[] | null>(null);

    const endRef = React.useRef<null | HTMLDivElement>(null)

    const scrollToTop = () => {
        endRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    React.useEffect(() => {
        setFlyghtCloudDatasProps(flyghtCloudData);
        setWorksDatas(WorksData);
        setDataStrategyDatas(DataStrategy);
        setHowWorks(HowWorksData)
    }, []);


    const notifyError = (err: string) => {
        dispatchToast(
            <Toast
                style={{ background: "#FDE7E9" }}>
                <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}
                </ToastTitle>
            </Toast>,
            { timeout, intent: "error" }
        );
    }

    const login = (d: any) => {
        setIsLoginDisabled(true)
        AuthService.login(d)
            .then((res) => {
                loggedMe.setMe(res)
                setIsLoginDisabled(false)
                if (res.token) {
                    navigate(`/dashboard`);
                }
            })
            .catch((err) => {
                console.table(err)
                notifyError("Either email or password is invalid")
                setTimeout(() => {
                    setIsLoginDisabled(false)
                }, timeout);
            })
    }
    const toggleBookDemoModal = (flag: boolean) => {
        setBookDemoModalVisible(flag)
    }
    return <div style={{ position: "relative" }} ref={endRef}>
        <div className={classes.bgContainer}>
            <div className={classes.headerNavBar}>
                <HeaderNavBar toasterId={toasterId} icon={flyghtcloud_logo} color='#FFFFFF' login={login} btnStatus={isLoginDisabled} />
            </div>
            <div className="legend" style={{ display: "flex", alignItems: "center", height: "85%" }}>
                <div style={{
                    display: "flex", flexDirection: "column", justifyContent: "space-between", margin: "0 auto", width: "85%", maxWidth: "100%", maxHeight: "100%", height: "70%"
                }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                        <div>
                            <h1 className={classes.barndingLabel}>FLYGHT</h1>
                            <h1 className={classes.barndingLabel} style={{ backgroundImage: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>CLOUD</h1>
                        </div>
                        <div style={{ display: "flex", justifyContent: "end", alignItems: "start" }}>
                            <h3 className={classes.barndingInfo}>Enhance your drone data’s workflow and unlock unparalleled insights across your asset lifecycle with <BrandName />. </h3>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "start" }}>
                        <Button shape="circular" className={classes.gradBtn} onClick={() => { toggleBookDemoModal(true) }}
                            style={{
                                backgroundColor: "white",
                                color: "transparent",
                                backgroundImage: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)",
                                WebkitBackgroundClip: "text",
                                backgroundClip: "text",
                            }}
                        >  Connect to Expert</Button>
                        <Button shape="circular" className={classes.gradBtn} onClick={() => { navigate(`/become-Provider`); }} style={{ background: "linear-gradient(90deg, #AAD824 0%, #2ABCD0 100%)", color: "white", border: "none", marginLeft: "1.5rem" }}> Become a Provider</Button>
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.commonConatainer} style={{ margin: "0 auto" }} >
            <h3 className={classes.initialHeading}>One platform, infinite possibilities.<br />Unlock the full potential of drone data intelligence.</h3>
            <div className={classes.commonRow}>
                <img src={processPoster} alt='picture' />
                <p style={{ width: "80%", fontSize: "1.3vw", lineHeight: "1.8vw", margin: "1em auto" }}>Simplify your drone data management with <BrandName />, eliminating the complexities of multiple vendors, regulations, and inefficient workflows. Access expert operators seamlessly through our unified platform.</p>
            </div>
            <div className={classes.commonRow}>
                <h2 className={classes.primaryHeading}>Why choose <span> FLYGHT CLOUD?</span></h2>
                {flyghtCloudDatas && (
                    <div className={classes.grid} >
                        {flyghtCloudDatas.map((data, index) => (
                            <div key={index} style={{ width: "50%", marginTop: "1em" }} >
                                <FlyghtCloudCard key={index} flyghtData={data} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={classes.commonRow}>
                <h2 className={classes.primaryHeading}>How it works?</h2>
                {howWorks && (
                    <div className={classes.woksgrid}>
                        {howWorks.map((data, index) => (
                            <div key={index} style={{ width: "24%", marginTop: "2em" }}>
                                <HowWorks key={index} worksData={data} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={classes.commonRow}>
                <h2 className={classes.primaryHeading}>Only one click required.<span> We understand your needs. </span></h2>
                {worksDatas && (
                    <div className={classes.grid} >
                        {worksDatas.map((data, index) => (
                            <div key={index} style={{ width: "50%", marginTop: "1em" }} >
                                <OneClickReq key={index} worksData={data} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={classes.commonRow}>
                <h2 className={classes.primaryHeading}>Elevate your data strategy with <span>tailored solution </span></h2>
                {dataStrategyDatas && (
                    <div className={classes.woksgrid}>
                        {dataStrategyDatas.map((data, index) => (
                            <div key={index} style={{ width: "24%", marginTop: "2em" }}>
                                <DataStrategyCard key={index} dataStrategy={data} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={classes.commonRow}>
                <h2 className={classes.primaryHeading}>Ready to take <span>flight?</span></h2>
                <p className={classes.footerInfo}>Sign in to your <BrandName /> account or book a demo to experience the future of drone data analytics firsthand. Whether you're a seasoned professional or just starting your journey with drone data, <BrandName /> has the tools and expertise to support your success.</p>
                <div className={classes.footerBtns}>
                    <Button onClick={() => { toggleBookDemoModal(true) }} className={classes.footerBtn} style={{
                        background: "#617AFA", padding: "10px", width: "20%", borderRadius: "4px", color: "#FAFAFA", border: "none"
                    }}>Connect to Expert</Button>
                </div>
            </div>
            <div className={classes.commonRow} style={{ marginTop: "7em" }}>
                <Footer />
            </div>
        </div >
        <div style={{ position: "fixed", bottom: "1%", right: "3%" }}>
            <Tooltip content="Scroll to top" relationship="label">
                <img src={floatBtn} onClick={scrollToTop} alt='scroll top' style={{ cursor: "pointer" }} />
            </Tooltip>
        </div>
        <BookDemoModal visible={bookDemoModalVisible} toggleModal={toggleBookDemoModal} />
    </div >
}

const useStyles = makeStyles({
    bgContainer: {
        backgroundImage: `url(${home_bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        color: "#ffff",
        height: 'calc(100vh - 4rem)',
    },
    bgTextContainer: {
        width: "75%",
        maxWidth: "100%",
        maxHeight: "60%",
        height: "60%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        zIndex: "9999",
        backgroundColor: "gray"
    },
    commonConatainer: {
        width: "75%",
        maxWidth: "100%",
        paddingTop: ".5em",
        paddingBottom: ".5em",
    },
    bgBtns: {
        color: "#617AFA",
        backgroundColor: "#FFFFFF",
        ':hover': {
            color: "#617AFA",
            boxShadow: "-1px 1px 4px 4px #7C7BFB80"
        },
    },
    footerBtn: {
        ...shorthands.borderRadius("0"),
    },
    primaryHeading: {
        fontSize: "3vw",
        color: "#000000",
        lineHeight: "2.5rem",
        fontWeight: 600,
        "> span": {
            color: "#95C114",
        },
    },
    bdPrimaryHeading: {
        fontSize: "2rem",
        fontWeight: 600,
        marginTop: "4rem",
        marginBottom: "1rem"
    },
    grid: {
        display: "flex",
        flexWrap: "wrap",
        width: "95%",
        marginTop: "4em",
        justifyContent: "space-between"
    },
    woksgrid: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "3em",
        width: "100%",
        justifyContent: "space-between"
    },
    footerConatainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "90%",
        width: "62%",
        textAlign: "center",
        paddingBottom: "3em",
        marginTop: "6em !important"
    },
    footerBtns: {
        marginTop: "2.5em",
        marginBottom: "2em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    footerInfo: {
        fontSize: "1.3vw",
        fontWeight: 400,
        marginTop: "1.5em",
        lineHeight: "1.5vw",
        color: "#000000"
    },
    barndingLabel: {
        fontSize: "7vw",
        lineHeight: "6vw",
        fontWeight: "700"
    },
    barndingInfo: {
        fontSize: "1.8vw",
        fontWeight: "500",
        maxWidth: "70%",
        lineHeight: "2.5vw",
        paddingTop: ".2em",
    },

    initialHeading: {
        fontSize: "2.7vw",
        fontWeight: "400",
        lineHeight: "3.5vw",
        textAlign: "center",
    },
    commonRow: {
        marginTop: "7em",
        marginBottom: "7em",
        color: "#747386",
    },
    gradBtn: {
        ...shorthands.padding("15px"),
        fontSize: "1.2vw",
        width: "18%",
    }
})

export default Home