import { makeStyles, mergeClasses, tokens } from "@fluentui/react-components";

interface Props {
    status?: string | JSX.Element | JSX.Element[];
    byLine?: string | JSX.Element | JSX.Element[];
    children: string | JSX.Element | JSX.Element[];
}

const useStyles = makeStyles({
    infoItem: {
        paddingLeft: '1em',
        paddingRight: '1em',
        paddingTop: '1em',
        paddingBottom: '1em',
        // marginBottom: '1em',
    },
    infoTitle: {
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'space-between',
    },
    infoDetail: {
    },
    infoFooter: {
        fontWeight: '200',
        fontSize: '0.8em'
    },
});

export const ListCard = ({ status, byLine, children }: Props) => {
    const classes = useStyles();
    return <div className={mergeClasses(classes.infoItem)} style={{ borderBottom: `${tokens.colorNeutralBackground2}` }}>
        <div className={mergeClasses(classes.infoTitle)}>
            {children}
        </div>
        <div className={mergeClasses(classes.infoDetail)}>{status}</div>
        <div className={mergeClasses(classes.infoFooter)}>{byLine}</div>
    </div>
}
