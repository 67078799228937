import { makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { NavLink } from 'react-router-dom';
import dashboardIcon from '../assets/icons/nav_icons/dashboard.svg';
import discoverApps_icon from "../assets/icons/nav_icons/discover-apps.svg";
import projectsIcon from '../assets/icons/nav_icons/projects.svg';
import supportIcon from '../assets/icons/nav_icons/support.svg';
import workflowsIcon from '../assets/icons/nav_icons/workflows.svg';

const navItems = [
    {
        href: "/dashboard",
        name: "Dashboard",
        icon: dashboardIcon,
    },
    {
        href: "/projects",
        name: "Projects",
        icon: projectsIcon,
    },
    {
        href: "/workFlow-library",
        name: "Workflow Library",
        icon: workflowsIcon,
    },
    {
        href: "/discover-apps",
        name: "Discover Apps",
        icon: discoverApps_icon,
    },
    {
        href: "/support",
        name: "Support",
        icon: supportIcon,
    },
];

export default function NavigationNew() {
    const classes = useStyles();

    return <div className={classes.navContainer}>
        {
            navItems.map(item =>
                <NavLink to={item.href} className={({ isActive }) => mergeClasses(classes.navLinkContainer, isActive && classes.activeNavLink)} key={item.href}>
                    <img src={item.icon} alt={item.name} />
                    {item.name}
                </NavLink>
            )
        }
    </div>
};


const useStyles = makeStyles({
    navContainer: {
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
        paddingTop: "0.25rem",
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("0.25rem"),
    },
    navLinkContainer: {
        display: "flex",
        alignItems: "center",
        ...shorthands.gap("0.5rem"),
        ...shorthands.textDecoration("none"),
        fontSize: "1rem",
        lineHeight: "1.25rem",
        height: "2rem",
        ...shorthands.padding("6px", "0px"),
        paddingLeft: "0.75rem",
        color: "black",
        '> img': {
            width: "24px",
            height: "24px",
        },
        ':hover': {
            backgroundColor: "#E8EAED",
        },
        ...shorthands.borderRadius("0.5rem"),
    },
    activeNavLink: {
        backgroundColor: "#E5E4FC",
        ...shorthands.borderRadius("0.5rem"),
        ':hover': {
            backgroundColor: "#E5E4FC",
        },
    }
});
