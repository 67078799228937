import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, Toolbar } from '@fluentui/react-components';
import { Search24Regular } from '@fluentui/react-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { LineItemResponse, WalletService } from '../services/openapi';
import { getTimestampTime } from '../utils/datetime';
import { Filter } from './Filter';
import { Header } from './Header';

export const Transactions = () => {
    const [credits, setCredits] = React.useState(450);
    const [items, setItems] = React.useState<LineItemResponse[] | null>(null);
    const [lineItemType, setTypeFilter] = React.useState("");
    const [search, setSearch] = React.useState('');
    const [from, setFrom] = React.useState('')
    const [to, setTo] = React.useState('')
    const [fromError, setFromError] = React.useState(false)
    const [toError, setToError] = React.useState(false)

    React.useEffect(() => {
        WalletService.getWalletInfo()
            .then((res) => { setCredits(res.balance) });
        WalletService.getLineItems()
            .then((res) => { setItems(res.lineItems) });
    }, [])

    const stripTime = (date: Date): Date => {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }

    const isAfterOrEqual = (a: Date, b: Date): boolean => {
        let A = stripTime(a);
        let B = stripTime(b);
        return A >= B;
    }

    const isBeforeOrEqual = (a: Date, b: Date): boolean => {
        let A = stripTime(a);
        let B = stripTime(b);
        return A <= B;
    }

    const clear = () => {
        setSearch('');
        setFrom('');
        setTo('');
        setTypeFilter('');
    }

    const inputButtonstyle = {
        backgroundColor: "#FFFFFF",
        borderRadius: "0.2em",
        borderColor: "#00000006"
    };

    const columns = [
        { label: "Date&Time" },
        { label: "Invoice No" },
        { label: "Type" },
        { label: "Amount" },
    ];

    const validateFromDate = (e: any) => {
        if (!e.target.value) {
            setFromError(true);
        }
    }

    const validateToDate = (e: any) => {
        console.log(e.target.value);
        if (!e.target.value) {
            setToError(true);
        }
    }

    return <div style={{ height: "88vh", width: "100%" }}>
        <Header>
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <Link to="/dashboard" style={{ cursor: "pointer", textDecoration: "none", color: "black", fontSize: "14px" }}>Dashboard</Link>  {`> Transaction History`}
                </div>
            </div>
        </Header>
        <div
            style={{
                width: '98%',
                background: '#FDFDFD',
                borderRadius: '1em',
                padding: '0.5em',
                display: 'flex',
                flexDirection: 'column',
                margin: '1em',
                height: "100%",
                boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
            }}
        >
            <div style={{ marginBottom: "12px" }}>
                <div className='HeadingRow'
                    style={{
                        display: 'flex', flexDirection: 'row',
                        margin: '1em', alignItems: 'end',
                        justifyContent: 'space-between',
                    }}>
                    <div style={{ whiteSpace: "nowrap", margin: '0.5em', fontSize: '1.75em', fontWeight: '600' }} >Transaction History</div>
                    <div style={{
                        marginLeft: '3em', borderRadius: '0.5em',
                        backgroundColor: '#fbe6f3', minWidth: '10em', textAlign: 'center',
                        fontSize: '1.75em', lineHeight: '1.75em', fontWeight: '600', paddingLeft: '1.2em', paddingRight: '1.2em', paddingTop: '0.19em', paddingBottom: '0.13em'
                    }}> Credit Balance:  {credits}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div onClick={() => { setSearch("") }} style={{ cursor: "pointer" }}><Filter /></div>
                        <Toolbar style={{
                            borderRadius: '0.5em', display: 'flex',
                            height: "2.25em", marginLeft: '1.5em',
                            backgroundColor: "#FAFAFA", padding: "0px",
                            marginTop: "0px", marginRight: '1.5em',
                        }}>
                            <input onChange={(e) => setSearch(e.target.value)}
                                value={search || ""}
                                placeholder='Type or Invoice ID' width='100%' height='100%'
                                style={{ backgroundColor: '#FAFAFA', borderWidth: '0px', paddingRight: '2em' }} />
                            <div style={{
                                width: '1.5em', height: '1.5em', borderWidth: '0px',
                                marginRight: '0px', backgroundColor: "#00000000", color: "#8E8E8E"
                            }}>
                                <Search24Regular />
                            </div>
                        </Toolbar>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '1em',
                        alignItems: 'end',
                        justifyContent: 'space-between'
                    }}>
                    <div
                        style={{
                            marginLeft: "0.5em",
                            display: "grid",
                            gap: "1.12em 1.75em",
                            gridTemplate: "2.25em /9.5em 9.5em 9.5em",
                            justifyContent: 'space-between'
                        }}>
                        <div style={{ padding: "0.5em" }}>From</div><div>To</div><div>Type</div>
                        <input onClick={() => { setFromError(false); }} value={from} type='date' style={{ ...inputButtonstyle, borderColor: fromError ? "#EC2A00" : "#00000006" }} onChange={(e) => { setFrom(e.target.value); setFromError(false) }} onBlur={validateFromDate} />
                        <input onClick={() => { setFromError(false); }} value={to} type='date' style={{ ...inputButtonstyle, borderColor: toError ? "#EC2A00" : "#00000006" }} onChange={(e) => { setTo(e.target.value); setToError(false) }} min={from} disabled={!from} onBlur={validateToDate} />
                        <select onChange={(e) => setTypeFilter(e.target.value)} value={lineItemType}
                            style={{
                                backgroundColor: "#FFFFFF",
                                borderRadius: "0.2em",
                                borderColor: "#00000006",
                                width: "20em",
                            }}>
                            <option value="">All</option>
                            <option value="image_storage">IMAGE STORAGE</option>
                            <option value="workflow_storage">WORKFLOW STORAGE</option>
                            <option value="view_storage">VIEW STORAGE</option>
                            <option value="plugin_invocation">Plugins</option>
                        </select>
                    </div>


                    <button onClick={() => clear()}
                        style={{
                            maxHeight: "2.25em", backgroundColor: "#FDFDFD70",
                            padding: "0.5em 1em", alignItems: "center", borderRadius: "0.25em",
                            border: "1px solid #606060", cursor: "pointer"
                        }}>Clear</button>
                </div>
            </div>
            <div style={{ width: "100%", overflowY: "scroll", overflowX: "hidden" }}>
                <Table arial-label="Default table" size='medium'>
                    <TableHeader>
                        <TableRow>
                            {columns.map((column) => (
                                <TableHeaderCell key={column.label}>
                                    {column.label}
                                </TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {items && items.filter((item) =>
                            (item.invoiceId.includes(search) || item.itemDescription.includes(search))
                            && item.itemTypeId.includes(lineItemType)
                            && ((from && isAfterOrEqual(getTimestampTime(item.createdAt), new Date(from))) || !from)
                            && ((to && isBeforeOrEqual(getTimestampTime(item.createdAt), new Date(to))) || !to)
                        ).map((item) => (
                            <TableRow key={item.id}>
                                <TableCell> {item.createdAt.split('T')[0]}</TableCell>
                                <TableCell>{item.invoiceId}</TableCell>
                                <TableCell>{item.itemDescription}</TableCell>
                                <TableCell><div style={{ width: "100%", display: "grid", gridTemplateColumns: "auto 2em" }}>
                                    <div>{item.transactionType === "credited" ? "+" : "-"}{item.amount}</div>
                                </div>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </div>
        </div>
    </div >
};