import { Button, Dialog, DialogBody, DialogContent, DialogSurface, Field, Input, Label, Textarea, makeStyles, shorthands } from "@fluentui/react-components";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUTMParams from "../../hooks/useUTMParams";
import { LeadRequest, ZohoService } from "../../services/openapi";

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    onSubmit: (f: TalkToExpertForm) => void;

}
interface TalkToExpertForm {
    fullName: string;
    email: string;
    phoneNumber: string;
    customMessage: string;
}


const TalkToExpertModal = (props: ModalProps) => {
    const classes = useStyles()
    const navigate = useNavigate();
    const { visible, toggleModal } = props;
    const [isValidMail, setIsValidMail] = React.useState(false);
    const [data, setData] = React.useState({ fullName: '', email: '', phoneNumber: "", customMessage: "" });
    const location = useLocation();
    const utmParams = useUTMParams();

    const createLead = (formValues: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "Talk to expert - Discover Apps",
                    "Last_Name": formValues.fullName,
                    "Email": formValues.email,
                    "Phone": formValues.phoneNumber,
                    "Description": formValues.customMessage,
                    "Form_Page_Url": `https://flyghtcloud.ideaforgetech.com${location.pathname}`,
                    "Form_Name": "Talk to expert - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            console.log("Lead created successfully")
        }).catch(err => console.error("Error creating lead", err))
    }

    React.useEffect(() => {
        setData({ ...data, fullName: '', email: '', phoneNumber: "", customMessage: "" });
        setIsValidMail(false)
    }, [visible]);


    const handleSubmit = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidMail(!emailPattern.test(data.email));
        if (emailPattern.test(data.email)) {
            createLead(data)
            props.onSubmit(data);
        }
    }
    return (
        <Dialog open={visible} >
            <DialogSurface aria-describedby={undefined} className={classes.modalBody}>
                <form onSubmit={(e) => { e.preventDefault() }}>
                    <DialogBody className={classes.modalBody} style={{ width: "100%", padding: 0 }}>
                        <DialogContent className={classes.content}>
                            <div>
                                <Label required htmlFor={"email-input"}>
                                    Full Name
                                </Label>
                                <Input className={classes.input}
                                    appearance="outline"
                                    size="large"
                                    placeholder="Type here..."
                                    value={data.fullName}
                                    onChange={(e) => {
                                        setData({ ...data, fullName: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <Label required htmlFor={"email-input"}>
                                    Email
                                </Label>
                                <Field validationMessageIcon={null} validationMessage={isValidMail ? "Incorrect email address" : null}>
                                    <Input
                                        style={{
                                            width: '100%',
                                            border: isValidMail ? "1px solid red" : "1px solid #D1DBE8",
                                            padding: "8px",
                                            marginTop: ".1em",
                                            marginBottom: ".1em"
                                        }}
                                        appearance="outline"
                                        size="large"
                                        placeholder="Type here..."
                                        value={data.email}
                                        onChange={(e) => {
                                            setIsValidMail(false)
                                            setData({ ...data, email: e.target.value })
                                        }}
                                    />
                                </Field>
                            </div>
                            <div className={classes.inputContainer}>
                                <Label required htmlFor={"email-input"}>
                                    Phone Number
                                </Label>
                                <input
                                    className={classes.input}
                                    style={{ fontSize: "16px", padding: "9px 18px", borderRadius: "8px" }}
                                    type="tel"
                                    placeholder="Type here..."
                                    value={data.phoneNumber}
                                    onChange={(e) => {
                                        setData({ ...data, phoneNumber: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <Label required htmlFor={"email-input"}>
                                    Type custom message
                                </Label>
                                <Textarea className={classes.input}
                                    appearance="outline"
                                    size="large"
                                    placeholder="Type here..."
                                    value={data.customMessage}
                                    onChange={(e) => {
                                        setData({ ...data, customMessage: e.target.value })
                                    }}
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <Label style={{ color: "#000000" }}>
                                    Flyght Cloud uses the information you provide us to process your request, and occasionally contact you about relevant content and services. You can unsubscribe any time. For more information, check out our <span style={{ color: "#1570EF", cursor: "pointer" }} onClick={() => { navigate(`/privacy-policy`) }}> Privacy Policy.</span>
                                </Label>
                            </div>
                            <div className={classes.inputContainer} style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button onClick={() => {
                                    setData({ ...data, fullName: '', email: '', phoneNumber: "", customMessage: "" });
                                    toggleModal(false);
                                }} className={classes.commonBtn} appearance="secondary">Cancel</Button>
                                <Button onClick={handleSubmit} style={{ background: "#5E5CE6", color: "#ffff" }} className={classes.commonBtn} disabled={!data.fullName || !data.email || !data.phoneNumber || !data.customMessage} type="submit" appearance="primary">
                                    Submit
                                </Button>
                            </div>
                        </DialogContent>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog >
    );
};
const useStyles = makeStyles({
    dialogContainer: {
        '& .r1mol2m5': {
            backgroundColor: "red !important",
            position: "fixed",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
    input: {
        width: '100%',
        marginTop: ".5em",
        ...shorthands.border("1px", "solid", "#E5E5E5"),
        ...shorthands.borderRadius('8px')
    },
    inputContainer: {
        marginTop: ".5em"
    },
    modalBody: {
        width: "550px",
        ...shorthands.padding('2em'),
        '& .r1mol2m5': {
            maxWidth: "550px !important"
        }
    },
    commonBtn: {
        width: "48%",
        ...shorthands.padding("8px 24px"),
        ...shorthands.borderRadius("8px")
    }


})

export default TalkToExpertModal;