import { Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Toast, ToastTitle, Toaster, makeStyles, mergeClasses, shorthands, useId, useToastController } from "@fluentui/react-components";
import { useEffect, useRef, useState } from "react";
import ArrowDownIcon from "../assets/icons/arrow_down.svg";
import InfoIcon from "../assets/icons/info.svg";
import MapShareIcon from "../assets/icons/map_share.svg";
import ToastSuccessIcon from "../assets/icons/toast_success.svg";
import { FlightResponse, ViewsService } from "../services/openapi";
import { PrimaryButton2 } from "./common/PrimaryButton2";

interface Props {
    flight?: FlightResponse;
    shareID: string | null;
    layerID: string | null;
    closeShare: () => void
}

export function MapShare(props: Props) {
    const { flight, shareID, layerID, closeShare } = props;
    const [shareExpire, setShareExpire] = useState("48 hours");
    const successToastID = useId("success");
    const { dispatchToast: dispatchSuccessToast } = useToastController(successToastID);
    const shareContainerRef = useRef<HTMLDivElement>(null);
    const [dropwdownSelected, setDropdownSelected] = useState(false);

    const classes = useStyles();

    const handleClick = (e: any) => {
        if (shareContainerRef.current && !shareContainerRef.current.contains(e.target) && !dropwdownSelected) {
            closeShare();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        setTimeout(() => {
            if (dropwdownSelected) {
                setDropdownSelected(false)
            }
        }, 100)
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [dropwdownSelected]);

    const successToast = (message: string) => dispatchSuccessToast(
        <Toast
            style={{ background: "rgba(223, 246, 221, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastSuccessIcon} alt="success icon" style={{ marginRight: "0.25rem" }} />}>{message}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const updateShareExpiryTime = (time: number, text: string) => {
        if (shareID && layerID) {
            ViewsService.updateExpiryTime(layerID, {
                shareId: shareID,
                validTime: time,
            }).then(() => {
                setShareExpire(text);
            }).catch(err => console.error("Error updating expiry time", err))
        } else {
            console.error("No Share ID found!")
        }
        setDropdownSelected(true);
    }

    return <>
        <div className={classes.shareContainer} ref={shareContainerRef}>
            <img src={MapShareIcon} alt="share" className={classes.shareImage} />

            <div className={classes.shareContentContainer}>
                <div className={classes.shareHeader}>{flight ? `Share ${flight?.name} map` : `Share map`}</div>
                <div className={classes.shareSubHeader}>Share this map with anyone by sending them a link. </div>
            </div>

            <div className={classes.shareContentContainer}>
                <div className={classes.shareSubHeaderBold}>Access to this map</div>

                {/* Commenting access dropdown for now */}
                {/* <Menu>
                    <MenuTrigger>
                        <div className={classes.shareAccessDropDownContainer}>
                            <div className={classes.shareAccessDropdownContent}>
                                <img src={ShareAnyoneIcon} alt="share anyone" />
                                <div className={classes.shareSubHeader}>Anyone with the link</div>
                            </div>
                            <img src={DropdownDownIcon} alt="down" />
                        </div>
                    </MenuTrigger>

                    <MenuPopover>
                        <MenuList style={{ width: "400px" }}>
                            <MenuItem>
                                <div className={classes.shareAccessDropdownContent}>
                                    <img src={ShareAnyoneIcon} alt="share anyone" />
                                    <div className={classes.shareSubHeader}>Anyone with the link</div>
                                </div>
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu> */}

                <div className={mergeClasses(classes.shareSubHeader, classes.shareInfoContianer)}> <img src={InfoIcon} alt="share" />
                    Anyone with link, even outside your organisation, can view this map
                </div>
            </div>

            <div className={classes.shareContentContainer}>
                <div className={classes.shareSubHeaderBold}>Your map’s public link</div>
                <div className={classes.shareLinkContainer}>
                    <p className={classes.shareSubHeader}>{`${window.location.host}/share/${shareID}`}</p>
                    <PrimaryButton2 label="Copy" onClick={() => {
                        navigator.clipboard.writeText(`${window.location.host}/share/${shareID}`);
                        if (flight && flight.name) {
                            successToast(`${flight.name} map link copied successfully`);
                        } else {
                            successToast("Map link copied successfully");
                        }
                    }} />
                </div>
            </div>

            <div className={classes.shareContentContainer}>
                <div className={classes.shareSubHeaderBold}>Public map settings</div>
                <div className={classes.shareExpireContainer} >
                    <p className={classes.shareSubHeader}>Map Link expires after</p>
                    <Menu onOpenChange={() => { setDropdownSelected(false); }}>
                        <MenuTrigger >
                            <div className={classes.expireDropDownContainer}>
                                <div className={classes.expireDropDownText}>{shareExpire}</div>
                                <img src={ArrowDownIcon} alt="down" />
                            </div>
                        </MenuTrigger>

                        <MenuPopover >
                            <MenuList>
                                <MenuItem onClick={() => { updateShareExpiryTime(24, "24 Hours") }}>24 Hours</MenuItem>
                                <MenuItem onClick={() => { updateShareExpiryTime(48, "48 Hours") }}>48 Hours</MenuItem>
                                <MenuItem onClick={() => { updateShareExpiryTime(24 * 7, "1 Week") }}>1 Week</MenuItem>
                                <MenuItem onClick={() => { updateShareExpiryTime(24 * 14, "2 Week") }}>2 Weeks</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div>
            </div>
        </div>
        <Toaster toasterId={successToastID} position="bottom" limit={1} />
    </>
}

const useStyles = makeStyles({
    shareContainer: {
        width: "75%",
        padding: "32px",
        display: 'flex',
        flexDirection: "column",
        ...shorthands.gap("40px"),
        overflowY: "auto"
    },
    shareImage: {
        width: "48px",
        height: "48px",
    },
    shareHeader: {
        color: "#000",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "28px"
    },
    shareContentContainer: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("8px"),
    },
    shareSubHeader: {
        color: "#000",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px"
    },
    shareSubHeaderBold: {
        color: "#000",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px"
    },
    shareInfoContianer: {
        display: "flex",
        ...shorthands.gap("8px"),
    },
    shareLinkContainer: {
        display: "flex",
        padding: "8px",
        justifyContent: "space-between",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#F3F6F9",
    },
    shareExpireContainer: {
        display: "flex",
        alignItems: "center",
        ...shorthands.gap("16px"),
        ...shorthands.padding("24px", "16px"),
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "rgba(125, 143, 169, 0.20)")

    },
    expireDropDownContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ...shorthands.gap("24px"),
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "4px",
        paddingBottom: "4px",
        ...shorthands.borderRadius("3px"),
        ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.06)"),
        backgroundColor: "rgba(255, 255, 255, 0.70)",
    },
    expireDropDownText: {
        color: "rgba(0, 0, 0, 0.90)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px"
    },
    shareAccessDropDownContainer: {
        width: "400px",
        ...shorthands.padding("12px"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "rgba(125, 143, 169, 0.30)"),
        cursor: "pointer",
    },
    shareAccessDropdownContent: {
        display: "flex",
        ...shorthands.gap("16px"),
    },
})