/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MapLayerResponse } from '../models/MapLayerResponse';
import type { MapLayersResponse } from '../models/MapLayersResponse';
import type { MapShareResponse } from '../models/MapShareResponse';
import type { UpdateTimeRequest } from '../models/UpdateTimeRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ViewsService {

    /**
     * @param flightId
     * @returns MapLayersResponse Get Map Layers
     * @throws ApiError
     */
    public static getFlightViews(
        flightId: string,
    ): CancelablePromise<MapLayersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/flights/{flight_id}/map_layers',
            path: {
                'flight_id': flightId,
            },
        });
    }

    /**
     * @param shareId
     * @returns MapLayerResponse View Map Layers using share_id
     * @throws ApiError
     */
    public static viewSharedMap(
        shareId: string,
    ): CancelablePromise<MapLayerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/map_layers/view/{share_id}',
            path: {
                'share_id': shareId,
            },
        });
    }

    /**
     * @param layerId
     * @returns MapShareResponse Create share_id for Map share
     * @throws ApiError
     */
    public static createShareId(
        layerId: string,
    ): CancelablePromise<MapShareResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/map_layers/{layer_id}/share',
            path: {
                'layer_id': layerId,
            },
        });
    }

    /**
     * @param layerId
     * @param requestBody
     * @returns any Update expiry time for map share
     * @throws ApiError
     */
    public static updateExpiryTime(
        layerId: string,
        requestBody: UpdateTimeRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/map_layers/{layer_id}/share',
            path: {
                'layer_id': layerId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectId
     * @returns MapLayersResponse Get Map Layer
     * @throws ApiError
     */
    public static getProjectViews(
        projectId: string,
    ): CancelablePromise<MapLayersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/projects/{project_id}/map_layers',
            path: {
                'project_id': projectId,
            },
        });
    }

    /**
     * @param workflowId
     * @returns MapLayersResponse Get Map Layers
     * @throws ApiError
     */
    public static getWorkflowViews(
        workflowId: string,
    ): CancelablePromise<MapLayersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflows/{workflow_id}/map_layers',
            path: {
                'workflow_id': workflowId,
            },
        });
    }

}
