import React from "react";
import useIsMobile from "../../../hooks/useIsMobile";
import Home from "./Home";
import MobileHome from "./MobileHome";



export function HomeWrapper() {
    const isMobile = useIsMobile()

    if (isMobile) {
        return <div>
            <MobileHome />
        </div >
    }
    return <Home />
}

