import { createContext } from 'react';
import { UserResponse } from './services/openapi';
interface contextType {
    me: UserResponse | null,
    setMe: React.Dispatch<React.SetStateAction<UserResponse | null>>,
    isNewProjectOpen: boolean,
    setIsNewProjectOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export const AuthContext = createContext<contextType>({
    me: null,
    setMe: () => { },
    isNewProjectOpen: false,
    setIsNewProjectOpen: () => { }
});
