import * as React from 'react';
import { makeStyles, shorthands, tokens } from "@fluentui/react-components"
import discoverApp_icon from "../../assets/icons/nav_icons/discover-apps.svg";
import { Link } from "react-router-dom";
import { recommendedWorkflowsData } from './common/RcmdWorkflowsData';
import RcmdWorkFlowCard from './RcmdWorkFlowCard';

type WorkFlowDataType = {
    id: string,
    icon: string,
    title: string,
    info: string,
    partner: string,
    tagNames: string[],
    createdBy: string
}[]
const RecommendedWorkflows = () => {
    const classes = useStyles()

    const [rcmsworkFlowsDatas, setrcmsworkFlowsDatas] = React.useState<WorkFlowDataType | null>(null)

    React.useEffect(() => {
        setrcmsworkFlowsDatas(recommendedWorkflowsData)
    }, [])
    return (
        <>
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Link style={{ textDecoration: "none", fontSize: '1rem', color: "black", fontWeight: "normal" }} className={classes.flexContent} to="/discover-apps"><img alt="projectIcon" style={{ paddingRight: ".5em", }} src={discoverApp_icon} /> Discover Apps</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>Recommended Workflows</div>
            </div>
            <div className={classes.bgContainer}
                style={{
                    background: tokens.colorNeutralBackground1,
                    overflow: "auto",

                }}
            >
                <div className={classes.subHeader}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        Recommended Workflows
                    </div>
                    {rcmsworkFlowsDatas && (
                        <div className={classes.wrapper}>
                            {rcmsworkFlowsDatas.map((data, index) => (
                                <div key={index} >
                                    <RcmdWorkFlowCard key={index} rcmdWorkFlowcardData={data} />
                                </div>
                            ))}
                        </div>
                    )
                    }
                </div>
            </div >
        </>
    )
}

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    bgContainer: {
        width: "98%",
        ...shorthands.borderRadius("1em"),
        ...shorthands.padding("1em"),
        ...shorthands.margin("1em"),
        height: "calc(90vh - 4.2rem)",
        scrollbarWidth: "thin",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    subHeader: {
        fontWeight: "600",
        fontSize: "1.5em",
    },
    cardHeading: {
        fontSize: "1.2rem",
        color: "#586A84",
        fontWeight: 500,
        lineHeight: "1.5rem",
        ...shorthands.margin(0),
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(25%, 1fr))",
        marginTop: "1em",
        gridGap: '1em',
    }
})

export default RecommendedWorkflows