import { Button, Input, Textarea, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import * as React from "react"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from "../../AuthContext"
import support_icon from "../../assets/icons/nav_icons/support.svg"
import useUTMParams from '../../hooks/useUTMParams'
import { LeadRequest, ZohoService } from "../../services/openapi"


function RaiseQuery() {
    const classes = useStyles()
    const [isFormSubmit, setIsFormSubmit] = React.useState<boolean | false>(false);
    const user = React.useContext(AuthContext);
    const [data, setData] = React.useState({ name: user.me?.name ?? "", question: '' });
    const location = useLocation();
    const navigate = useNavigate();
    const utmParams = useUTMParams();

    const createLead = (formValues: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "Raise a query",
                    "Last_Name": formValues.name,
                    "Email": user.me?.email,
                    "Description": formValues.question,
                    "Form_Page_Url": `https://flyghtcloud.ideaforgetech.com${location.pathname}`,
                    "Form_Name": "Raise a query - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            console.log("Lead created successfully")
        }).catch(err => console.error("Error creating lead", err))
    }

    const onSubmit = (formData: any) => {
        console.log('formData', formData)
        createLead(formData);
        setIsFormSubmit(true)
    }
    const handleCancel = () => {
        setData({ ...data, question: '', name: "" });
        setIsFormSubmit(false);
        navigate(-1);
    }

    return <>
        <div className={classes.projectHeaderText}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Link style={{ textDecoration: "none", fontSize: '1rem', color: "black", fontWeight: "normal" }} className={classes.flexContent} to="/support"><img alt="support_icon" style={{ paddingRight: ".5em", }} src={support_icon} /> Support</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>Raise a Query</div>
        </div>
        <div className={classes.bgContainer} style={{ background: tokens.colorNeutralBackground1, overflow: "auto" }}>
            <div style={{ width: "40%" }}>
                <h2 className={classes.primaryHeading}>Have a question or need assistance?</h2>
                <p className={classes.commonTxt}>Don't worry, you can submit your question and we'll get back to <br /> you with an answer.</p>
                <form
                    style={{
                        flexGrow: 1,
                        margin: 'auto',
                    }}
                    onSubmit={(e) => {
                        e.preventDefault();
                        setData({ ...data, question: '' });
                    }}
                >
                    <div>
                        <div>
                            <h3 className={classes.labelText}>Your name *</h3>
                            <Input
                                style={{
                                    width: '100%',
                                }}
                                appearance="outline"
                                size="large"
                                placeholder="Type your name here"
                                value={data.name}
                                onChange={(e) => {
                                    setIsFormSubmit(false)
                                    setData({ ...data, name: e.target.value })
                                }}
                            />
                        </div>
                        <div style={{ paddingBottom: '1em' }}>
                            <h3 className={classes.quesText}>Your question *</h3>
                            <Textarea
                                style={{
                                    width: '100%',
                                    border: "1px solid #D1DBE8",
                                    height: "20vh"

                                }}
                                className={classes.txtBox}
                                appearance="outline"
                                size="large"
                                placeholder="Ask your question here..."
                                value={data.question}
                                onChange={(e) => {
                                    setIsFormSubmit(false);
                                    setData({ ...data, question: e.target.value })
                                }}
                            />
                        </div>

                        <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between" }}>
                            <Button style={{ width: '45%', color: "#586A84", padding: "8px 16px", borderRadius: "8px" }}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{ width: '45%', background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px" }}
                                appearance="primary"
                                onClick={() => {
                                    onSubmit(data);
                                    setData({ ...data, question: '' });
                                }}
                                disabled={!data.question || !data.name}
                                type="submit"
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
                {
                    isFormSubmit ? <div>
                        <p className={classes.quesText}>Thank you for reaching out!</p>
                        <p className={classes.commonTxt}>We have received your question and will get back to you with a response.Your satisfaction is our priority, and we aim to address your query promptly and effectively. Thank you for your patience.</p>
                    </div> : null
                }

            </div>
        </div>
    </>

}

const useStyles = makeStyles({
    bgContainer: {
        width: "98%",
        ...shorthands.borderRadius("1em"),
        paddingLeft: "1em",
        ...shorthands.margin("1em"),
        height: "calc(92vh - 4.5rem)",
        scrollbarWidth: "thin",
        display: "flex", justifyContent: "center", alignItems: "center",
        boxShadow: "5px 1px 20px 0px rgba(0, 0, 0, 0.05)",
    },
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    primaryHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
    },
    commonTxt: {
        marginTop: '1.2em',
        marginBottom: "1.2em",
        fontWeight: "400"
    },
    quesText: {
        marginTop: "2rem",
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    txtBox: {
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        ...shorthands.borderRadius("16px"),
        backgroundColor: "#F7FAFC"
    },
    labelText: {
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
})

export default RaiseQuery