
import * as React from "react";
import { Button, Field, Input, makeStyles } from "@fluentui/react-components";
import "./styles.css";
import useIsMobile from "../hooks/useIsMobile";
interface ForgotPassWordForm {
    email: string,
}

interface SignProps {
    onSubmit: (f: ForgotPassWordForm) => void;
    onChangeAuthForm: (f: string) => void;
    formData: ForgotPassWordForm;
    initialState: ForgotPassWordForm;
    buttonState: boolean,
    setForgotPasswordValues: React.Dispatch<React.SetStateAction<ForgotPassWordForm>>;
}

const useStyles = makeStyles({
    primaryHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
        paddingTop: "1em",
        paddingBottom: "1em",
        textAlign: "center"
    },
    spanColor: {
        color: "#1570EF",
        marginTop: "1em",
        cursor: "pointer"
    },
    inputContainer: {
        paddingBottom: "1.5em",
        '& .f13qh94s': {
            display: "block"
        }
    }
});

export const ForgotPassword = (props: SignProps) => {
    const classes = useStyles();
    const isMobile = useIsMobile()

    const [isValidMail, setIsValidMail] = React.useState(false);

    const handleCancel = () => {
        props.setForgotPasswordValues(props.initialState);
        props.onChangeAuthForm("login");
    }

    const handleSubmit = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidMail(!emailPattern.test(props.formData.email));
        if (emailPattern.test(props.formData.email)) {
            props.onSubmit(props.formData);
        }
    };


    return <div className="loginContainer" >
        <h3 className={classes.primaryHeading}>Forgot Password</h3>
        <p >Enter the email address associated with your account and the one-time code sent to your email.</p>
        <form
            onSubmit={(e) => { e.preventDefault() }}
            style={{ marginTop: "1.5em" }}
            className="loginForm"
        >
            <div className={classes.inputContainer}>
                <Field validationMessageIcon={null} validationMessage={isValidMail ? "Incorrect email address" : null}>
                    <Input
                        style={{
                            width: '100%',
                            border: isMobile ? isValidMail ? "1px solid red" : "1px solid #E5E5E5" : isValidMail ? "1px solid red" : "none"
                        }}
                        appearance="outline"
                        size="large"
                        placeholder="Work Email"
                        value={props.formData.email}
                        onChange={(e) => {
                            setIsValidMail(false)
                            props.setForgotPasswordValues({ ...props.formData, email: e.target.value })
                        }}
                    />
                </Field>
            </div>
            <div>
                <Button
                    style={{
                        width: '100%', background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px"
                    }}
                    onClick={handleSubmit}
                    disabled={!props.formData.email || props.buttonState}
                    type="submit"
                >
                    Submit
                </Button>
            </div>
            <div style={{ marginTop: "1rem" }}>
                <Button
                    style={{
                        width: '100%', background: "none", border: "1px solid #5C74E6", color: "#5E5CE6", padding: "8px 16px", borderRadius: "8px"
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </div>
        </form>
    </div >

};
