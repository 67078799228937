import React from "react";
import { Step } from "react-joyride";
import mapIcon from "../assets/icons/map.svg";
import workflowsIcon from "../assets/icons/nav_icons/workflows.svg";
import sendIcon from "../assets/icons/send.svg";
interface FloaterContentProps {
    heading: string,
    content: string | React.ReactNode,
}
const FloaterContent = ({ heading, content }: FloaterContentProps) => {
    return <div style={{
        display: "flex",
        flexDirection: 'column',
        alignItems: "flex-start"
    }}>
        <h2 style={{
            color: "#484848",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "20px"
        }}>{heading}</h2>
        <p style={{
            color: "#484848",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            textAlign: "left"
        }}>{content}</p>
    </div>
}

const floaterStyles = {
    disableBeacon: true,
    styles: {
        options: {
            overlayColor: "rgba(0, 0, 0, 0.30)"
        },
        tooltip: {
            padding: "16px 16px 8px 16px",
            width: "336px"
        },
        tooltipContent: {
            padding: "0"
        },
        buttonClose: {
            display: "none"
        },
        buttonNext: {
            color: "#0E84E5",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            background: "none",
            margin: "0",
            padding: "0"
        },
        buttonBack: {
            color: "#0E84E5",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            background: "none",
            margin: "0",
            padding: "0",
            marginRight: "auto",
        },
        tooltipFooter: {
            borderTop: "1px solid #ECECEE",
            justifyContent: "space-between",
        },

        tooltipFooterSpacer: {
            display: "none",
        }
    },
    disableOverlayClose: true,
    spotlightPadding: 0,
}

export const getTourSteps = (additionalData: Record<string, string | null>) => {
    const tourSteps: Step[] = [
        {
            target: '#navigation',
            ...floaterStyles,
            content: <FloaterContent heading="Left Navigation Panel" content="From here you can easily navigate to any of the pages." />,
            placement: "right-start",
            styles: {
                ...floaterStyles.styles,
                tooltipFooter: {
                    borderTop: "1px solid #ECECEE",
                    justifyContent: "flex-end",
                },
            },
        },
        {
            target: '#project-card',
            ...floaterStyles,
            content: < FloaterContent heading="Sample Project" content="All your projects will be displayed here as separate cards." />,
            placement: "bottom-start",
            styles: {
                ...floaterStyles.styles,
                spotlight: {
                    borderRadius: "16px",
                }
            },
        },
        {
            target: '#project-card-totalflights',
            ...floaterStyles,
            content: < FloaterContent heading="Total Number of Flights" content={<div>
                <img src={sendIcon} alt="total maps" style={{ display: "inline", float: "left", height: "20px", paddingRight: "2px" }} />
                <p>{`Symbolises the total number of flights in this project. Here in this project we have ${additionalData.noOfFlights} flight data available.`}</p>
            </div>} />,
            placement: "bottom-start",
        },
        {
            target: '#project-card-viewmaps',
            ...floaterStyles,
            content: < FloaterContent heading="Maps are ready in this project" content={<div>
                <img src={mapIcon} alt="view maps" style={{ display: "inline", float: "left", height: "20px", paddingRight: "2px" }} />
                <p>Symbolises the output maps in this project. Clicking on this icon will open up the detailed maps generated after data processing.</p>
            </div>} />,
            placement: "bottom-start",

        },
        {
            target: '#project-card-title',
            ...floaterStyles,
            content: < FloaterContent heading="Click to open this project" content="Clicking on this card will display a list of all available flights in this project." />,
            placement: "bottom-start",
            data: {
                next: `projects/${additionalData.projectID}`,
            },
        },
        {
            target: '#flight-actions-header',
            ...floaterStyles,
            content: < FloaterContent heading="Flights Section" content="This page will show all the flight and flight related operations that you can perform." />,
            placement: "bottom-start",
            data: {
                previous: `projects`,
            },
        },
        {
            target: '#flight-actions-managefiles',
            ...floaterStyles,
            content: <FloaterContent heading="Manage project files here" content="You can access and manage the additional preference files (Eg. Boundary files) of this project from here. This feature is disabled in this demo mode. Contact Us to unlock more features." />,
            placement: "bottom-start",
            styles: {
                ...floaterStyles.styles,
                spotlight: {
                    borderRadius: "8px",
                }
            },
        },
        {
            target: '#flight-actions-addflight',
            ...floaterStyles,
            content: <FloaterContent heading="Add new flights to this project" content="Add your new flight data to this project from this section. This feature is disabled in this demo mode. Contact Us to unlock more features." />,
            placement: "bottom-end",
            styles: {
                ...floaterStyles.styles,
                spotlight: {
                    borderRadius: "8px",
                }
            },
        },
        {
            target: '#flights-table-workflows',
            ...floaterStyles,
            content: <FloaterContent heading="Workflows" content={<div>
                <img src={workflowsIcon} alt="view maps" style={{ display: "inline", float: "left", height: "20px", paddingRight: "2px" }} />
                <p>icon symbolises the number of workflows in individual flights.</p>
            </div>} />,
            placement: "bottom-start",
        },
        {
            target: '#flight-actions-viewmaps',
            ...floaterStyles,
            content: < FloaterContent heading="Click on View Map button" content="Please click on the button to access the maps in this project." />,
            placement: "left",
            styles: {
                ...floaterStyles.styles,
                spotlight: {
                    borderRadius: "8px",
                }
            },
            data: {
                next: `projects/${additionalData.projectID}/views`,
            },
        },
        {
            target: '#map-timeline',
            ...floaterStyles,
            content: < FloaterContent heading="Map timeline" content="The map in current view is displayed here with the date of map generation on the time line." />,
            placement: "left-end",
            styles: {
                ...floaterStyles.styles,
                options: {
                    overlayColor: "rgba(0, 0, 0, 0.0)",
                },
            },
            data: {
                previous: `projects/${additionalData.projectID}`,
            },
        },
        // {
        //     target: '#map-timeline123',
        //     ...floaterStyles,
        //     content: < FloaterContent heading="Map tool box" content="All the controls available on this map is accessible from this tool box." />,
        //     placement: "left-end",
        //     styles: {
        //         ...floaterStyles.styles,
        //         options: {
        //             overlayColor: "rgba(0, 0, 0, 0.0)"
        //         },
        //     }
        // },
        {
            target: '#map-breadcrumb',
            ...floaterStyles,
            content: < FloaterContent heading="Breadcrumbs for navigation" content="The breadcrumbs also helps you in seamless backward navigation." />,
            placement: "left-end",
            styles: {
                ...floaterStyles.styles,
                options: {
                    overlayColor: "rgba(0, 0, 0, 0.0)"
                },
            },
            data: {
                next: `projects/${additionalData.projectID}`,
            },
        },
        {
            target: '#flight-row',
            ...floaterStyles,
            content: < FloaterContent heading="Click on flight" content="Please click on this flight, to view the workflow that was setup." />,
            placement: "bottom-end",
            data: {
                next: `projects/${additionalData.projectID}/flights/${additionalData.flightID}`,
                previous: `projects/${additionalData.projectID}/views`
            },
            styles: {
                ...floaterStyles.styles,
            },
        },
        {
            target: '#flight-details',
            ...floaterStyles,
            content: < FloaterContent heading="Flight Details" content="Information related to this flight is displayed here for your reference. " />,
            placement: "bottom-end",
            data: {
                previous: `projects/${additionalData.projectID}`
            },
        },
        {
            target: '#flight-details-startWorkflowBtn',
            ...floaterStyles,
            content: < FloaterContent heading="Start New Workflow button" content="You can add new workflows from here. This feature is disabled in this  demo mode. 
Contact Us to unlock more features." />,
            placement: "bottom-end",
            styles: {
                ...floaterStyles.styles,
                spotlight: {
                    borderRadius: "8px",
                }
            },
        },
        {
            target: '#flight-details-workflowrows',
            ...floaterStyles,
            content: < FloaterContent heading="List of Workflows " content="You can access and manage all workflows in this flight data." />,
            placement: "right",
        },
        {
            target: '#flight-details-workflowrow-title',
            ...floaterStyles,
            content: < FloaterContent heading="Click on this workflow" content="Please click on this workflow, to see the detailed steps and outputs in this workflow." />,
            placement: "bottom-start",
            data: {
                next: `workflows/${additionalData.workflowID}`,
            },
        },
        {
            target: '#workflow-details-title',
            ...floaterStyles,
            content: < FloaterContent heading="Detailed view of the workflow" content="You can access and manage this workflow from here." />,
            placement: "bottom-end",
            styles: {
                ...floaterStyles.styles,
                options: {
                    overlayColor: "rgba(0, 0, 0, 0.0)"
                },
            },
            data: {
                previous: `projects/${additionalData.projectID}/flights/${additionalData.flightID}`,
            },
        },
        {
            target: '#workflow-details-step',
            ...floaterStyles,
            content: < FloaterContent heading="Workflow Steps/Stages" content="You can view all the detailed steps in this workflow here. This is the first step in this workflow process." />,
            placement: "right",
            styles: {
                ...floaterStyles.styles,
                options: {
                    overlayColor: "rgba(0, 0, 0, 0.0)"
                },
            },
        },
        {
            target: '#workflow-details-laststep',
            ...floaterStyles,
            content: < FloaterContent heading="Workflow Steps/Stages" content="This is the last step in this workflow process." />,
            placement: "right",
            styles: {
                ...floaterStyles.styles,
                options: {
                    overlayColor: "rgba(0, 0, 0, 0.0)"
                },
            },
        },
        {
            target: '#workflow-details-output',
            ...floaterStyles,
            content: < FloaterContent heading="Outputs" content="Outputs (if any) from each step can be accessed separately from here. Outputs can be in the form of maps or PDF reports. " />,
            placement: "left",
            styles: {
                ...floaterStyles.styles,
                options: {
                    overlayColor: "rgba(0, 0, 0, 0.0)"
                },
            },
        },
        {
            target: '#workflow-details-detailedOutput',
            ...floaterStyles,
            content: < FloaterContent heading="Detailed Outputs" content="You can also view the detailed outputs from this workflow from this section. Apart from this all the output will be shared to the registered email address." />,
            placement: "right",
            styles: {
                ...floaterStyles.styles,
                options: {
                    overlayColor: "rgba(0, 0, 0, 0.0)"
                },
            },
            data: {
                next: `projects`,
            },
        },
    ];

    // console.log("steps.length", tourSteps.findIndex(imte => imte.target === "#map-timeline"))
    return tourSteps
}
