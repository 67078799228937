import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import open_new from "./assets/icons/open_in_new.svg";
import { AppRoutes } from './components/AppRoutes';
import Navigation from './components/Navigation';
import { TopBar } from './components/TopBar';
import NewProject from './components/dashboard/modals/NewProject';
import { AuthService } from './services/openapi';
import useIsMobile from './components/hooks/useIsMobile';
import MobileHome from './components/landing/pages/home/MobileHome';
import MobileDesclimer from './components/landing/pages/home/MobileDesclimer';

const landingPagesList = ['/', '/login', '/solutions', '/faqs', '/about-us', '/become-Provider', '/eula', '/privacy-policy', '/map', "/mob-login", '/book-demo', "/mob-becomeProvider"];


const App = () => {
    const classes = useStyles()
    const location = useLocation();
    const loggedMe = React.useContext(AuthContext);
    const isMobile = useIsMobile();
    const hideNavigation = landingPagesList.includes(location.pathname) || location.pathname.startsWith("/share/");
    const { isNewProjectOpen, me: user, setIsNewProjectOpen } = React.useContext(AuthContext);

    const whoami = () => {
        AuthService.whoami()
            .then((me) => {
                loggedMe.setMe(me);
            })
            .catch((err) => {
                console.log(err);
                if (location.pathname.startsWith("/share/")) {
                    return;
                }
                if (!landingPagesList.includes(location.pathname)) {
                    window.location.href = '/';
                    return;
                }
            });
    };
    React.useEffect(() => {
        whoami();
    }, []);

    if (isMobile && user) {
        return <MobileDesclimer />
    }


    return <>
        <div className={classes.pageContainer}>
            {!hideNavigation && <TopBar />}
            <div className={classes.pageContentContainer} >
                {
                    !hideNavigation && <div className={classes.navContainer} id="navigation">
                        <Navigation />
                        <p className={classes.branding} onClick={() => { window.open('https://ideaforgetech.com/ ', '_blank') }}>
                            ideaforgetech.com
                            <img src={open_new} alt='open_new' />
                        </p>
                    </div>
                }
                <div style={{ flex: 1 }} >
                    <AppRoutes />
                </div>
            </div>
            {isNewProjectOpen && <NewProject />}
        </div>
    </>
}

const useStyles = makeStyles({
    pageContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowX: "hidden",
    },
    pageContentContainer: {
        display: "flex",
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.flex(1),
    },
    navContainer: {
        width: "14.5rem",
        maxWidth: "14.5rem",
        backgroundColor: tokens.colorBrandBackground2,
        position: "relative",
        paddingTop: ".5em",
        ...shorthands.borderRight('1px', 'solid', '#E3E9F2'),
    },
    branding: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("0.5rem"),
        width: "100%",
        fontSize: "1rem",
        lineHeight: "1.25rem",
        ...shorthands.borderTop('1px', 'solid', '#E3E9F2'),
        height: "2.5rem",
        cursor: "pointer",
        color: "black",
        position: "absolute",
        bottom: "0",
        '> img': {
            width: "15px",
            height: "15px",
        },
        ':hover': {
            backgroundColor: "#E8EAED",
        },
        ...shorthands.borderRadius("0.5rem"),
    },
});
export default App