import { Checkbox, Divider, Label, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Popover, PopoverSurface, PopoverTrigger, Slider, Tooltip, makeStyles, shorthands } from "@fluentui/react-components";
import { Map, View } from "ol";
import { Control, defaults } from 'ol/control.js';
import MVT from "ol/format/MVT";
import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import VectorTileLayer from "ol/layer/VectorTile";
import WebGLTileLayer from "ol/layer/WebGLTile";
import { fromLonLat } from "ol/proj";
import OSM from "ol/source/OSM";
import VectorTileSource from 'ol/source/VectorTile';
import XYZ from "ol/source/XYZ";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../AuthContext";
import DownloadIcon from "../assets/icons/ol_download.svg";
import FullScreenIcon from "../assets/icons/ol_fullscreen.svg";
import LayerIcon from "../assets/icons/ol_layers.svg";
import MinimizeIcon from "../assets/icons/ol_minimize.svg";
import LayerOpacityControl from "../assets/icons/ol_opacity_control.svg";
import ShareIcon from "../assets/icons/ol_share.svg";
import ZoomInIcon from "../assets/icons/ol_zoom_in.svg";
import ZoomOutIcon from "../assets/icons/ol_zoom_out.svg";
import LinkIcon from "../assets/icons/open_in_new.svg";
import { API_BASE } from "../constants";
import { MapLayerResponse, UserResponse } from "../services/openapi";

interface OpenLayerProps {
    layers: MapLayerResponse[]
    onLoaded?: (map: Map) => void;
    showFullScreenIcon?: boolean
    fullScreenState?: boolean;
    showFullScreen?: () => void;
    hideFullScreen?: () => void;
    showToolBar?: boolean;
    showLayers?: boolean;
    mapContainerStyles?: React.CSSProperties;
    showDownload?: boolean;
    onClickDownload?: () => void;
    showShare?: boolean;
    onClickShare?: () => void
    showIdeaforgeButton?: boolean;
    shareID?: string | null;
    isShareOpen?: boolean;
}

type layerItemsVisibilityConfigType = Record<string, Record<"opacityIcon", boolean>>

export default function OpenLayers(props: OpenLayerProps) {
    const { layers, onLoaded, showFullScreenIcon = true, showFullScreen = () => null, hideFullScreen = () => null, fullScreenState = false, showToolBar = true, mapContainerStyles, showLayers = true, showDownload = false, showShare = false
        , onClickDownload = () => null, onClickShare = () => null, showIdeaforgeButton = false, shareID, isShareOpen = false
    } = props
    const mapEl = useRef<HTMLDivElement>(null);
    const controlsRef = useRef<HTMLDivElement>(null);
    const [olMap, setOlMap] = useState<Map | null>(null);
    const [mapLayers, setMapLayers] = useState<Layer[]>([]);
    const classes = useStyles()
    const { me: auth } = useContext(AuthContext);
    const [baseZoomLevel, setBaseZoomLevel] = useState(0)
    const [showZoomLevel, setShowZoomLevel] = useState(false)
    const [p, setp] = useState(100)
    const [layerItemsVisibilityConfig, setLayerItemsVisibilityConfig] = useState<layerItemsVisibilityConfigType>({})
    const [currentView, setCurrentView] = useState<"2D" | "3D">("2D")
    const threeDLayers = layers.find((l) => l.layerType === 'PointCloud');

    useEffect(() => {
        if (!mapEl.current) return;
        let ls = createLayers(layers, auth, shareID);
        setMapLayers(ls.layers)

        const map = new Map({
            controls: defaults({
                zoom: false,
                rotate: false,
                attribution: false,
            }),
            target: mapEl.current,
            layers: ls.layers.map((l) => l.olLayer),
            view: new View({
                center: ls.center,
                zoom: Math.min(...ls.zooms),
            }),
            keyboardEventTarget: document,
        });

        const layerItemsVisibilityConfig: layerItemsVisibilityConfigType = {}
        ls.layers.forEach((lyr, index) => {
            layerItemsVisibilityConfig[`${lyr.olLayer.get("title")}${index}`] = {
                opacityIcon: false,
            }
        })
        setLayerItemsVisibilityConfig(layerItemsVisibilityConfig)
        setOlMap(map)
        if (onLoaded) {
            onLoaded(map)
        }
        setBaseZoomLevel(Math.min(...ls.zooms))
        return () => {
            map.setTarget(undefined);
        };
    }, [layers])

    useEffect(() => {
        if (olMap && controlsRef.current) {
            olMap.addControl(new Control({ element: controlsRef.current }));
        }
    }, [olMap, controlsRef])


    useEffect(() => {
        if (showZoomLevel) {
            clearTimeout(hideTimer);
            hideTimer = setTimeout(() => {
                setShowZoomLevel(false);
            }, 2000);
        }
        return () => {
            clearTimeout(hideTimer);
        };
    }, [showZoomLevel]);

    const calculatePercentage = () => {
        if (!olMap) {
            console.error("Map instance not found")
            return 100
        }
        let currentZoom = olMap.getView().getZoom()
        if (currentZoom === undefined) {
            console.error("Couldn't get current zoom level")
            return 100
        }

        return 100 + ((currentZoom - baseZoomLevel) / baseZoomLevel) * 100
    }

    let hideTimer: any;

    const setZoom = (type: "in" | "out") => () => {
        if (!olMap) {
            return
        }
        let currentZoom = olMap.getView().getZoom()
        if (currentZoom === undefined) {
            return
        }
        olMap.getView().setZoom(type === "out" ? currentZoom - 1 : currentZoom + 1)
        if (showZoomLevel) {
            clearTimeout(hideTimer);
            hideTimer = setTimeout(() => {
                setShowZoomLevel(false);
            }, 2000);
        } else {
            setShowZoomLevel(true)
        }
        setp(calculatePercentage())
    }

    return <>
        <div className={classes.container} ref={mapEl} style={{ display: currentView === "2D" ? "block" : "none", overflow: "hidden", ...mapContainerStyles }}></div>

        {showToolBar && <div ref={controlsRef}
            className={classes.controls}
        >
            <Tooltip content="Zoom in" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0} >
                <img src={ZoomInIcon} alt="zoom in" className={classes.icon} onClick={setZoom("in")} />
            </Tooltip>

            <Divider className={classes.divider} />

            <Tooltip content="Zoom out" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                <img src={ZoomOutIcon} alt="zoom out" className={classes.icon} onClick={setZoom("out")} />
            </Tooltip>

            {showLayers && <>
                <Divider className={classes.divider} id="map-toolbox" />
                <Menu positioning={"before-top"} persistOnItemClick>
                    <MenuTrigger disableButtonEnhancement>
                        <Tooltip content="Layers" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                            <img src={LayerIcon} alt="layers" className={classes.icon} />
                        </Tooltip>
                    </MenuTrigger>

                    <MenuPopover className={classes.layersPopover}>
                        <MenuList>
                            {mapLayers.map((lyr: Layer, index) => {
                                const l = lyr.olLayer;
                                const title = l.get('title')
                                const key = `${title}${index}`
                                return <div key={key}
                                    onMouseEnter={() => {
                                        setLayerItemsVisibilityConfig(prev => {
                                            return {
                                                ...prev,
                                                [key]: {
                                                    ...prev[key],
                                                    opacityIcon: true,
                                                }
                                            }
                                        })
                                    }}
                                    onMouseLeave={() => {
                                        setLayerItemsVisibilityConfig(prev => {
                                            return {
                                                ...prev,
                                                [key]: {
                                                    ...prev[key],
                                                    opacityIcon: false,
                                                }
                                            }
                                        })
                                    }}
                                >
                                    <MenuItem style={{ maxHeight: "32px" }}>
                                        <div className={classes.layerItem}>
                                            <Checkbox size='large'
                                                className={classes.checkbox}
                                                onChange={(e) => {
                                                    l.setVisible(e.target.checked);
                                                    setMapLayers([...mapLayers]);
                                                }}
                                                checked={l.getVisible()}
                                            />


                                            <div className={classes.layerItemTextContainer}>
                                                <p className={classes.layerTitle}>{title}</p>


                                                {Boolean(layerItemsVisibilityConfig[key].opacityIcon) && <>
                                                    <Popover positioning={"before"}>
                                                        <PopoverTrigger>
                                                            <img src={LayerOpacityControl} alt="opacity control" />
                                                        </PopoverTrigger>

                                                        <PopoverSurface tabIndex={-1} style={{ padding: 0 }}>
                                                            <Slider
                                                                min={0}
                                                                max={100}
                                                                value={l.getOpacity() * 100}
                                                                onChange={(_, v) => {
                                                                    l.setOpacity(v.value / 100.0);
                                                                    setMapLayers([...mapLayers]);
                                                                }}
                                                            />
                                                        </PopoverSurface>
                                                    </Popover>
                                                </>}
                                            </div>
                                        </div>
                                    </MenuItem>

                                    {lyr.config?.layerType === 'SingleChannel' && (
                                        <div className={classes.layerItem} style={{ alignItems: "center" }}>
                                            <p className={classes.layerTitle}>Threshold</p>
                                            <div className={classes.thresholdSliderWrapper}>
                                                <Label aria-hidden>{Math.trunc(lyr.config?.config.layer.stats[0].min)}</Label>
                                                <Slider
                                                    min={0}
                                                    max={100}
                                                    value={(lyr.variables.threshold || 0) * 100}
                                                    onChange={(_, v) => {
                                                        (l as WebGLTileLayer).updateStyleVariables({ threshold: v.value / 100.0 })
                                                        setMapLayers([...mapLayers]);
                                                    }}
                                                // valueLabelDisplay="on"
                                                // valueLabelFormat={(v, _) => {
                                                //     if (lyr.config) {
                                                //         const stats: any = (lyr.config as any)['config']['layer']['stats'][0];
                                                //         const max = stats['max'];
                                                //         const min = stats['min'];
                                                //         return <div style={{ transform: 'transelateX(-100%)' }}>{parseFloat(min + (max - min) * v / 100).toFixed(0)}</div>;
                                                //     }
                                                //     return `${v}`
                                                // }}
                                                />
                                                <Label aria-hidden>{Math.trunc(lyr.config?.config.layer.stats[0].max)}</Label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            })}
                        </MenuList>
                    </MenuPopover>
                </Menu></>}

            {showShare && <>  <Divider className={classes.divider} />
                <Tooltip content="Share Map" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                    <img src={ShareIcon} alt="share" className={classes.icon} onClick={onClickShare} />
                </Tooltip>
            </>}
        </div>}

        {currentView === "3D" && <>
            <iframe
                style={{ width: '100%', height: '100%' }}
                src={`/potree/pages/viewer.html?path=${API_BASE}/api/map_layers/${threeDLayers?.id}/files/cloud.js&token=${auth?.token}`}
            >
            </iframe>
        </>}
        {showDownload && <div className={classes.fullScreen} onClick={onClickDownload}>
            <Tooltip content="Download Map" relationship="label" hideDelay={0} appearance="inverted" positioning={"before-top"} showDelay={0}>
                <img src={DownloadIcon} alt="download map" className={classes.icon} />
            </Tooltip>
        </div>}

        {showFullScreenIcon && <>
            {!fullScreenState && <div className={classes.fullScreen} onClick={showFullScreen}>
                <img src={FullScreenIcon} alt="full screen" className={classes.icon} />
            </div>}

            {fullScreenState && <div className={classes.fullScreen} onClick={hideFullScreen}>
                <img src={MinimizeIcon} alt="minimize" className={classes.icon} />
            </div>}
        </>}
        {
            showIdeaforgeButton && <div className={classes.ideaforgeWebsiteButton} onClick={() => { window.open('https://ideaforgetech.com/ ', '_blank') }}>
                <p> ideaforgetech.com</p>
                <img src={LinkIcon} alt='open ideaforge website' />
            </div>
        }

        {threeDLayers && !isShareOpen && <div className={classes.toggleView}>
            <button className={classes.toggleItem} onClick={() => { setCurrentView("3D") }} style={{ borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px" }}>
                <p className={classes.toggleItemText}
                    style={{
                        color: currentView === "3D" ? "black" : "rgba(130, 146, 168, 1)",
                        borderColor: currentView === "3D" ? "black" : "rgba(130, 146, 168, 1)"
                    }}>
                    3D</p>
            </button>
            <button className={classes.toggleItem} onClick={() => { setCurrentView("2D") }} style={{ borderTopRightRadius: "8px", borderBottomRightRadius: "8px" }}>
                <p className={classes.toggleItemText}
                    style={{
                        color: currentView === "2D" ? "black" : "rgba(130, 146, 168, 1)",
                        borderColor: currentView === "2D" ? "black" : "rgba(130, 146, 168, 1)"
                    }}>
                    2D</p>
            </button>
        </div>}

        <div className={classes.zoomLevelContainer} style={{ opacity: showZoomLevel ? "1" : "0", transition: "all 0.25s" }}>
            <div className={classes.zoomLevel}>{`${calculatePercentage().toFixed()}% Zoom`}</div>
        </div>
    </>
}


const useStyles = makeStyles({
    container: {
        height: "100%",
        width: "100%",
    },
    controls: {
        position: 'absolute',
        top: '180px',
        right: '20px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "white"
    },
    icon: {
        ...shorthands.padding("8px"),
        cursor: "pointer",
    },
    divider: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    fullScreen: {
        position: 'absolute',
        bottom: '16px',
        right: '20px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
    },
    toggleView: {
        position: 'absolute',
        bottom: '16px',
        right: '80px',
        backgroundColor: "#FFF",
        ...shorthands.borderRadius("8px"),
        boxShadow: "-2px 2px 10px 0px rgba(0, 0, 0, 0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    toggleItem: {
        cursor: "pointer",
        ...shorthands.border("none"),
        ...shorthands.overflow("hidden"),
        ...shorthands.padding("8px"),
        backgroundColor: "#FFF",
        ":hover": {
            backgroundColor: "#EEE",
        },
    },
    toggleItemText: {
        ...shorthands.border("2px", "solid", "rgba(130, 146, 168, 1)"),
        ...shorthands.padding("0px", "4px"),
        ...shorthands.borderRadius("4px"),
        fontWeight: "bold",
        fontSize: "12px",
    },
    zoomLevelContainer: {
        position: 'absolute',
        bottom: '32px',
        right: "50%",
        transform: "traslateX(50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

    },
    zoomLevel: {
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingRight: "8px",
        paddingLeft: "8px",
        color: "#FFF",
        backgroundColor: "rgba(0, 0, 0, 0.50)",
        ...shorthands.borderRadius("4px"),
    },
    layersPopover: {
        left: "-8px !important",
    },
    checkbox: {
        '> div': {
            ...shorthands.borderRadius("3.5px"),
            ...shorthands.margin(0),
        },
        '> input': {
            width: "100%",
        },
    },
    layerTitle: {
        color: "rgba(0, 0, 0, 0.90)",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px"
    },
    layerItem: {
        display: "flex",
        flexDirection: "row",
        ...shorthands.gap("0.5rem"),
        width: "224px",
    },
    layerItemTextContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    thresholdSliderWrapper: {
        display: 'flex',
        alignItems: "center"
    },
    ideaforgeWebsiteButton: {
        position: 'absolute',
        bottom: '16px',
        left: '20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...shorthands.gap("8px"),
        fontSize: "16px",
        lineHeight: "24px",
        cursor: "pointer",
        backgroundColor: "#FFF",
        color: "black",
        ...shorthands.padding("8px", "16px"),
        '> img': {
            width: "15px",
            height: "15px",
        },
        ':hover': {
            backgroundColor: "#C6FFCA",
            boxShadow: "-1px 1px 10px 0px rgba(0, 0, 0, 0.25)"
        },
        ...shorthands.borderRadius("0.5rem"),
    }
})

interface Layer {
    olLayer: BaseLayer,
    config?: MapLayerResponse,
    variables: { threshold?: number },
}

const createLayers = (layers: MapLayerResponse[], auth: UserResponse | null, shareID?: string | null) => {
    const ls: Layer[] = [
        {
            olLayer:
                new TileLayer({
                    visible: true,
                    title: 'Base',
                    source: new OSM(),
                    zIndex: 0,
                } as any),
            variables: {},
        }
    ];
    let center: any[] = [0, 0];
    let zooms: any[] = [];
    let idx = 0;
    for (const layer of layers) {
        const layerConfig = layer.config.layer;
        if (!layerConfig) continue;
        const bl = fromLonLat([layerConfig['bl']['lon'], layerConfig['bl']['lat']], 'EPSG:3857');
        const tr = fromLonLat([layerConfig['tr']['lon'], layerConfig['tr']['lat']], 'EPSG:3857');
        center = fromLonLat([layerConfig['center']['lon'], layerConfig['center']['lat']], 'EPSG:3857');
        const title = layer.config['title'] || layer.layerType;
        const variables = { threshold: 0.00 };

        idx = idx + 1;
        let l: BaseLayer;
        if (layer.layerType !== 'VectorTiles') {
            zooms.push(layerConfig['minZoom']);
            zooms.push(layerConfig['maxZoom']);
        }
        switch (layer.layerType) {
            case 'VectorTiles': {
                l = new VectorTileLayer({
                    declutter: false,
                    source: new VectorTileSource({
                        format: new MVT({
                            defaultDataProjection: 'EPSG:4326',
                        } as any),
                        url: `${API_BASE}/api/map_layers/${layer.id}/files/{z}/{x}/{y}.pbf`,
                    }),
                    zIndex: 100 + idx,
                });
                break;
            }
            case 'SingleChannel': {
                const normalize = (e: any) => {
                    return ['/', e, 1];
                };
                const normalizedValue = normalize(['band', 1]);
                l = new WebGLTileLayer({
                    visible: true,
                    opacity: 0.8,
                    extent: [bl[0], bl[1], tr[0], tr[1]],
                    source: new XYZ({
                        attributions: 'ideaForge FlyghtCloud',
                        minZoom: layerConfig['minZoom'],
                        maxZoom: layerConfig['maxZoom'],
                        url: `${API_BASE}/api/map_layers/${layer.id}/files/{z}/{x}/{-y}.png?token=${auth?.token || ''}`,
                        tileSize: [layerConfig['tileSize']['width'], layerConfig['tileSize']['height']],
                    }),
                    zIndex: idx,
                    style: {
                        color: [
                            'array',
                            normalizedValue,
                            normalizedValue,
                            normalizedValue,
                            [
                                'case',
                                ['<=', normalizedValue, ['var', 'threshold']],
                                0,
                                1,
                            ],
                        ],
                        variables,
                    },
                });
                break;
            }
            case 'RGBA': {
                l = new TileLayer({
                    visible: true,
                    opacity: 0.8,
                    extent: [bl[0], bl[1], tr[0], tr[1]],
                    source: new XYZ({
                        attributions: 'ideaForge FlyghtCloud',
                        minZoom: layerConfig['minZoom'],
                        maxZoom: layerConfig['maxZoom'],
                        url: `${API_BASE}/api/map_layers/${layer.id}/files/{z}/{x}/{-y}.png${shareID ? `?shareId=${shareID}` : ''}`,
                        tileSize: [layerConfig['tileSize']['width'], layerConfig['tileSize']['height']],
                    } as any),
                    zIndex: 50 + idx,
                });
                break;
            }
            default: {
                continue;
            }
        }
        l.set('title', title);
        const lyr = { olLayer: l, config: layer, variables };
        ls.push(lyr);
    }

    if (zooms.length === 0) {
        // if only vector layers, use some sensible zoom level for initial
        zooms.push(16);
    }

    return { layers: ls, center, zooms };
};
