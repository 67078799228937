import createMaps_icon from "../../../assets/icons/workflow_images/CreateMapsWithODM.png";
import createMapswithAgisoftMetashape_icon from "../../../assets/icons/workflow_images/createMapsWithAGISOFT.png";
import createMapsWithAgisoftMetashapeUsingGCPs_icon from "../../../assets/icons/workflow_images/createMapsWithAGISOFTUsingGCPs.png";
import createStockpileReport_icon from "../../../assets/icons/workflow_images/stockpileReport.png";
import highResolutionDEMRorBFT_icon from "../../../assets/icons/workflow_images/highResolutionDemForBFT.png"

export const deafultWorkFlowData = [
    {
        id: "1",
        icon: createMaps_icon,
        title: 'Create maps with ODM',
        info: "Perform photogrammetry and generate maps with OpenDroneMap.",
        tagNames: ["Photogrammetry", "OpenDroneMap"],
        overView: "Use this workflow to create aerial and elevation maps from raw images. Useful for vegetation areas and not recommended for large areas and the areas with a lot of structures. This workflow uses OpenDroneMap photogrammetry software to process the images. This workflow processing is not for CROSS GRID, only for SIMPLE GRID flight plan.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate maps with OpenDroneMap",
                info: "Creating geospatial maps and 3D models from aerial imagery collected by drones, using the OpenDroneMap(ODM)"
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces "
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            },
        ],
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
        ],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
        ],
        processingPricing: "50",
        storagePricing: "12",
        demoLink: [
            { name: "Demo video - Create Map With ODM", type: "video", path: 'https://www.youtube.com/watch?v=TGCBdo0dGNI' },
            { type: "docs", name: "Photogrammetry_report", path: "/assets/workflow_docs/createMapWithODM-photogrammetry_report.pdf" },
        ],

    },
    {
        id: "2",
        icon: createMapswithAgisoftMetashape_icon,
        title: 'Create maps with Agisoft Metashape',
        info: "Perform photogrammetry and generate maps with Agisoft Metashape.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate maps with Agisoft Metashape",
                info: "Create detailed 2D/3D maps and models from aerial images using Metashape."
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces"
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            },
            {
                title: "Generate tiles for a 3D view",
                info: "Convert 3D models or data into smaller, manageable pieces (tiles) for efficient display and navigation of interactive 3D visualizations"
            },],
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
        ],
        processingPricing: "60",
        storagePricing: "12",
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "3D point cloud (.laz or .las format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            },
        ],
        tagNames: ["Photogrammetry", "Agisoft", "Metashape"],
        overView: "Utilize this workflow to create aerial and elevation maps from raw images using Agisoft Metashape photogrammetry software. Ideal for various use cases and large-scale mapping projects. Please note, this workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
            { name: "Demo Video - Create Map With Agisoft", type: "video", path: 'https://www.youtube.com/watch?v=a_Q5PLd7bYg' },
            { type: "docs", name: "Agisoft Metashape report", path: "/assets/workflow_docs/agisoftMetashapeReport.pdf" },
        ],
    }, {
        id: "3",
        icon: createMapsWithAgisoftMetashapeUsingGCPs_icon,
        title: 'Create maps with Agisoft Metashape using GCPs',
        info: "Perform photogrammetry and generate maps with Agisoft Metashape with Ground Control Points.",
        processingPricing: "80",
        storagePricing: "12",
        inputParameters:
            [
                {
                    title: "Geo-Tagged images (.JPG format)",
                    info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
                },
                {
                    title: "Ground control point( .csv format)",
                    info: "Readings(Northing, Easting, Elevation or Latitude, longitude, elevation) in CSV format"
                },

            ],
        tagNames: ["Agisoft", "Metashape", "GCPs"],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "3D point cloud (.laz or .las format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            },
        ],
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Tag GCPs(Ground Control Points) in the images",
                info: "Label and identify specific points in aerial images that correspond to known ground locations, enabling georeferencing and accurate mapping"
            },
            {
                title: "Generate maps with Agisoft Metashape with GCPs",
                info: "Create highly accurate 2D/3D maps and models from aerial images using Metashape's photogrammetry software, guided by tagged Ground Control Points (GCPs) for precise georeferencing."
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces"
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            }, {
                title: "Generate tiles for a 3D view",
                info: "Convert 3D models or data into smaller, manageable pieces (tiles) for efficient display and navigation of interactive 3D visualizations"
            },],
        overView: "If your project requires ground control points along with PPK, use this workflow to create aerial and elevation maps. This workflow utilizes Agisoft Metashape photogrammetry software to process the images. It is highly useful for various use cases and large-scale mapping. Please note, this workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
            { name: "Demo Video - Create maps with Agisoft Metashape using GCPs", type: "video", path: 'https://www.youtube.com/watch?v=8xA_yQL5FjM' },
            { type: "docs", name: "Agisoft Metashape Report", path: "/assets/workflow_docs/agisoftMetashapeReport.pdf" },
        ],
    }, {
        id: "4",
        icon: createStockpileReport_icon,
        title: 'Create stockpile report',
        info: "Produce map data with OpenDroneMap and generate stockpile report.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate maps with OpenDroneMap",
                info: "Creating geospatial maps and 3D models from aerial imagery collected by drones, using the OpenDroneMap(ODM)"
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces "
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            },
            {
                title: "Upload the stockpile boundaries",
                info: "Upload in geojson format"
            },
            {
                title: "Generate stockpile report",
                info: "Detailed information about a stockpile, including its volume, weight, material composition, and spatial dimensions"
            }
        ],
        processingPricing: "100",
        storagePricing: "12",
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
            {
                title: "Stockpile boundaries ( .geojson format )",
                info: "File in .geojson format and should be in geographic coordinate system."
            },
        ],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "Stockpile report",
                info: "PDF Report containing detailed information about a stockpile, including its volume, weight, material composition, and spatial dimensions"
            },
        ],
        tagNames: ["Stockpile", "OpenDroneMap"],
        overView: "Use this workflow to create a stockpile report from raw images. It is very useful for monitoring projects like mining, earthworks, etc. This workflow requires a one-time setup of stockpile boundaries and no further human intervention. It runs the photogrammetry session on uploaded images using OpenDroneMap and creates an orthomosaic and DEM. Based on these outputs, it generates a stockpile report. This workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
            { name: "Demo video - Create stockpile report", type: "video", path: 'https://www.youtube.com/watch?v=DKualn7Kr78' },
            { type: "docs", name: "Stockpile Report", path: "/assets/workflow_docs/stockpileReport.pdf" },
        ],

    }, {
        id: "5",
        icon: highResolutionDEMRorBFT_icon,
        title: 'High resolution DEM for BFT',
        info: "Generate a high-resolution DEM and seamlessly convert it to BFT's compatible format.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate Digital Elevation Model with Agisoft Metashape",
                info: "Creating a highly accurate 3D model of the terrain using Metashape's, converting overlapping aerial images into a detailed Digital Elevation Model (DEM)."
            },
            {
                title: "Convert DEM to BFT's compatible format",
                info: "Digital Elevation Model (DEM) is made ready for BFT compatible format"
            }],
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            }
        ],
        tagNames: ["BFT", "Digital Elevation Model", "High resolution"],
        worflowOutputs: [
            {
                title: "High resolution DEM (. geotiff format )",
                info: "A highly detailed Digital Elevation Model (DEM) in GeoTIFF format, providing precise elevation data and topographic information at a high spatial resolution, ideal for applications requiring accurate terrain representation. The output will also contain 3D point cloud Orthomosiac in (.tif format) and BFT Compatible high resolution DEM (.tif format)."
            },
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "Use this workflow to create a BFT-compatible digital elevation model from uploaded raw images. This workflow utilizes Agisoft Metashape photogrammetry software to process the raw data and generate the digital elevation model. Note: This workflow processing is only for simple grid flight plans, not for cross grid.",
        demoLink: [
            { name: "Demo video - High resolution DEM for BFT", type: "video", path: 'https://www.youtube.com/watch?v=-kA7Xz8qIDU' },
            { type: "docs", name: "Report-High Resolution DEM For BFT", path: "/assets/workflow_docs/report-HighResolutionDEMForBFT.pdf" },
        ],
    },
]