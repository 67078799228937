
import { Toast, ToastTitle, makeStyles, useId, useToastController } from '@fluentui/react-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../AuthContext';
import flyghtcloud_logo from "../../../../assets/icons/landing/logo_green.svg";
import ToastErrorIcon from "../../../../assets/icons/toast_error.svg";
import useUTMParams from '../../../../hooks/useUTMParams';
import { AuthService, LeadRequest, ZohoService } from '../../../../services/openapi';
import { BecomeProviderCardData } from '../../common/BecomeProviderData';
import Footer from '../../common/Footer';
import HeaderNavBar from '../../common/HeaderNavBar';
import BecomeaProviderCard from './BecomeaProviderCard';
import BecomeaProviderForm from './BecomeaProviderForm';
import BrandName from '../../../common/BrandName';

type CardProps = {
    id: number,
    title: string,
    info: string
}

function BecomeaProvider() {
    const classes = useStyles();
    const toasterId = useId("toaster");
    const navigate = useNavigate();
    const timeout = 2000;
    const { dispatchToast } = useToastController(toasterId)
    const loggedMe = React.useContext(AuthContext);
    const [cardDatas, setCardDatas] = React.useState<CardProps[] | null>(null);
    const [isLoginDisabled, setIsLoginDisabled] = React.useState(false);
    const utmParams = useUTMParams();

    React.useEffect(() => {
        setCardDatas(BecomeProviderCardData);
    }, []);

    const submitForm = (formData: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "Become a provider - Home",
                    "Last_Name": formData.fullName,
                    "Company": formData.companyName ? formData.companyName : undefined,
                    "Email": formData.email,
                    "Phone": formData.phoneNumber ? formData.phoneNumber : undefined,
                    "Form_Page_Url": "https://flyghtcloud.ideaforgetech.com/become-Provider",
                    "Form_Name": "Become a provider - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => console.log("Lead created successfully")).catch(err => console.error("Error creating lead", err))
    }

    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const login = (d: any) => {
        AuthService.login(d)
            .then((res) => {
                loggedMe.setMe(res)
                if (res.token) {
                    navigate(`/dashboard`);
                }
            })
            .catch((err) => {
                console.table(err)
                notifyError("Either email or password is invalid")
                setTimeout(() => {
                    setIsLoginDisabled(false)
                }, timeout);
            })
    }

    return <>
        <div className={classes.headerNavBar}>
            <HeaderNavBar login={login} toasterId={toasterId} icon={flyghtcloud_logo} color='black' signInBorder={true} btnBg="#E5E8EB" btnStatus={isLoginDisabled} />
        </div>
        <div style={{ width: "75%", marginTop: "3em", marginLeft: "auto", marginRight: "auto" }}>
            <h2 className={classes.primaryHeading}  >Join The Flyght Cloud Ecosystem</h2>
            <p className={classes.subHeading}>Integrate your tools and offer enhanced solutions to <BrandName /> users</p>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5em" }}>
                <div style={{ width: "45%" }}>
                    <h3 className={classes.cardHeading}>What's in it for you?</h3>
                    {cardDatas && (
                        <div className={classes.wrapper}>
                            {cardDatas.map((data, index) => (
                                <div key={index} >
                                    <BecomeaProviderCard cardData={data} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div style={{ position: "relative", width: "1px", marginLeft: '1em', marginRight: "1em" }}>
                    <div style={{ position: "absolute", top: 0, bottom: 0, left: "50%", backgroundColor: "#D1DBE8", width: "100%" }}></div>
                </div>
                <div style={{ width: "45%" }}>
                    <h3 className={classes.cardHeading}>Connect with us for more details</h3>
                    <p className={classes.info}>Please fill out the form below, and our team will get in touch <br /> with you shortly to discuss partnership opportunities.</p>
                    <BecomeaProviderForm onSubmit={(d: any) => {
                        submitForm(d);
                    }} />
                </div>
            </div>
        </div >
        <div style={{ color: "#586A84", marginTop: "8em", marginBottom: "2em" }}>
            <Footer />
        </div>
        <div>

        </div>

    </>
}

const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999

    },
    faqContainer: {
        maxWidth: "92%",
    },
    primaryHeading: {
        fontSize: "2.3rem",
        color: "#1C1B1F",
        fontWeight: 600,
    },
    subHeading: {
        fontSize: "1rem",
        marginTop: "1.5em",
        color: "#1C1B1F",
        fontWeight: 500,
    },
    quesContainer: {
        maxWidth: "60%",
        width: "55%",
    },
    wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(40%, 1fr))",
        marginTop: "2em",
        gridGap: '1.5em',
    },
    formContainer: {
        maxWidth: "50%",
        width: "42%"
    },
    cardHeading: {
        fontSize: "1.6rem",
        fontWeight: 600,
    },
    info: {
        marginTop: "1.5em",
        fontSize: "1rem",
        fontWeight: 500,
    },


    cmpTxt: {
        color: "#586A84"
    }
})
export default BecomeaProvider