import React, { useEffect, useRef } from 'react'
import HeaderNavBar from '../landing/common/HeaderNavBar'
import { Toast, ToastTitle, makeStyles, mergeClasses, useId, useToastController } from '@fluentui/react-components'
import { AuthService } from '../../services/openapi'
import { AuthContext } from '../../AuthContext'
import { useNavigate } from 'react-router-dom'
import ToastErrorIcon from "../../assets/icons/toast_error.svg";
import flyghtcloud_logo from "../../assets/icons/landing/logo_green.svg"



const Eula = () => {
    const classes = useStyles()
    const loggedMe = React.useContext(AuthContext);
    const navigate = useNavigate();
    const timeout = 2000;
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const [isLoginDisabled, setIsLoginDisabled] = React.useState(false);

    const endRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        endRef.current?.scrollIntoView({ behavior: "auto" })
    }
    useEffect(() => {
        scrollToBottom()
    }, []);
    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const login = (d: any) => {
        setIsLoginDisabled(true)
        AuthService.login(d)
            .then((res) => {
                loggedMe.setMe(res)
                setIsLoginDisabled(false)
                if (res.token) {
                    navigate(`/dashboard`);
                }
            })
            .catch((err) => {
                console.table(err)
                notifyError("Either email or password is invalid")
                setTimeout(() => {
                    setIsLoginDisabled(false)
                }, timeout);
            })
    }

    return <div ref={endRef}>
        <div className={classes.headerNavBar}>
            <HeaderNavBar login={login} toasterId={toasterId} icon={flyghtcloud_logo} signInBorder={true} color='black' btnBg="#E5E8EB" btnStatus={isLoginDisabled} />
        </div>
        <div style={{ width: "60%", margin: "2em auto", fontSize: "1rem" }}>
            <h2 className={classes.primaryHeading}>End User License Agreement</h2>
            <div className={classes.commonContainer}>
                <p className={classes.commonTxt}> This END USER LICENSE AGREEMENT (the <b>“Agreement”</b>) governs your use of the software known as
                    ‘FlyGHT’, developed for use in Unmanned Aerial Vehicles (UAVs), with features as more fully
                    described under <b>Annexure A </b>hereto (<b>“Software”</b>). The Software has been developed and is owned
                    by <b>IDEAFORGE TECHNOLOGY LIMITED,</b> a company incorporated under the laws of India, having its
                    registered office at El-146, TTC Industrial Area, Electronic Zone MIDC, Mahape, Navi Mumbai, Thane
                    Maharashtra – 400710 (hereinafter referred to as the <b>“Licensor”</b> ). </p>
                <p className={classes.commonTxt} style={{ marginTop: "1em" }}>
                    Please make sure you read this Agreement and any other conditions that may relate to the Software
                    that we offer, before downloading and/or using the Software. The Agreement constitute a legal and
                    binding agreement between the Licensor and you and forms the basis for which you are allowed to
                    download and utilize the Software. Your action of downloading or using the Software, in any
                    manner, constitutes your acceptance of and consent to abide by the terms and conditions set forth
                    in this Agreement.
                </p>
                <p className={classes.commonTxt} style={{ marginTop: "1em" }}>
                    The Software is available only to persons who can form legally binding contracts under Indian
                    Contract Act, 1872. Persons who are ‘incompetent to contract’ within the meaning of the Indian
                    Contract Act, 1872 are not eligible to download or use the Software. If you are a minor i.e. under the
                    age of 18 years, you may use the Software only under the supervision and prior consent/ permission
                    of a parent or legal guardian. By the use of the Software you confirm that you must possess the legal
                    authority and capacity to create a binding legal obligation, including to be bound by the terms of this
                    Agreement and your use of the Software does not violate any law, statute, ordinance, rule or
                    regulation.
                </p>
                <p className={classes.commonTxt} style={{ marginTop: "1em" }}>
                    For the purpose of this Agreement, you and all users of the Software shall be referred to as the
                    <b>“Licensee”</b>. The Licensor and the Licensee shall collectively be referred to as <b>“Parties”</b> and
                    individually as <b>“Party”</b>.
                </p>

            </div>
            {/* from below */}
            <div className={classes.commonContainer}>
                <h2 className={classes.boldTxt}>NOW IT IS MUTUALLY AGREED BY AND BETWEEN THE PARTIES HERETO AS FOLLOWS: </h2>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>1.</p>
                    <p>ACCOUNT REGISTRATION AND LICENSE GRANT</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.1</p>
                    <p>In order to use the Software, the Licensee may be required to register and create an account
                        (&quot;Account&quot;). During the registration process, the Licensee will be required to provide certain
                        information to the Licensor and the Licensee will establish a username and a password
                        (“Account Credentials”). The Licensee agrees to provide accurate, current and complete
                        information during the registration process and to update such information to keep it
                        accurate, current and complete. The Licensee is responsible for safeguarding its Account
                        Credentials. The Licensee agrees not to disclose its Account Credentials to any third party
                        and to take sole responsibility for any activities or actions under its Account, whether or not
                        it has authorized such activities or actions. By registering for an Account, the Licensee agrees
                        that the Licensor may send occasional emails relevant to its Account or to its use of the
                        Software. The Licensee shall notify the Licensor immediately of any unauthorized access to

                        or use of the user name or password or any other breach of security that the Licensee
                        become aware of.    </p>
                </div>
                <div className={classes.flexContainer} >
                    <p>1.2</p>
                    <p>On payment of the License Fee, as specified under Clause 3 , the Licensor shall provide the
                        Licensee with an ‘administration user name’ and password, as per the Account Credentials
                        created by the Licensee. The Licensee is at liberty to create sub usernames for each of its
                        users, provided that the Licensee shall be responsible for all such usernames and passwords.
                        All such sub usernames shall be linked to the administration user name provided to the
                        Licensee, for the purpose of tracking the Licensee’s usage of the Software.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.3</p>
                    <p>The Licensor reserves the right to suspend or terminate the Licensee’s Account if any
                        information provided during the registration process or thereafter proves to be inaccurate,
                        incomplete, or not correct. The Licensor also reserves the right to refuse service or
                        terminate accounts for any reason, in its sole discretion without prior notice.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.4</p>
                    <p>On receipt of the License Fee, the Licensor grants to the Licensee a non-transferable, non-
                        exclusive and revocable license to use the Software through the Licensor’s website [Name]
                        <b>(“Website”)</b> / by downloading the application [Name] <b>(“App”)</b>, as per the terms specified in
                        this Agreement.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.5</p>
                    <p>Save as expressly provided to the contrary below, the Licensee may not and shall ensure
                        that it and any of its authorized users by virtue of being granted a user name/password do
                        not: (a) modify, adapt, copy, decompile, sub-license, disassemble, extract, reverse engineer
                        the Software or otherwise derive the source code and any other ideas, algorithms, concepts
                        or procedures from the Software or documentation provided with Software, unless and to
                        the extent expressly permitted by applicable statutory law; (b) create derivative works based
                        on the Software; (c) transfer the license or otherwise make the same available to any third
                        party; (d) impersonate any person or entity, or falsely state or otherwise misrepresent the
                        Licensee’s affiliation with a person or entity; and/or (e) use the Software for purposes that
                        are not permitted under this Agreement, any applicable policy, any applicable law,
                        regulation or generally accepted practices or guidelines in the relevant jurisdiction.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.6</p>
                    <p>The Software may, inter alia, be used by the authorized users to view and record ‘geospatial
                        data’. Geospatial data includes, but is not limited to, data relating to location information
                        about natural or man-made, physical or imaginary features whether above the ground or
                        below, boundaries, points of interest, natural phenomena, mobility data, weather patterns
                        and statistical information. The Licensee shall, and shall ensure that its authorized users
                        shall, at all times be aware of and abide by the applicable laws relating to recording, storing
                        and sharing of geospatial data.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.7</p>
                    <p>The Ministry of Science &amp; Technology, Department of Science &amp; Technology (DST) has issued
                        guidelines on acquiring and producing geospatial data and has published a negative list of
                        sensitive attributes that would require regulation before anyone can acquire and/or use
                        such attribute data. The DST has further stipulated conditions for storage and processing of
                        certain geospatial data having a spatial accuracy/value up to the threshold value as specified

                        by the DST, only in India. The Licensee shall, and shall ensure that its authorized users shall,
                        abide by the guidelines issued by the DST and any other relevant governmental authority,
                        while using the Software and not, in any manner, violate the negative list of sensitive
                        attributes or storage and processing requirements.
                        <span style={{ display: "block", marginTop: "1em" }}>The national geospatial policy and guidelines for acquiring and producing geospatial data
                            and geospatial data services including maps issued by the DST, as on May 17, 2024 are
                            available at: <span style={{ color: "blue", cursor: "pointer" }} onClick={() => { window.open('https://dst.gov.in/geospatial', "_blank"); }}><u>https://dst.gov.in/geospatial.</u></span> These guidelines may be updated by the DST from
                            time to time.</span>
                        <span style={{ display: "block", marginTop: "1em" }}>
                            It shall be the responsibility of the Licensee to keep itself updated with the latest guidelines
                            and negative list of sensitive attributes published by the DST and inform its authorized users
                            of the same. The Licensor shall have the right to suspend the Licensee’s usage of the
                            Software, in the manner provided under Clause 9 hereunder, for any breach of the
                            provisions of this Clause 1.5 .
                        </span>
                    </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.8</p>
                    <p>The Licensee shall, as and when required by the Licensor or any statutory authority, provide: </p>
                </div>
                <div style={{ paddingLeft: "1.7em" }}>
                    <p>{'a)'} a self-certification, in the manner directed, of its and its authorized users adherence to the
                        geospatial guidelines issued by the DST; and</p>
                    <p>{'b)'} provide documentary evidence that it is an Indian entity and not a foreign entity and/or
                        foreign owned or controlled Indian entity.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.9</p>
                    <p>The Licensee shall, and shall ensure that all its employees and agents having access to the
                        Software shall, strictly abide this Agreement and the privacy policy available at <a style={{ color: "blue" }} href='https://flyghtcloud.ideaforgetech.com/privacy-policy'>https://flyghtcloud.ideaforgetech.com/privacy-policy</a>
                        (“Privacy Policy”). The Licensee shall be legally bound by the Privacy Policy and the Licensor
                        shall have the right to update/amend the same from time to time without having the
                        obligation of informing the Licensee regarding the same. The Licensee’s continued use of the
                        Software shall signify its acceptance of any updated/amended terms.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.10</p>
                    <p>The Licensee shall be responsible for protecting the user name and password provided by
                        the Licensor for the use of the Software by the Licensee as well as its authorized users.
                        Further, the Licensee shall be solely responsible for maintaining the content, data, reports
                        etc. that is made available/transmitted through the Software.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.11</p>
                    <p>The Licensee shall be responsible for ensuring that it has the requisite internet connectivity
                        for the use of the Software. Further, it shall ensure that the Software is installed only on
                        Designated Servers (as defined herein) as agreed to by the Parties. For the purposes of this
                        Agreement, <b>“Designated Server”</b> shall mean [●]. Each Designated Server must be approved
                        by the Licensor to be compatible with the Software.</p>
                </div> <div className={classes.flexContainer}>
                    <p>1.12</p>
                    <p>The Licensee shall provide true, accurate, current and complete information as prompted by
                        the signup process and maintain and promptly update the information provided during sign
                        up to keep it true, accurate, current, and complete. The Licensee shall be responsible for the
                        accuracy, quality, and legality of the data and the means by which the Licensee has acquired
                        the data.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.13</p>
                    <p><u>Prohibitions:</u> When using the Software, the Licensee agrees not to: </p>
                </div>
                <div style={{ paddingLeft: "1.7em" }}>
                    <p>{'a) '}  Disrupt, damage, interfere with, violate the security of, or attempt to gain unauthorized
                        access to, the Software or any use of the same by a third party;</p>
                    <p>{'b) '}  Circumvent any technological measure implemented by the Licensor or any of its providers
                        or any other third party to protect the Software and services provided on the Software;</p>
                    <p>{'c) '}  Use the Software in any manner that could disable, overburden, damage, or impair or
                        interfere with any other party’s use of the Software, including their ability to engage in real
                        time activities through the Software; </p>
                    <p>{'d) '}  Upload, transmit, distribute, or run any computer virus, worm, trojan horse, logic bomb, or
                        any computer code or other material that could harm or alter a computer, portable device,
                        computer network, communication network, data, the Software, or any other system,
                        device or property;</p>
                    <p>{'e) '}  Use any software robots, spiders, crawlers, or other automatic device, process or means to
                        access the Software for any purpose, including monitoring or copying any content on the
                        Software;</p>
                    <p>{'f) '}  Engage in any conduct that creates or is intended to create liability for the Licensor;</p>
                    <p>{'g) '}  Transmit, or cause the sending of any advertising or promotional material without the prior
                        written consent of the Licensor, including any ‘junk mail’, ‘chain letter’, ‘spam’ or any other
                        similar solicitation;</p>
                    <p>{'h) '}  Attempt, in any manner, to obtain the password, account, credentials, or other security
                        information from any other user; or jeopardize the security of the Account or anyone else’s
                        account (for example, by allowing another individual to login to the the Software using the
                        Account Credentials);</p>
                    <p>{'i) '}  Impersonate or attempt to impersonate the Licensor, another user or any other person or
                        entity (including, without limitation, by using email addresses or screen names associated
                        with any of the foregoing);</p>
                    <p>{'j) '}  Use the Software in any way that violates any applicable federal, state, local or international
                        law or regulation;</p>
                    <p>{'k) '}  Send, knowingly receive, upload, download, use or re-use any content that does not comply
                        with the standards and requirements set out in this Agreement and applicable law;</p>
                    <p>{'l) '}  Engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the
                        Software, or which, as determined by the Licensor, may harm us or users of the Software or
                        expose them to liability;</p>
                    <p>{'m) '}  Otherwise attempt to interfere with the proper working of the Software;</p>
                    <p>{'n) '}  Harm minors in any way;</p>
                    <p>{'o) '}  Infringes any patent, trademark, copyright or other proprietary rights;</p>
                    <p>{'p) '}  Violates any law for the time being in force;</p>
                    <p>{'q) '}  Deceive or mislead the addressee about the origin of such messages or communicates any
                        information which is grossly offensive or menacing in nature;</p>
                    <p>{'r) '}  Impersonate another person; or</p>
                    <p>{'s) '}  Perform any activity that threatens the unity, integrity, defense, security or sovereignty of
                        India, friendly relations with foreign states, or public order or causes incitement to the
                        commission of any cognizable offence or prevents investigation of any offence or is insulting
                        any other nation.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>1.14</p>
                    <p>In the event that the Licensee violates any of the terms of the Agreement, the Privacy Policy
                        or any other applicable guidelines or policies, the Licensor reserves the right to immediately
                        revoke the license and terminate this Agreement, apart from availing any other legal remedy
                        available to the Licensor. In the event of such violation, the Licensor shall have the right to
                        disclose any of the Licensee’s personal data/customer data to the extent required.</p>
                </div> <div className={classes.flexContainer}>
                    <p>1.15</p>
                    <p>The Licensor may cooperate with legal authorities and/or third parties in the investigation of
                        any suspected or alleged crime or civil wrong. Except as may be expressly limited by the
                        Privacy Policy, the Licensor reserves the right at all times to disclose any information it
                        deems necessary to satisfy any applicable law, regulation, legal process or governmental
                        request, or to edit, refuse to post or to remove any information or materials, in whole or in
                        part, in the Licensor’s sole discretion.</p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>2.</p>
                    <p>OBLIGATIONS OF THE LICENSOR </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>2.1</p>
                    <p> The Licensor acknowledges that the ownership of all content and data that is created and stored by the Licensee shall belong to the Licensee.The Licensor shall not use, reproduce, adapt, modify, publish or distribute the content created by the Licensee for its commercial or marketing purposes. However, the Licensee grants the Licensor permission to access, copy, store and reformat, the content of the Licensee’s account solely as required for the purpose of this Agreement. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>2.2</p>
                    <p>The Licensor will maintain administrative and technical safeguards for the protection of the security, confidentiality, and integrity of the Licensee’s data. Those safeguards will include, but are not limited to measures for preventing access, use, modification or disclosure of the Licensee’s data by the Licensor’s personnel, except (a) to provide the Software and prevent or address service or technical problems, (b) as compelled/required by law. </p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>3.</p>
                    <p>PAYMENT TERMS </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>3.1</p>
                    <p>In consideration of the license granted under this Agreement, the Licensee shall pay to the Licensor, the fee as specified under Annexure B to this Agreement (<b>“License Fee”</b>). </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>3.2</p>
                    <p>The Licensee shall pay the License Fee within 30 (thirty) days of receipt of an invoice from the Licensor. In the event of any delay in payment of the License Fee, the Licensor may, at its discretion (i) revoke or suspend the license to the Software, (ii) charge an interest on the unpaid amount at the rate of 18% per annum, and/or (iii) terminate this Agreement. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>3.3</p>
                    <p>Each Party shall be responsible for paying its respective applicable taxes including but not limited to the goods and services tax.</p>
                </div>
            </div >
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>4.</p>
                    <p>CONFIDENTIALITY </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>4.1</p>
                    <p>Each Party acknowledges that it will have access to certain confidential information of the other Party concerning the other Party’s business, plans, customers, technology, operations, products, and other information held in confidence by the other Party (<b>“Confidential Information”</b>). Each Party acknowledges that any disclosure to third parties of Confidential Information may cause immediate and irreparable harm to the owner of the disclosed Confidential Information. Confidential Information will include all information in tangible or intangible form that is marked or designated as confidential or that, under the circumstances of its disclosure, should be considered confidential. Each Party agrees that it will not use in any way, for its own account or the account of any third party, except for its own account as expressly permitted by, or required to achieve the purposes of this Agreement, nor disclose to any third party, any of the other Party’s Confidential Information and will take reasonable precautions to protect the confidentiality of such information in a manner consistent with generally accepted industry standards and in accordance with the provisions of this Agreement.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>4.2</p>
                    <p>The receiving Party may disclose Confidential Information only to those of its directors, officers, employees and consultants who have a need to know such information in order for the receiving Party to be able to exercise its rights and/or perform its obligations hereunder and who have themselves an agreement with the receiving Party sufficient to require them to treat the Confidential Information in the same manner as is set out herein. The receiving Party will nevertheless at all times be responsible for ensuring that such directors, officers, employees and consultants retain the confidentiality of the Confidential Information and shall remain liable for any breach of confidentiality by such directors, officers, employees and consultants. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>4.3</p>
                    <p>Information will not be deemed Confidential Information hereunder if such information: (i) is known to the receiving Party prior to receipt from the disclosing Party directly or indirectly from a source other than one having an obligation of confidentiality to the disclosing Party;<br /> (ii) becomes known (independently of disclosure by the disclosing party) to the receiving Party directly or indirectly from a source other than the one having an obligation of confidentiality to the disclosing Party; (iii) is trivial or obvious, in the public domain, becomes publicly known or otherwise ceases to be secret or confidential, except through a breach of this Agreement by the receiving Party; or (iv) is independently developed by the receiving Party. The receiving Party may disclose Confidential Information pursuant to the requirements of a governmental agency or by operation of applicable statutory law or by a rule or regulation of a recognized stock exchange, provided that it gives the disclosing Party reasonable prior written notice sufficient to permit the disclosing Party to contest such disclosure.  </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>4.4</p>
                    <p>Neither of the Parties will make any public announcements concerning this Agreement and/or the involvement of any Party herein without the prior written consent of the other contracting Party unless this shall be required by applicable statutory law or by a rule or regulation of a recognized stock exchange. The timing and content of any such announcement will be agreed between the Parties.  </p>
                </div>
            </div >
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>5.</p>
                    <p>INTELLECTUAL PROPERTY RIGHTS  </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>5.1</p>
                    <p>The Licensee acknowledges that ownership of and title in and to all intellectual property rights, including patent, trademark, service mark, copyright, and trade secret rights, in the Software are and shall remain with the Licensor. The Licensee will acquire only the right to use the Software under the terms and conditions of this Agreement and does not acquire any ownership rights or title in or the Software, either express or implied. The Licensee shall not remove, alter or tamper with any proprietary, copyright, trademark, or service mark legend from the Software. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>5.2</p>
                    <p>All right, title, and interest in and to the Website and App, and all intellectual property rights
                        in the Website and App, are retained by the Licensor, its affiliates, partners or licensors, and
                        are protected by copyright, trademark, patent, intellectual property, and other laws of India.
                        INFRINGEMENT UPON COPYRIGHT IS A CRIMINAL OFFENCE AND THE LICENSOR TAKES
                        COPYRIGHT INFRINGEMENT VERY SERIOUSLY. THE LICENSOR MAY SCAN OTHER WEBSITES
                        FOR DUPLICATION OF OUR PROPERTY AND PERIODICALLY CHECK FOR ANY INFRINGEMENT.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>5.3</p>
                    <p>All other intellectual property not owned by the Licensor or its affiliates that appear on the
                        Website / App are the property of their respective owners, who may or may not be affiliated
                        with, connected to, or sponsored by the Licensor or its affiliates. Except as expressly
                        provided in this Agreement Terms, the Licensee is expressly prohibited from copying, selling,
                        assigning, licensing, renting, reproducing, distributing, modifying, disassembling,
                        decompiling, reverse engineering, creating derivative works of, publicly displaying, publicly
                        performing, marketing, downloading, storing, transmitting, disclosing, or otherwise
                        exploiting any part of the Website or App available, without the express prior consent of the
                        Licensor.</p>
                </div>  <div className={classes.flexContainer}>
                    <p>5.4</p>
                    <p>Framing or utilizing framing techniques to enclose any trademark, logo, or other proprietary
                        information (including images, text, designs, page layout, or form) of the Licensor and
                        affiliates is prohibited without express written consent. The Licensee may not use any of the
                        Licensor’s logo or other proprietary graphic or trademark as part of the link without express
                        written permission. The Licensor grants the Licensee limited license to access and make
                        personal use of the Software.</p>
                </div>  <div className={classes.flexContainer}>
                    <p>5.5</p>
                    <p>Except as set forth in this Clause 5 , the Licensee’s access or use, or right to provide any other
                        individual with access, to any part of the Website / App / Software in breach of this
                        Agreement and the Licensee’s right to use the Software will terminate immediately.</p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>6.</p>
                    <p>LINKS TO THIRD PARTY WEBSITES </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>6.1</p>
                    <p>The Software may contain hyperlinks to other sites owned and operated by parties other
                        than the Licensor. Such hyperlinks are provided only for ready reference and ease of use.

                        Inclusion of any link on the Software does not imply that the Licensor endorses the linked
                        site. The Licensee may use the links and these services at its own risk. The Licensor does not
                        control such websites/applications and cannot be held responsible for their services,
                        content or accuracy. In the event the Software provides hyperlinks to other websites that
                        are not owned, operated or maintained by the Licensor, the Licensee acknowledges and
                        agrees that the Licensor is not responsible for and are not liable for the content, products,
                        services or other materials on or available from such websites/applications.
                    </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>6.2</p>
                    <p>The Licensor accepts no liability for any information, products, advertisements, content,
                        services or software accessible through these third party websites or for any action the
                        Licensee may take as a result of linking to any such website/applications. Any such
                        websites/applications are likely to set forth specific terms of use and privacy policies that
                        the Licensee should review. The Licensor is under no obligation to maintain any link on the
                        Software and may remove a link at any time in its sole discretion for any reason whatsoever.
                        The Licensor shall not be responsible or liable, directly or indirectly, for any damages or
                        losses caused or alleged to be caused by or in connection with the use of or reliance on such
                        content, services or other materials available on or through any such website/application.
                        The Licensor is not responsible for the privacy practices of any other websites.
                    </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>6.3</p>
                    <p>The Licensor reserves the rights to display sponsored ads on the Software. These ads would
                        be marked as ‘Sponsored Ads’. Without prejudice to the status of other content, the
                        Licensor will not be liable for the accuracy of information or the claims made in the
                        Sponsored Ads. The Licensor does not encourage the Licensee to visit the Sponsored Ads
                        page or to avail any services from them. The Licensor will not be liable for the services of the
                        providers of the Sponsored Ads.
                    </p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>7.</p>
                    <p>REPRESENTATIONS AND WARRANTIES.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>7.1</p>
                    <p> Each Party, individually, represents and warrants that (i) it/he/she has the capacity to enter into this Agreement; and (ii) the execution and delivery of this Agreement and the promises, agreements or undertakings of the Party under this Agreement do not violate any law, rule, regulation or order applicable to it/him/her and that there is neither any contract, agreement nor any understanding with anyone, restricting or preventing it/him/her from entering into this Agreement or performing its/his/her obligations as set forth in this Agreement.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>7.2</p>
                    <p>The Parties shall at all times and at their own expense (a) strictly comply with all corporate governance, applicable laws, rules, regulations, and governmental orders and applicable codes of practice relating to its/his/her performance of this Agreement, (b) maintain in full force and effect all licenses, permits, authorization, registration and qualifications necessary under this Agreement. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>7.3</p>
                    <p> <span><u>Disclaimers</u></span> <br />The Licensor does not warrant that the operation of the Software will be uninterrupted or error free or that the applications contained in the Software are designed to meet all of the Licensee’s business requirements. The Licensor will have no responsibility, warranty or other obligations whatsoever with respect to: (a) the use of the Software in a manner inconsistent with the respective documentation or this Agreement, (b) any Software which has been modified by anyone other than the Licensor, (c) failure of any Software as a result of accident, abuse or misapplication,(d) Software not installed on a designated server, specified by the Licensor; or (e) to the extent that the defect is caused by or is contributed to by the Licensee; or (f) if the Licensee does not provide access, including remote access, to the Software as required; or (g) if the defect is caused by a malfunction of a hardware, software other than the Software or combination of all or any of them.</p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>8.</p>
                    <p>INDEMNIFICATION</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>8.1</p>
                    <p> <span><u>Indemnification by the Licensee.</u></span> The Licensee shall indemnify the Licensor against all claims, liabilities, and costs, including reasonable attorneys’ fees, reasonably incurred in the defense of any claim (other than for the infringement of intellectual property rights specified hereinabove), arising out of any use of the Software by the Licensee not in accordance with this Agreement, the Terms of Use, the Privacy Policy or any the applicable policies/guidelines.  </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>8.2</p>
                    <p><span><u>The Licensor’s Right to Commence Infringement Actions:</u></span> The Licensor may take any such actions which it determines is reasonably necessary or desirable in its sole discretion in connection with any infringement or alleged infringement by a third party of any portion of the Software. The Licensee shall not undertake any action in response to any infringement or alleged infringement of the Software without the prior written consent of the Licensor. The Licensee agrees to cooperate with and assist the Licensor in taking whatever action (including consenting to being named as a party to any suit or other proceeding), which the Licensor determines to be reasonably necessary or desirable.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>8.3</p>
                    <p><span><u>Entire Liability.</u></span> TO THE FULL EXTENT PERMITTED BY APPLICABLE LAW, THE FOREGOING STATES THE ENTIRE LIABILITY OF THE LICENSOR TO THE LICENSEE CONCERNING INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, INCLUDING BUT NOT LIMITED TO PATENT, TRADEMARK, COPYRIGHT AND TRADE SECRET RIGHTS BY THE SOFTWARE OR ANY PART THEREOF OR BY THEIR OPERATION.</p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>9.</p>
                    <p>LIMITATION OF LIABILITY</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>9.1</p>
                    <p>IN NO EVENT WILL THE LICENSOR BE LIABLE OR RESPONSIBLE TO THE LICENSEE FOR ANY TYPE OF PUNITIVE, INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOST REVENUE, LOST PROFITS, LOSS OF TECHNOLOGY, RIGHTS OR SERVICES, LOSS OF DATA, OR INTERRUPTION OR LOSS OF USE OF SOFTWARE OR ANY OTHER LOSS ARISING OUT OF OR RELATING TO THIS AGREEMENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE LICENSOR AND ITS AFFILIATES TO THE LICENSEE AND ITS AFFILIATES EXCEED THE FEES PAID BY THE LICENSEE UNDER THIS AGREEMENT TOWARDS SUCH SERVICE FOR WHICH THE LIABILITY AROSE.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>9.2</p>
                    <p>THE LICENSOR WILL NOT BE RESPONSIBLE UNDER THIS AGREEMENT FOR (I) THE MODIFICATION OR IMPROVEMENT OF THE SOFTWARE TO FIT THE PARTICULAR REQUIREMENTS OF THE LICENSEE, OR (II) THE CORRECTION OF ANY PROGRAM ERRORS RESULTING FROM MODIFICATIONS OR EXTENSIONS UNDERTAKEN BY A THIRD PARTY AT THE REQUEST OF OR BY THE LICENSEE OR (III) THE CORRECTION OF ANY PROGRAM ERRORS AS A RESULT OF MISUSE OF THE SOFTWARE BY THE LICENSEE. </p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>10.</p>
                    <p>TERM, SUSPENSION AND TERMINATION</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>10.1</p>
                    <p>This Agreement will continue to apply until terminated by either of the Parties as set forth
                        below. The Licensee may terminate this Agreement by (i) not accessing the Software; or (ii)
                        closing its Account.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>10.2</p>
                    <p>The Licensee agrees that the Licensor may, in its sole discretion and without prior notice,
                        terminate its access to the Software and block its future access to the Software if the
                        Licensor determines that the Licensee has violated the terms of this Agreement. The
                        Licensee also agrees that any violation will cause irreparable harm to the Licensor, for which
                        monetary damages may be inadequate, and consents to the Licensor obtaining any
                        injunctive or equitable relief that the Licensor deems necessary or appropriate in such
                        circumstances. These remedies are in addition to any other remedies the Licensor may have
                        at law or in equity.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>10.3</p>
                    <p>In addition to Clause 10.2 above, the Licensor may, at any time, with or without notice,
                        terminate this Agreement (or portion thereof) if:</p>
                </div>
                <div style={{ paddingLeft: "1.7em" }}>
                    <p>{'a) '}  The Licensor is required to do so by law, or upon request by any law enforcement or other
                        government agencies;</p>
                    <p>{'b) '}  The Software is, in the Licensor’s opinion, no longer commercially viable;</p>
                    <p>{'c) '}  The Licensor has elected to discontinue, with or without reason, access to the Software (or
                        any part thereof); or</p>
                    <p>{'d) '}  In the event the Licensor faces any unexpected technical issues or problems that prevent the
                        Software from working.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>10.4</p>
                    <p>The Licensor may also terminate or suspend all or a portion of the Account or access to the
                        Software with or without reason. Except as may be set forth in any additional terms,
                        termination of the Account may include: (i) removal of access to all offerings within the
                        Software; (ii) disabling access to the account information, including personal information,
                        log-in ID and password, and all related information, files and materials associated with or
                        inside the account (or any part thereof), and any content uploaded by the Licensee; and (iii)
                        prohibiting further use of the Software.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>10.5</p>
                    <p>The Licensee agrees that all terminations shall be made in the Licensor’s sole discretion and
                        that the Licensor shall not be liable to the Licensee or any third party for any termination, or
                        the Licensee’s access to the Software.
                    </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>10.6</p>
                    <p>Once temporarily suspended, indefinitely suspended or terminated, the Licensee may not
                        continue to use the Software under the same account, a different account or re-register
                        under a new account. On termination of an account due to the reasons mentioned herein,
                        such Licensee shall no longer have access to data, messages, files and other material kept on
                        the Software by such Licensee.
                    </p>
                </div> <div className={classes.flexContainer}>
                    <p>10.7</p>
                    <p>Notwithstanding the foregoing, such terms of this Agreement as are meant to survive
                        termination/ expiry of this Agreement, will survive unless and until the Licensor chooses to
                        terminate them.
                    </p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>11.</p>
                    <p>FORCE MAJEURE</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>11.1</p>
                    <p> If either Party is prevented, restricted, delayed or interfered with in the performance of their obligations under this Agreement by force majeure circumstances which are beyond the control of the Parties, including but not limited to government legislations, acts of government, government regulation, pandemics or epidemics, the obligations of the Party which cannot be performed by reason of such force majeure conditions shall remain suspended. Where such suspension carries on for a continuous period of over 3 (three) months, then the other Party shall have the right to terminate the Agreement </p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>12.</p>
                    <p>DISPUTE RESOLUTION</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>12.1</p>
                    <p>In case of any dispute between the Parties in connection with this Agreement and Annexures or the interpretation of any of the terms and/or conditions contained in the agreement, the dispute shall be referred to private Mediation by a sole mediator accredited or certified by any High Court of the Republic of India and appointed mutually by the Parties. If the Parties are unable to resolve the dispute through mediation within thirty (30) days from the date of referral and after attending at least two sessions of Mediation, then the Parties agree to submit any dispute arising out of or in connection with the agreement to arbitration by a sole arbitrator. Such arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996 or any statutory amendment or re-enactment thereof for the time being in force. The arbitration shall be conducted in English and the venue of arbitration shall be in Mumbai, India.</p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>13.</p>
                    <p>GOVERNING LAW</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>13.1</p>
                    <p>This Agreement shall be governed by and construed in accordance with the applicable laws of India and the courts in Mumbai, India shall have exclusive jurisdiction over all matters pertaining to this Agreement.  </p>
                </div>
            </div>
            <div className={classes.commonContainer}>
                <div className={mergeClasses(classes.flexContainer, classes.boldTxt)}>
                    <p>14.</p>
                    <p>MISCELLANEOUS</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.1</p>
                    <p><span><u>Entire Agreement & Precedence:</u></span>This Agreement and the Annexures constitute the entire agreement between the Parties relative to the subject matter hereof, and supersedes all proposals, written or oral, and all other communications between the parties relating to the subject matter of this Agreement.
                    </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.2</p>
                    <p><span><u>Amendments or Alterations:</u></span>  This Agreement and the Annexures may be modified, amended or rescinded only by a written agreement executed by both Parties. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.3</p>
                    <p><span><u>Severability:</u></span>The invalidity, illegality or unenforceability of any provision of this Agreement shall in no way affect the validity, legality or enforceability of any other provision. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.4</p>
                    <p><span><u>Binding Nature:</u></span>This Agreement and the Annexures shall be binding upon and inure to the benefit of the Parties hereto and their respective successors and assigns. </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.5</p>
                    <p><span><u>Assignment:</u></span> Parties shall not assign, nor otherwise transfer or sell any of its rights under this Agreement without prior written consent of the other Party.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.6</p>
                    <p><span><u>Waiver:</u></span> The waiver by either party of a breach of any provision of this Agreement and the Annexures shall not operate or be construed as a waiver of any subsequent breach.</p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.7</p>
                    <p><span><u>Audit:</u></span>The Licensee shall prepare and maintain complete and accurate books of accounts and records covering all transactions relating to this Agreement. During the term of this Agreement and for 1 (one) year thereafter, Licensor’s representatives may, on reasonable notice during normal business hours, examine Licensee’s books and records relating to the matters hereto and make extracts or copies thereof.   </p>
                </div>
                <div className={classes.flexContainer}>
                    <p>14.8</p>
                    <p><span><u>Relationship:</u></span>The Licensor’s relationship with the Licensee is that of an independent contractor, and nothing in this Agreement will be construed to create a joint partnership, joint venture, agency, or employer-employee relationship. </p>
                </div>
            </div>
            <div className={classes.cmnCenter}>
                <p className={classes.boldTxt}>ANNEXURE A</p>
                <p className={classes.boldTxt}>Details of the Software</p>
            </div>
        </div >
    </div>
}

const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999
    },
    primaryHeading: {
        fontSize: "1.5rem",
        fontWeight: "500"
    },
    commonTxt: {
        fontSize: "1rem",
    },
    commonContainer: {
        marginTop: "2em",
        textAlign: "justify"
    },
    boldTxt: {
        fontWeight: "bold",
        marginTop: "1em",
        marginBottom: "1em"
    },
    commonSpace: {
        marginTop: "1em",
        marginBottom: "1em"
    },
    flexContainer: {
        display: "flex",
        marginTop: "1em",
        '> p:first-child': {
            width: "5%"
        },
        '> p:last-child': {
            width: "95%"
        }
    },

    cmnCenter: {
        textAlign: "center",
        marginTop: "8em",
        marginBottom: "5em"
    }

})
export default Eula