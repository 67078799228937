import { Button, makeStyles, shorthands } from "@fluentui/react-components";
import { Document24Regular } from "@fluentui/react-icons";
import * as React from 'react';
import { WorkflowsService } from "../services/openapi";

interface Props {
    workflowId: string;
    fileId: string;
    title?: string;
}

export const DownloadReportButton = ({ title, workflowId, fileId }: Props) => {
    const [loading, setLoading] = React.useState(false);

    const classes = useStyles()

    const download = () => {
        setLoading(true);
        WorkflowsService.getFilePresignedUrl(workflowId, fileId)
            .then((url) => {
                window.open(url, "_blank");
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    return <>
        <Button appearance="subtle" disabled={loading} onClick={() => download()} className={classes.button} id="workflow-details-output">
            {title || 'Download'}<Document24Regular />
        </Button>
    </>
};

const useStyles = makeStyles({
    button: {
        display: "flex",
        height: "40px",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        ...shorthands.gap("8px"),
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "#79747E"),
        color: "#5E5CE6",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        backgroundColor: "transparent",
        whiteSpace: "nowrap",
        '&:hover': {
            backgroundColor: "#F4F4FF",
            color: "#5E5CE6",
        },
        '&:active': {
            backgroundColor: "#F4F4FF",
            color: "#5E5CE6",
            ...shorthands.border("1px", "solid", "#F4F4FF"),
        }
    },
})
