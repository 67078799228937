import * as React from 'react'
import BookDemoForm from './BookDemoForm'
import MobileHeader from './MobileHeader';
import { Button, makeStyles } from '@fluentui/react-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeadRequest, ZohoService } from '../../../services/openapi';
import useUTMParams from '../../../hooks/useUTMParams';

function MobileBookDemo() {
    const classes = useStyles()
    const navigate = useNavigate();
    const location = useLocation();
    const utmParams = useUTMParams();

    const [isformSubmitted, setisFormSubmitted] = React.useState<boolean>(false)


    const pathNameToLeadSource: Record<string, string> = {
        "/": "Connect to expert - Home",
        "/book-demo": "Connect to expert - Mobile-Home",
        "/solutions": "Connect to expert - Solutions",
        "/about-us": "Connect to expert - About Us",
    }
    const createLead = (formData: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": pathNameToLeadSource[location.pathname] ? pathNameToLeadSource[location.pathname] : "Connect to expert",
                    "Company": formData.companyName ? formData.companyName : undefined,
                    "Phone": formData.phoneNumber ? formData.phoneNumber : undefined,
                    "Last_Name": formData.fullname,
                    "Email": formData.email,
                    "Country": formData.country ? formData.country : undefined,
                    "Industry": formData.industry.length > 0 ? formData.industry.join(", ") : undefined,
                    "Designation": formData.designation ? formData.designation : undefined,
                    "City": formData.city ? formData.city : undefined,
                    "Form_Page_Url": `https://flyghtcloud.ideaforgetech.com${location.pathname}`,
                    "Form_Name": "Connect to expert - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            setisFormSubmitted(true);
        }).catch(err => console.error("Error creating lead", err))
    }
    return (
        <div>
            <MobileHeader />
            {
                isformSubmitted ? <div className={classes.submitedState}>
                    <h2 className={classes.barndingInfo}>
                        Your Request Is Submitted Scuccessfully</h2>
                    <p className={classes.ssbarndingInfo}>Our experts will get back to you soon!</p>
                    <Button onClick={() => { navigate(`/`); }}
                        style={{ width: '100%', marginTop: "1em", background: "none", color: "#8D8D8D", padding: "8px 16px", borderRadius: "8px" }}>
                        Okay
                    </Button>
                </div> : <div className={classes['mob-container']}>
                    <h2 className={classes.barndingInfo}>See How Flyght Cloud Can Take Your Drone Data to the Next Level</h2>
                    <p className={classes.ssbarndingInfo}>Connect with our experts and get a demo.</p>
                    <BookDemoForm onSubmit={createLead} />
                    <Button onClick={() => { navigate(`/`); }}
                        style={{ width: '100%', marginTop: "1em", background: "none", color: "#8D8D8D", padding: "8px 16px", borderRadius: "8px" }}>
                        Back
                    </Button>
                </div>
            }

        </div>
    )
}

export default MobileBookDemo;
const useStyles = makeStyles({
    "mob-container": {
        width: "93%",
        margin: "2em auto 1em auto"
    },
    barndingInfo: {
        fontSize: "1.2rem",
        lineHeight: "1.5rem",
        fontWeight: "500",
        color: "#08201D",
    },
    ssbarndingInfo: {
        fontSize: ".9rem",
        fontWeight: "400",
        lineHeight: "1.1rem",
        marginTop: ".5em"
    },
    submitedState: {
        width: "93%",
        margin: "2em auto 1em auto",
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
})