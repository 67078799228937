import * as React from 'react'
import { Card, makeStyles, shorthands, CardPreview, CardHeader, Body1 } from '@fluentui/react-components';

interface analyticsDataProps {
    id: number,
    title: string,
    icon: string
}
function AnalyticsCard({ analyticsData }: { analyticsData: analyticsDataProps }) {
    const classes = useStyles();
    return <Card className={classes.card}>
        <CardPreview>
            <img className={classes.gridImg}
                src={analyticsData.icon}
                alt="logo"
            />
        </CardPreview>
        <CardHeader
            header={
                <Body1>
                    <h3 className={classes.cardHeading}>{analyticsData.title}</h3>
                </Body1>
            }
        />
    </Card >
}

const useStyles = makeStyles({
    card: {
        width: "98%",
        maxWidth: "100%",
        height: 'calc(100% - 0rem)',
        boxShadow: "none"
    },
    text: {
        ...shorthands.margin(0),
    },
    gridImg: {
        width: "100%",
    },
    cardHeading: {
        fontSize: ".9rem",
        fontWeight: 500,
    },
})

export default AnalyticsCard