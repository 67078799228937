import * as React from 'react'
import MobileHeader from './MobileHeader'
import BecomeaProviderForm from '../pages/become-provider/BecomeaProviderForm';
import { LeadRequest, ZohoService } from '../../../services/openapi';
import useUTMParams from '../../../hooks/useUTMParams';
import { Button, makeStyles } from '@fluentui/react-components';
import BrandName from '../../common/BrandName';
import { useNavigate } from 'react-router-dom';

function MobileBecomeProvider() {
    const classes = useStyles();
    const utmParams = useUTMParams();
    const navigate = useNavigate();

    const [isformSubmitted, setisFormSubmitted] = React.useState<boolean>(false)


    const submitForm = (formData: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "Become a provider - Home",
                    "Last_Name": formData.fullName,
                    "Company": formData.companyName ? formData.companyName : undefined,
                    "Email": formData.email,
                    "Phone": formData.phoneNumber ? formData.phoneNumber : undefined,
                    "Form_Page_Url": "https://flyghtcloud.ideaforgetech.com/become-Provider",
                    "Form_Name": "Become a provider - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            setisFormSubmitted(true)
            console.log("Lead created successfully")
        }).catch(err => console.error("Error creating lead", err))
    }
    return (
        <div>
            <MobileHeader />
            <div className={classes['mob-container']}>
                {
                    isformSubmitted ? <div className={classes.submitedState}>
                        <h2 className={classes.barndingInfo}>
                            Your Request Is Submitted Scuccessfully</h2>
                        <p className={classes.ssbarndingInfo}>Our team will get back to you soon!</p>
                        <Button onClick={() => { navigate(`/`); }}
                            style={{ width: '100%', marginTop: "1em", background: "none", color: "#8D8D8D", padding: "8px 16px", borderRadius: "8px" }}>
                            Okay
                        </Button>
                    </div> : <div>
                        <h2 className={classes.barndingInfo}>
                            Join The <BrandName /> Ecosystem
                        </h2>
                        <p className={classes.ssbarndingInfo}>
                            Integrate your tools and offer enhanced solutions to <BrandName /> users.
                        </p>
                        <h3 className={classes.subBarndingInfo}>Connect with us for more details</h3>
                        <BecomeaProviderForm onSubmit={(d: any) => {
                            submitForm(d);
                        }} />
                        <Button onClick={() => { navigate(`/`); }}
                            style={{ width: '100%', marginTop: "1em", background: "none", color: "#8D8D8D", padding: "8px 16px", borderRadius: "8px" }}>
                            Back
                        </Button>
                    </div>
                }

            </div>

        </div>
    )
}

export default MobileBecomeProvider;
const useStyles = makeStyles({
    "mob-container": {
        width: "93%",
        margin: "2em auto 1em auto"
    },
    barndingInfo: {
        fontSize: "1.2rem",
        lineHeight: "1.5rem",
        fontWeight: "500",
        color: "#08201D",
    },
    subBarndingInfo: {
        fontSize: ".9rem",
        lineHeight: "1rem",
        marginTop: "2em",
        fontWeight: "500",
        color: "#08201D",
    },
    ssbarndingInfo: {
        fontSize: ".9rem",
        fontWeight: "400",
        lineHeight: "1.1rem",
        marginTop: ".5em"
    },
    submitedState: {
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
})