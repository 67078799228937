import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input, Label, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, tokens } from "@fluentui/react-components";
import React, { useState } from "react";
import { ProjectRequest, ProjectResponse, ProjectsService, WorkflowTemplateResponse, WorkflowTemplatesService } from "../../services/openapi";
import { WorkflowCard } from "../WorkflowCard";

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
    project: ProjectResponse;
    load: (page: number) => void;
}

const defaultWorkflowIDs = ["DEFAULT_MAPPING", "METASHAPE_MAPPING", "METASHAPE_MAPPING_W_GCPS", "ODM_MAP_STOCKPILE_REPORT", "METASHAPE_BFT_OUTPUT"]

const EditProjectDetailsModal = (props: ModalProps) => {
    const { visible, project, toggleModal, load } = props
    const [formValues, setFormvalues] = useState<ProjectRequest>({
        name: project.name,
        workflowTemplate: project.workflowTemplate
    });
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponse[]>([]);

    React.useEffect(() => {
        WorkflowTemplatesService.getTemplates()
            .then((ts) => {
                const defaultTemplates = ts.templates.filter(t => defaultWorkflowIDs.includes(t.id));
                setTemplates(defaultTemplates);
            })
            .catch((err) => console.error(`Could not get workflow templates: ${err}`));
    }, [])


    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        ProjectsService.editProjectDetails(project.id, formValues).then(() => {
            toggleModal(false);
            load(0)
        })
    };

    const getTemplateName = () => {
        const t = (templates || []).find((t) => t.id === formValues?.workflowTemplate);
        return t?.name || '';
    };

    return (
        <Dialog
            open={visible}
        >
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle>Edit Project Details</DialogTitle>
                        <DialogContent >
                            <Label htmlFor={"name-input"} style={{ marginRight: "0.75rem" }}>
                                Project Name
                            </Label>
                            <Input required id={"name-input"} value={formValues.name} onChange={(e) => { setFormvalues(prev => { return { ...prev, name: e.target.value } }) }} />

                            <div style={{ marginTop: '1rem', marginBottom: '2em' }}>
                                <div>
                                    Default Workflow
                                </div>
                                <Menu positioning={'below-start'}>
                                    <MenuTrigger     >
                                        <Button size='large' style={{ width: "100%", height: "2rem", marginTop: "0.25rem", justifyContent: "flex-start", fontWeight: "normal", fontSize: "14px" }}>
                                            {getTemplateName() || "-- Select One --"}
                                        </Button>
                                    </MenuTrigger>
                                    <MenuPopover style={{ width: "600px", maxWidth: "600px" }}>
                                        <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                            {templates.map((t) => {
                                                return <MenuItem
                                                    key={t.id}
                                                    style={{
                                                        backgroundColor: tokens.colorNeutralBackground1,
                                                        minWidth: "100%"
                                                    }}
                                                    onClick={() => {
                                                        setFormvalues({ ...formValues, workflowTemplate: t.id });
                                                    }}
                                                >
                                                    <WorkflowCard template={t} />
                                                </MenuItem>
                                            })}
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={() => toggleModal(false)}>Close</Button>
                            </DialogTrigger>
                            <Button type="submit" appearance="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog >
    );
};

export default EditProjectDetailsModal;