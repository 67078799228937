import * as React from 'react';

import { Button, CounterBadge, Dialog, DialogBody, DialogSurface, makeStyles, Select, Text, tokens } from "@fluentui/react-components";
import { FileTypeResponse, PluginResponse, PluginsService, WorkflowTemplateResponse, WorkflowTemplatesService } from '../../services/openapi';
import { Loading } from '../Loading';
import { Editmodel, AddPluginResult, } from './EditModel';
import { Settings24Regular } from '@fluentui/react-icons';
import { PluginIcon } from '../PluginIcon';
import validator from '@rjsf/validator-ajv8';
import { withTheme } from '@rjsf/core';
import { WidgetProps } from '@rjsf/utils';

const useStyles = makeStyles({
    schemaForm: {
        width: '100%',
        height: '28em',
        overflowY: 'auto',
    },
});
const Form = withTheme({
    widgets: {
        fileType: (props: WidgetProps) => {
            return <Select
                disabled={!!props.readonly}
                value={props.value}
                onChange={(_, e) => props.onChange(e.value)}
            >
                <option>-- Select one --</option>
                {props.formContext.fileTypes.map((ft: any) => {
                    return <option key={ft.id} value={ft.id}>{ft.id}</option>
                })}
            </Select>
        }
    },
});

interface Props {
    base?: WorkflowTemplateResponse;
    onChange?: (model: Editmodel) => void;
    readonly?: boolean;
}

export const TemplateEditor = ({ base, onChange, readonly }: Props) => {
    const [plugins, setPlugins] = React.useState<PluginResponse[] | null>();
    const [fileTypes, setFileTypes] = React.useState<FileTypeResponse[] | null>();
    const classes = useStyles();

    // state for editing
    const [model, setEditModel] = React.useState(new Editmodel());
    const [pluginChoice, setPluginChoice] = React.useState<AddPluginResult | null>();
    const [nodeConfig, setNodeConfig] = React.useState<{ nodeId: string, plugin: PluginResponse, config: any } | null>();

    React.useEffect(() => {
        Promise.all([
            PluginsService.getPlugins(),
            WorkflowTemplatesService.getFileTypes()
        ]).then((res) => {
            const plugins = res[0].plugins;
            setPlugins(plugins);
            setFileTypes(res[1].fileTypes);
            setEditModel(new Editmodel(plugins, base));
        }).catch((err) => console.error(err));
    }, [base]);

    React.useEffect(() => {
        if (onChange && !readonly) {
            onChange(model);
        }
    }, [model]);

    const addPluginForOutput = (fileTypeId: string) => {
        const res = model.addPluginForFileType(fileTypeId);
        setEditModel(model.clone());
        if (res.added === 'choice') {
            setPluginChoice(res);
        }
        if (res.added === 'none') {
            // we couldnt find any plugins providing this type
            // so we wil suggest system plugins for upload or for using project files
            const choices = [];
            const picker = plugins?.find((p) => p.id === 'PROJECT_FILE_PICKER');
            if (picker) {
                choices.push({ plugin: picker, config: { 'file_type': fileTypeId } });
            }
            const linker = plugins?.find((p) => p.id === 'WORKFLOW_FILE_UPLOADER');
            if (linker) {
                choices.push({ plugin: linker, config: { 'file_type': fileTypeId } });
            }
            if (choices.length > 0) {
                setPluginChoice({ added: 'choice', fileTypeId: res.fileTypeId, choices })
            }
        }
    }

    const addViewGenNodes = (viewGenConfig: any) => {
        model.addNode(viewGenConfig);
        setEditModel(model.clone());
    }

    if (!fileTypes || !plugins) {
        return <Loading />;
    }

    const filtered = fileTypes.filter((t) => {
        if (t.id === 'pg_report') return false;
        return ['zip', 'json', 'geojson', 'laz'].indexOf(t.extension) === -1;
    });
    const outputs = model.getGeneratedOutputs();
    const m = model.renderModel();

    return <>
        {!readonly && <div
            style={{
                width: '100%',
                marginTop: '0.5em',
                borderRadius: '0.5em',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: tokens.colorNeutralBackground1,
                padding: '1em',
                boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
            }}
        >
            <div>
                <div><b>Select Required Outputs</b></div>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '50% 50%',
                    }}
                >
                    {filtered.map((t) => {
                        return <div
                            key={t.id}
                            style={{
                                border: '0px',
                                borderRadius: '0.5em',
                                padding: '1em',
                                margin: '1em',
                                backgroundColor: '#F2F6FA',
                            }}
                        >
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}>
                                <div>
                                    <div style={{
                                        fontSize: '1.2em',
                                        fontWeight: '600'
                                    }}>
                                        {t.id}
                                    </div>
                                    <div>
                                        {t.description}
                                    </div>
                                </div>
                                {outputs.indexOf(t.id) === -1 ? (
                                    <Button style={{
                                        marginLeft: "0.5em",
                                        alignItems: "center",
                                        color: "#606060",
                                        borderColor: "#606060",
                                        maxHeight: '2.5em',
                                    }}
                                        appearance='subtle'
                                        onClick={() => {
                                            addPluginForOutput(t.id);
                                        }}
                                    >Add</Button>
                                ) : (
                                    'Added'
                                )}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>}
        <div style={{
            backgroundColor: '#FDFDFD',
            marginTop: '1em',
            padding: '1em',
            borderRadius: '0.5em',
            boxShadow: '5px 1px 20px 0px rgba(0, 0, 0, 0.05)'
        }}>
            <div><b>Added Plugins</b></div>
                <div>
                    {m.plugins.map((p, idx) => {
                        const plugin = plugins.find((pl) => pl.id === p.pluginId);
                        if (!plugin) {
                            return <></>;
                        }
                        return <div
                            key={p.id}
                            style={{
                                backgroundColor:'#F2F6FA',
                                borderRadius: '0.5em',
                                padding: '1em',
                                margin: '1em',
                                color: "#606060",
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'start',
                                        flexDirection: 'column',
                                        justifyContent: 'start',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CounterBadge count={m.plugins.length - idx} />&nbsp;
                                        <PluginIcon plginId={p.pluginId} coloured={false} /> &nbsp;&nbsp;
                                        <Text weight='semibold' size={400}>{plugin.name}</Text> &nbsp;
                                        (<Text weight='medium' size={200}>{p.id}</Text>)
                                    </div>
                                    <div>
                                        <Text size={300}>{plugin.description}</Text>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        appearance='subtle'
                                        disabled={!(plugin && plugin.schema)}
                                        onClick={() => setNodeConfig({ nodeId: p.id, config: p.config, plugin })}
                                    >
                                        <Settings24Regular style={{color: "#606060",}}/>
                                    </Button>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div
                                    style={{
                                        width: '50%',
                                        border: '1px solid #ACC7D5',
                                        borderRadius: '0.5em',
                                        padding: '0.5em',
                                        margin:'0.5em',
                                    }}
                                >
                                    <div style={{fontWeight:'600'}}>Outputs</div>
                                    {p.outputs.map((o: any) => <div
                                        key={o.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div>{o.id} ({o.type})</div>
                                        {o.viewGenConfig && (
                                            <Button
                                                appearance='outline'
                                                disabled={readonly}
                                                onClick={() => addViewGenNodes(o.viewGenConfig)}
                                            >Generate View</Button>
                                        )}
                                    </div>
                                    )}
                                </div>
                                <div
                                    style={{
                                        width: '50%',
                                        border: '1px solid #ACC7D5',
                                        borderRadius: '0.5em',
                                        padding: '0.5em',
                                        margin: '0.5em',
                                    }}
                                >
                                    <div style={{fontWeight:'600'}}>Inputs</div>
                                    {p.inputs.map((i) => <div
                                        key={i.id}
                                    >
                                        {i.id} ({i.type})
                                        {i.from ?
                                            `From ${i.from}`
                                            : <Button disabled={readonly} onClick={() => addPluginForOutput(i.type || '')}>Add plugin</Button>}
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
    
        <Dialog
            open={!!nodeConfig}
            onOpenChange={(_, s) => {
                if (!s.open) {
                    setNodeConfig(null);
                }
            }}
        >
            <DialogSurface style={{ height: '30em' }}>
                <DialogBody style={{ display: 'flex' }}>
                    <Form
                        readonly={readonly ||
                            ['PROJECT_FILE_PICKER', 'WORKFLOW_FILE_UPLOADER'].indexOf(nodeConfig?.plugin.id || '') > -1
                        }
                        className={classes.schemaForm}
                        formContext={{ fileTypes }}
                        schema={nodeConfig?.plugin.schema}
                        validator={validator}
                        formData={nodeConfig?.config}
                        uiSchema={{
                            "file_type": {
                                "ui:widget": "fileType"
                            }
                        }}
                        onChange={(e) => {
                            model.setNodeConfig(nodeConfig?.nodeId || '', e.formData);
                            setEditModel(model.clone());
                        }}
                    />
                </DialogBody>
            </DialogSurface>
        </Dialog>
        <Dialog
            open={!!pluginChoice}
            onOpenChange={(_, d) => {
                if (!d.open) {
                    setPluginChoice(null);
                }
            }}
        >
            <DialogSurface>
                <DialogBody style={{ display: 'flex', flexDirection: 'column' }}>
                    Output {pluginChoice?.fileTypeId} can be provided by multiple plugins.
                    Please select one below:
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent:'space-evenly', justifyItems:'baseline' }}>
                    {(pluginChoice?.choices || []).map((ch) => {
                        const p = ch.plugin;
                        return <div
                            key={p.id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '0px',
                                backgroundColor: '#F2F6FA',
                                padding:'2%',
                                width: '10em',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div style={{ fontWeight: '600' }}>{p.name}</div>
                            <div style={{ margin: '2%' }}><PluginIcon plginId={p.id} coloured={true}/></div>
                            <div>
                                <Button
                                    disabled={readonly}
                                    size='small'
                                    onClick={() => {
                                        model.addPlugin(p, ch.config);
                                        setEditModel(model.clone());
                                        setPluginChoice(null);
                                    }}
                                >Select</Button></div>
                        </div>
                    })}
                    </div>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    </>;
}
