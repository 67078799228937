import React, { useState } from 'react'
import MobileHeader from '../landing/common/MobileHeader'
import { Login } from './Login'
import { AuthService } from '../../services/openapi';
import { Button, Toast, Toaster, ToastTitle, useId, useToastController } from '@fluentui/react-components';
import ToastErrorIcon from "../../assets/icons/toast_error.svg";
import { AuthContext } from '../../AuthContext';
import { Signup } from './Signup';
import { OTPVerfication } from './OTPVerfication';
import { ForgotPassword } from './ForgotPassword';
import { ForgotPasswordVerification } from './ForgotPasswordVerification';
import congratutionIcon from "../../assets/icons/landing/congratulation.svg"
import MobileDesclimer from '../landing/pages/home/MobileDesclimer';

interface SignUpProps {
    fullName: string,
    email: string,
    password: string,
    confirmPassword: string
}
interface ForgotPAsswordProps {
    email: string,
}

interface ForgotPasswordVerificationProps {
    otp: string,
    password: string,
    confirmPassword: string
}

function MobileLogin() {
    const [isAuthForm, setIsAuthForm] = React.useState<'login' | string>('login');
    const [isLoginDisabled, setIsLoginDisabled] = React.useState(false);
    const [isSignUpDisabled, setIsSignUpDisabled] = React.useState(false);
    const initialSignUpValues = { fullName: '', email: '', password: '', confirmPassword: '' };
    const [signUpValues, setSignUpValues] = React.useState<SignUpProps>(initialSignUpValues);
    const [isOtpVerificationBtn, setIsOtpVerificationBtn] = React.useState(false);
    const [isForgotDisabaled, setIsForgotDisabaled] = React.useState(false);
    const initiaForgotPasswordValues = { email: '', };
    const initiaFPWValues = { otp: '', password: '', confirmPassword: '' };
    const [forgotPasswordVerificationValues, setForgotPasswordVerificationValues] = React.useState<ForgotPasswordVerificationProps>(initiaFPWValues);
    const [forgotPasswordValues, setForgotPasswordValues] = React.useState<ForgotPAsswordProps>(initiaForgotPasswordValues);
    const [isForgotPasswordVerification, setIsForgotPasswordVerification] = React.useState(false);
    const [showMobileDisclaimer, setShowMobileDisclaimer] = useState(false);

    const loggedMe = React.useContext(AuthContext);

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);
    const timeout = 2000;

    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DCF7EFEF" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const notifyError = (err: string) => {
        dispatchToast(
            <Toast
                style={{ background: "#FDE7E9" }}>
                <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}
                </ToastTitle>
            </Toast>,
            { timeout, intent: "error" }
        );
    }

    const login = (d: any) => {
        setIsLoginDisabled(true)
        AuthService.login(d)
            .then((res) => {
                loggedMe.setMe(res)
                setIsLoginDisabled(false)
                if (res.token) {
                    setShowMobileDisclaimer(true);
                }
            })
            .catch((err) => {
                console.table(err)
                notifyError("Either email or password is invalid")
                setTimeout(() => {
                    setIsLoginDisabled(false)
                }, timeout);
            })
    }

    type SignUpRequest = {
        email: string;
        fullName: string;
        password: string;
        confirmPassword: string;
    };
    const handleSignUp = ({ fullName, email, password, }: SignUpRequest) => {
        setIsSignUpDisabled(true)
        const signUpData = {
            name: fullName,
            email,
            password,
        };
        AuthService.signUp(signUpData)
            .then((res) => {
                setIsSignUpDisabled(false)
                setIsAuthForm('otpVerification')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsSignUpDisabled(false)
                }, timeout);
                notifyError('This email is already in use. Please log in or try another')
            })
    }

    const handleOtp = (otpValue: string | { otp: string }) => {
        const verifyOtpData = {
            otp: (otpValue as { otp: string }).otp,
            email: signUpValues.email
        }
        setIsOtpVerificationBtn(true)
        AuthService.verifyOtp(verifyOtpData)
            .then((res) => {
                setIsOtpVerificationBtn(false)
                setShowMobileDisclaimer(true)
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsOtpVerificationBtn(false)
                }, timeout);
                notifyError('Invalid OTP. Please try again.')
            })
    }

    const handleForgotPassword = (formValues: any) => {
        setIsForgotDisabaled(true)
        AuthService.resetPassword(formValues)
            .then((res) => {
                setIsForgotDisabaled(false)
                setIsAuthForm('forgotPasswordVerification')
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsForgotDisabaled(false)
                }, timeout);
                notifyError('The entered email does not exist.')
            })
    }

    const handleForgotPasswordVerification = (formValues: any) => {
        const fPVericationData = {
            email: forgotPasswordValues.email,
            otp: formValues.otp,
            newPassword: formValues.password
        };
        setIsForgotPasswordVerification(true)
        AuthService.verifyResetPasswordOtp(fPVericationData)
            .then((res) => {
                setIsForgotPasswordVerification(false)
                setForgotPasswordVerificationValues(initiaFPWValues);
                setForgotPasswordValues(initiaForgotPasswordValues);
                setIsAuthForm('login')
                notifySucess("Successfully reset your password");
            })
            .catch((err) => {
                console.table(err)
                setTimeout(() => {
                    setIsForgotPasswordVerification(false)
                }, timeout);
                notifyError('Invalid OTP. Please try again.')
            })

    }

    const handleGoBack = (formType: string) => {
        setIsAuthForm(formType)
    }

    const Congratulation = () => {
        return <div style={{
            width: "93%",
            margin: "5em auto 1em auto",
            padding: "1em",
            textAlign: "center",
            flexDirection: "column"
        }}>
            <img src={congratutionIcon} alt='congratutionIcon' style={{ width: "30%" }} />
            <h3 style={{ fontWeight: "500", fontSize: "1rem" }}>Congratulation, your account
                has been successfully created</h3>
            <Button
                onClick={() => { setShowMobileDisclaimer(true) }}
                style={{
                    width: '100%', marginTop: "1em", background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px"
                }}
            >
                Continue
            </Button>
        </div>
    }

    return (
        <>
            {showMobileDisclaimer ? <MobileDesclimer /> :
                <div>
                    <MobileHeader />
                    <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
                        {
                            isAuthForm === 'login' ?
                                <>
                                    <Login
                                        onSubmit={(d: any) => login(d)}
                                        onChangeAuthForm={(formType: string) => setIsAuthForm(formType)}
                                        buttonState={isLoginDisabled}
                                    />
                                    <Toaster toasterId={toasterId} position="top-end" />
                                </>
                                :
                                isAuthForm === 'SignUp' ?
                                    <>
                                        <Signup onSubmit={(d: any) => {
                                            handleSignUp(d);
                                        }}
                                            initialState={initialSignUpValues}
                                            onChangeAuthForm={handleGoBack}
                                            formData={signUpValues}
                                            setSignUpValues={setSignUpValues}
                                            buttonState={isSignUpDisabled}
                                        />
                                        <Toaster toasterId={toasterId} position="top-end" />
                                    </>
                                    :
                                    isAuthForm === 'otpVerification' ?
                                        <>
                                            <OTPVerfication
                                                onSubmit={(d: any) => {
                                                    handleOtp(d);
                                                }}
                                                onChangeAuthForm={(formType: string) => setIsAuthForm(formType)}
                                                handleResend={(d: any) => {
                                                    handleSignUp(d);
                                                }}
                                                signUpFormData={signUpValues}
                                                buttonState={isOtpVerificationBtn}

                                            />
                                            <Toaster toasterId={toasterId} position="top-end" />
                                        </>
                                        :
                                        isAuthForm === 'forgotPassword' ?
                                            <>
                                                <ForgotPassword
                                                    onSubmit={(d: any) => {
                                                        handleForgotPassword(d);
                                                    }}
                                                    initialState={initiaForgotPasswordValues}
                                                    onChangeAuthForm={handleGoBack}
                                                    formData={forgotPasswordValues}
                                                    setForgotPasswordValues={setForgotPasswordValues}
                                                    buttonState={isForgotDisabaled}

                                                />
                                                <Toaster toasterId={toasterId} position="top-end" />
                                            </>
                                            :
                                            isAuthForm === 'forgotPasswordVerification' ?
                                                <>
                                                    <ForgotPasswordVerification
                                                        onSubmit={(d: any) => {
                                                            handleForgotPasswordVerification(d);
                                                        }}
                                                        initialState={initiaFPWValues}
                                                        onChangeAuthForm={handleGoBack}
                                                        formData={forgotPasswordVerificationValues}
                                                        setForgotPasswordVerificationValues={setForgotPasswordVerificationValues}
                                                        buttonState={isForgotPasswordVerification}
                                                    />
                                                    <Toaster toasterId={toasterId} position="top-end" />
                                                </>
                                                :
                                                <Congratulation />
                        }

                    </div>
                </div>
            }

        </>
    )
}

export default MobileLogin