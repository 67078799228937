import { makeStyles, shorthands } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TimeRange, TransactionsBucket } from "../../services/openapi";

const colors = ["#0077CE", "#66BEFE", "#A4CCFF", "#B7A4FE", "#EBA2FE", "#FF9FDE", "#FFA09E", "#FFDCA2", "#ECFFA1", "#B2FFA1", "#A2FFC8", "#A5FFFF"]

const itemTypeLabel: Record<string, string> = {
}
interface Props {
	transactions?: Array<TransactionsBucket>
	topOffset: string;
	currentFilter?: TimeRange
}

export default function CreditUsageCard({ transactions, topOffset, currentFilter = TimeRange.PAST_WEEK }: Props) {
	const classes = useStyles();
	const [donutSize, setDonutRadius] = useState({
		size: "200px",
		radius: 100,
		innerSize: "150px",
	});
	const tx = transactions && transactions.length > 0 ? transactions.filter(t => t?.amount && t.amount > 0) : [];

	useEffect(() => {
		const handleResize = () => {
			console.log("CURRENT", window.innerWidth)
			if (window.innerWidth > 1600) {
				setDonutRadius({
					size: "300px",
					radius: 150,
					innerSize: "210px",
				})
			} else if (window.innerWidth > 1200) {
				setDonutRadius({
					size: "200px",
					radius: 100,
					innerSize: "150px",
				})
			} else {
				setDonutRadius({
					size: "100px",
					radius: 50,
					innerSize: "50px",
				})
			}
		};
		handleResize()
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	let currentAngle = 0;
	const calculateLabelPosition = (angle: any) => {
		const radius = donutSize.radius;
		const x = Math.cos((angle - 90) * (Math.PI / 180)) * radius;
		const y = Math.sin((angle - 90) * (Math.PI / 180)) * radius;

		return {
			top: `calc(50% + ${y}px)`,
			left: `calc(50% + ${x}px)`,
			transform: 'translate(-50%, -50%)',
		};
	};
	const totalAmount = tx.reduce((accumulator, currentValue) => {
		if (!currentValue.amount) {
			return 0
		}
		return accumulator + currentValue?.amount;
	}, 0);

	const percentages = tx.map(item => {
		if (!item.amount) {
			return 0
		}
		return (item?.amount / totalAmount) * 100
	})
	const generateConicGradient = () => {
		let currentTotalPercentage = 0
		const gradientString = percentages.map((p, index) => {
			let str;
			let color: string;
			if (index + 1 > colors.length) {
				color = "#DBE1E5"
			} else {
				color = colors[index]
			}
			if (index === 0) {
				str = `${color} 0% ${p}%`;
			} else if (index === percentages.length - 1) {
				str = `${color} ${currentTotalPercentage}% 100%`;
			} else {
				str = `${color} ${currentTotalPercentage}% ${currentTotalPercentage + p}%`;
			}
			currentTotalPercentage += p
			return str
		}).join(', ');
		return `conic-gradient(${gradientString})`;
	}

	const convertToUpperCase = (s: string) => {
		return s
			.split('_')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
	}


	return <div className={classes.container} style={{ maxHeight: `calc(100vh - ${topOffset})`, height: `calc(100vh - ${topOffset})` }}>
		<div className={classes.cardHeaderContainer}>
			<h2 className={classes.heading}>Credits Usage</h2>
			{currentFilter && <p className={classes.filterText}>{currentFilter}</p>}
		</div>

		{tx.length === 0 && <NoCreditUsageState donutSize={donutSize} />}

		{tx.length > 0 && <>
			<div className={classes.donutContainer}>
				<div className={classes.donut} style={{
					position: "relative",
					backgroundImage: generateConicGradient(),
					width: donutSize.size,
					height: donutSize.size
				}}>
					<div className={classes.donutCircleContainer}>
						<div className={classes.donutCircle}
							style={{
								height: donutSize.innerSize,
								width: donutSize.innerSize
							}}>
							<p className={classes.donutLabel}>{totalAmount.toFixed()}</p>
							<p>Credits</p>
						</div>
					</div>

					{percentages.map((per) => {
						const p = parseInt(per.toFixed())
						let position = currentAngle + ((p / 100) * 360) / 2
						currentAngle = currentAngle + ((p / 100) * 360)
						return <div style={{
							...calculateLabelPosition(parseInt(position.toFixed()))
						}} className={classes.percentageLabel}>
							{`${p.toFixed()}%`}
						</div>
					})}
				</div>
			</div>

			<div className={classes.legendContainer}>
				{tx.map((item, i) => {
					const color = colors[i]
					return <div className={classes.legendContentContainer}>
						<div className={classes.legendColor} style={{ background: color }}></div>
						<p style={{ color, fontWeight: 600 }}>{itemTypeLabel[item.item_type_id] ? itemTypeLabel[item.item_type_id] : convertToUpperCase(item.item_type_id)}</p>
					</div>
				})}
			</div>

			<div className={classes.actionsContainer}>
				<button
					style={{ all: "unset", boxSizing: "border-box", padding: "8px 16px", borderRadius: "8px", border: "1px solid #5C74E6", color: "#5E5CE6", cursor: "pointer", textAlign: "center", }}>
					<Link to={"/transactions"} style={{ textDecoration: "none" }}>
						View Transaction History
					</Link>
				</button>
			</div>
		</>}
	</div >
}

interface NoCreditProps {
	donutSize: {
		size: string;
		innerSize: string;
		radius: number;
	}
}

function NoCreditUsageState({ donutSize }: NoCreditProps) {
	const classes = useStyles()

	return <>
		<div className={classes.donutContainer}>
			<div className={classes.donut} style={{
				position: "relative",
				background: "#DBE1E5",
				width: donutSize.size,
				height: donutSize.size
			}}>
				<div className={classes.donutCircleContainer}>
					<div className={classes.donutCircle}
						style={{
							height: donutSize.innerSize,
							width: donutSize.innerSize
						}}>
						<p className={classes.donutLabel} style={{ color: "#586A84" }}>No Data</p>
					</div>
				</div>
			</div>
		</div>
		<div className={classes.actionsContainer}>
			<p className={classes.tipText}>Tip: Try different filter options from the Recent Flight Data section.</p>
		</div>
	</>

}

const useStyles = makeStyles({
	container: {
		...shorthands.borderRadius("1rem"),
		...shorthands.border("1px", "solid", "#E3E9F2"),
		width: "25%",
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		paddingBottom: "36px",
		overflowX: "hidden",
	},
	heading: {
		color: "#043957",
		fontSize: "28px",
		fontWeight: 600,
		lineHeight: "36px",
	},
	cardHeaderContainer: {
		paddingBottom: "18px",
		paddingRight: "32px",
		paddingTop: "32px",
		paddingLeft: "32px",
		backgroundColor: "white",
		borderTopLeftRadius: "1rem",
		borderTopRightRadius: "1rem",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	iconImage: {
		minWidth: "18px",
		minHeight: "18px",
	},
	filterButton: {
		...shorthands.border("1px solid #7D8FA9"),
		...shorthands.padding("6px 12px"),
		":hover": {
			backgroundColor: "#E5E5FE",
		}
	},
	filterButtonText: {
		fontSize: "14px",
		color: "#7D8FA9",
		fontWeight: 400,
	},
	menuText: {
		fontSize: "14px",
	},
	donutContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		marginTop: "22px",

	},
	donut: {
		backgroundColor: "#A6A4F4",
		...shorthands.borderRadius("50%"),
		position: "relative",
		boxShadow: "-1px 1px 20px 0px rgba(0, 0, 0, 0.15)"
	},
	donutCircleContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	donutCircle: {
		...shorthands.borderRadius("50%"),
		backgroundColor: "#FFF",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		color: "#1B198F",
		fontSize: "14px",
	},
	donutLabel: {
		fontSize: "20px",
		fontWeight: 600,
		lineHeight: "28px",
	},
	percentageLabel: {
		position: 'absolute',
		fontSize: '18px',
		fontWeight: 600,
		color: '#504ED7',
		textAlign: 'center',
		paddingTop: "8px",
		paddingBottom: "8px",
		paddingLeft: "1rem",
		paddingRight: "1rem",
		...shorthands.borderRadius("1rem"),
		backgroundColor: "rgba(243, 243, 243, 0.60)",
		boxShadow: "1px 0px 5px 0px rgba(0, 0, 0, 0.10)",
		backdropFilter: "blur(2px)"
	},
	legendContainer: {
		marginTop: "2.5rem",
		paddingLeft: "2rem",
		display: "flex",
		flexDirection: "column",
		...shorthands.gap("0.5rem"),
	},
	legendContentContainer: {
		display: "flex",
		...shorthands.gap("0.5rem"),
		alignItems: "center",
	},
	legendColor: {
		width: "30px",
		height: "15px",
		...shorthands.borderRadius("4px"),
	},
	actionsContainer: {
		marginTop: "2rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	tipText: {
		fontSize: "12px",
		color: "#586A84",
		paddingLeft: "32px",
		paddingRight: "32px",
	},
	filterText: {
		color: "#586A84",
		fontSize: "14px",
	},
})