/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookmarkRequest } from '../models/BookmarkRequest';
import type { BookmarkResponse } from '../models/BookmarkResponse';
import type { CreateTemplateRequest } from '../models/CreateTemplateRequest';
import type { FileTypesResponse } from '../models/FileTypesResponse';
import type { WorkflowTemplateResponse } from '../models/WorkflowTemplateResponse';
import type { WorkflowTemplatesResponse } from '../models/WorkflowTemplatesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkflowTemplatesService {

    /**
     * @returns FileTypesResponse Get known file types
     * @throws ApiError
     */
    public static getFileTypes(): CancelablePromise<FileTypesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/file_types',
        });
    }

    /**
     * @returns WorkflowTemplatesResponse Get workflow templates
     * @throws ApiError
     */
    public static getTemplates(): CancelablePromise<WorkflowTemplatesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflow_templates',
        });
    }

    /**
     * @param requestBody
     * @returns WorkflowTemplateResponse Create workflow template
     * @throws ApiError
     */
    public static createWorkflowTemplate(
        requestBody: CreateTemplateRequest,
    ): CancelablePromise<WorkflowTemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/workflow_templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns BookmarkResponse Get bookmarks
     * @throws ApiError
     */
    public static getBookmarks(): CancelablePromise<BookmarkResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflow_templates/bookmarks',
        });
    }

    /**
     * @param requestBody
     * @returns any Bookmark workflow template
     * @throws ApiError
     */
    public static bookmarkWorkflowTemplate(
        requestBody: BookmarkRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/workflow_templates/bookmarks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param templateId
     * @returns any Unbookmark workflow template
     * @throws ApiError
     */
    public static unbookmarkWorkflowTemplate(
        templateId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/workflow_templates/bookmarks/{template_id}',
            path: {
                'template_id': templateId,
            },
        });
    }

    /**
     * @param templateId
     * @returns WorkflowTemplateResponse Get workflow template by id
     * @throws ApiError
     */
    public static getTemplate(
        templateId: string,
    ): CancelablePromise<WorkflowTemplateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/workflow_templates/{template_id}',
            path: {
                'template_id': templateId,
            },
        });
    }

    /**
     * @param templateId
     * @param requestBody
     * @returns WorkflowTemplateResponse Edit workflow template
     * @throws ApiError
     */
    public static editWorkflowTemplate(
        templateId: string,
        requestBody: CreateTemplateRequest,
    ): CancelablePromise<WorkflowTemplateResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/workflow_templates/{template_id}',
            path: {
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
