/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganisationRequest } from '../models/CreateOrganisationRequest';
import type { CreditsRequest } from '../models/CreditsRequest';
import type { OrganisationResponse } from '../models/OrganisationResponse';
import type { OrganisationsResponse } from '../models/OrganisationsResponse';
import type { Project } from '../models/Project';
import type { RoleRequest } from '../models/RoleRequest';
import type { RoleResponse } from '../models/RoleResponse';
import type { RolesResponse } from '../models/RolesResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganisationsService {

    /**
     * @returns RolesResponse Organisation Roles
     * @throws ApiError
     */
    public static getMyRoles(): CancelablePromise<RolesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my_organisation_roles',
        });
    }

    /**
     * @returns OrganisationsResponse Your organisations
     * @throws ApiError
     */
    public static getMyOrganisations(): CancelablePromise<OrganisationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my_organisations',
        });
    }

    /**
     * @param orgId
     * @param userId
     * @returns any Organisation role removed, no content
     * @throws ApiError
     */
    public static removeMyOrganisationRole(
        orgId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/my_organisations/{org_id}/roles/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
        });
    }

    /**
     * @returns OrganisationsResponse Organisations
     * @throws ApiError
     */
    public static getAllOrganisations(): CancelablePromise<OrganisationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organisations',
        });
    }

    /**
     * @param requestBody
     * @returns OrganisationResponse Organisation added
     * @throws ApiError
     */
    public static addOrganisation(
        requestBody: CreateOrganisationRequest,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organisations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @param requestBody
     * @returns OrganisationResponse Add credits for organisation
     * @throws ApiError
     */
    public static addCredits(
        orgId: string,
        requestBody: CreditsRequest,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organisations/{org_id}/credits',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @returns Project Organisation Projects
     * @throws ApiError
     */
    public static getAllMemberProjects(
        orgId: string,
    ): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organisations/{org_id}/projects',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId
     * @returns RolesResponse Organisation Roles
     * @throws ApiError
     */
    public static getAllMemberRoles(
        orgId: string,
    ): CancelablePromise<RolesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organisations/{org_id}/roles',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId
     * @param userId
     * @param requestBody
     * @returns RoleResponse Role Updated
     * @throws ApiError
     */
    public static setOrganisationRole(
        orgId: string,
        userId: string,
        requestBody: RoleRequest,
    ): CancelablePromise<RoleResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organisations/{org_id}/roles/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @param userId
     * @returns any Organisation role removed, no content
     * @throws ApiError
     */
    public static removeOrganisationRole(
        orgId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/organisations/{org_id}/roles/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
        });
    }

}
