import { Button, Card, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTrigger, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import closeBtn from "../../../assets/icons/landing/close.svg";
import helloThere_icon from "../../../assets/icons/landing/helloThere.svg";
import demoSuccess_icon from "../../../assets/icons/landing/successDemo.svg";
import useUTMParams from '../../../hooks/useUTMParams';
import { LeadRequest, ZohoService } from '../../../services/openapi';
import { BookDemoData } from './BookDemoData';
import BookDemoForm from './BookDemoForm';

interface bookDemoLogos {
    id: number,
    title: string,
}
interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;

}

const pathNameToLeadSource: Record<string, string> = {
    "/": "Connect to expert - Home",
    "/solutions": "Connect to expert - Solutions",
    "/about-us": "Connect to expert - About Us",
}

function BookDemoModal(props: ModalProps) {
    const { visible, toggleModal } = props;
    const classes = useStyles();
    const [bookDemoDatas, setBookDemoDatas] = React.useState<bookDemoLogos[] | null>(null);
    const [isformSubmitted, setisFormSubmitted] = React.useState<boolean>(false)
    const location = useLocation();
    const utmParams = useUTMParams();

    React.useEffect(() => {
        setisFormSubmitted(false)
        setBookDemoDatas(BookDemoData);
    }, [visible]);

    const createLead = (formData: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": pathNameToLeadSource[location.pathname] ? pathNameToLeadSource[location.pathname] : "Connect to expert",
                    "Company": formData.companyName ? formData.companyName : undefined,
                    "Phone": formData.phoneNumber ? formData.phoneNumber : undefined,
                    "Last_Name": formData.fullname,
                    "Email": formData.email,
                    "Country": formData.country ? formData.country : undefined,
                    "Industry": formData.industry.length > 0 ? formData.industry.join(", ") : undefined,
                    "Designation": formData.designation ? formData.designation : undefined,
                    "City": formData.city ? formData.city : undefined,
                    "Form_Page_Url": `https://flyghtcloud.ideaforgetech.com${location.pathname}`,
                    "Form_Name": "Connect to expert - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            setisFormSubmitted(true);
        }).catch(err => console.error("Error creating lead", err))
    }

    const handleSubmit = (formData: any) => {
        createLead(formData)
        console.log('Form submitted with data:', formData, isformSubmitted);
    };

    return <Dialog
        open={visible} modalType="non-modal"
    >
        <DialogSurface className={mergeClasses(classes.gradient)} style={{ margin: 0, maxWidth: "100%", width: "100%", position: "fixed", height: "100vh", }}>
            <DialogBody style={{ height: "100vh", display: "flex", alignItems: "center", color: "#fffff", position: "relative" }}>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <DialogTrigger disableButtonEnhancement>
                            <img style={{ position: "absolute", top: 0, right: 0, cursor: "pointer" }} src={closeBtn} alt='close' onClick={() => toggleModal(false)} />
                        </DialogTrigger>
                    </DialogTrigger>
                </DialogActions>
                <DialogContent style={{ color: "white", display: "flex", width: "90%", alignItems: 'center', margin: "3em auto", justifyContent: "space-between", }}>
                    <div className={classes.contentBox}>
                        <h2 className={classes.bgHeader}>See How Flyght Cloud Can Take Your Drone Data to the Next Level</h2>
                        <p style={{ marginTop: ".5em" }} className={classes.subHeader}>Connect with our experts and get a demo to unlock powerful insights today!</p>
                        <p style={{ marginTop: "3em" }} className={classes.subHeader}>Crafted by ideaForge and supported by industry experts and leaders.</p>
                        {bookDemoDatas && (
                            <div className={classes.grid}>
                                {bookDemoDatas.map((data, index) => (
                                    <div key={index} >
                                        <Card style={{
                                            background: "none", color: "white", border: "1px solid white", padding: '1em', borderRadius: "8px"
                                        }} className={classes.card}>
                                            <img alt='partner_logo' src={data.title
                                            } style={{ width: "100%", height: "100%" }} />
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={classes.formBox} style={{ paddingLeft: "8em", position: "relative", }}>
                        {
                            isformSubmitted ? <div style={{
                                background: "white",
                                borderRadius: "16px",
                                flex: "1",
                                padding: "3em"
                            }} className={classes.resContainer}>

                                <img src={demoSuccess_icon} style={{ width: "140px" }} alt='demoSuccess_icon' />
                                <h2 className={classes.primaryHeader}>Request Sent Successfully</h2>
                                <p className={classes.regularTxt}>Thank you! One of our experts will reach out to you shortly. In the meantime, please check your email for further details.</p>
                                <Button style={{ background: "#5E5CE6", color: "white", padding: ".6em" }} onClick={() => toggleModal(false)}>
                                    Back to home page
                                </Button>
                            </div> : <>
                                <div style={{ position: "absolute", right: "12%", height: "15%", top: "0", width: "50%" }}>
                                    <div style={{ position: "relative", height: "100%" }}>
                                        <div className={classes.thereContainer}>
                                            Hey! Need expert advice?<br /> Connect to expert!
                                        </div>
                                        <img src={helloThere_icon} alt='greeting_icon' style={{ width: "25%", position: "absolute", right: "0", bottom: "-10px" }} />
                                    </div>
                                </div>
                                <BookDemoForm onSubmit={handleSubmit} /></>}
                    </div>
                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}


const useStyles = makeStyles({
    gradient: {
        backgroundImage: 'linear-gradient(to left top,rgb(28 7 122), rgb(0, 0, 3) )'
    },
    contentBox: {
        width: "50%",

    },
    formBox: {
        width: "50%",

    },
    bgHeader: {
        color: "#FFF",
        fontSize: "40px",
        fontWeight: 600,
        lineHeight: "52px"
    },
    subHeader: {
        color: "#FFF",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "28px"
    },
    primaryHeader: {
        fontSize: "1.5rem",
        fontWeight: 600,
        lineHeight: '2rem'
    },
    regularTxt: {
        fontSize: ".8rem",
        textAlign: "center",
        marginTop: "1.5em",
        marginBottom: "1.5em"
    },
    grid: {
        display: "grid",
        gridTemplateColumns: 'repeat(4, 3fr)',
        marginTop: "2em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    card: {
        width: "98%",
        maxWidth: "100%",
        height: 'calc(20vh - 3rem)',
        display: "flex", alignItems: "center", justifyContent: "center",
    },
    resContainer: {
        marginTop: "5em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "#586A84",
        width: "85%",
    },
    thereContainer: {
        position: "absolute",
        color: "#000000",
        paddingLeft: ".5em",
        paddingTop: ".5em",
        paddingBottom: ".5em",
        paddingRight: "3em",
        backgroundColor: "white",
        ...shorthands.borderRadius("4px"),
        left: "5%",
        fontWeight: "400",
        top: "0",
    }


})
export default BookDemoModal