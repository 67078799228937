import * as React from "react";
import { Button, makeStyles } from "@fluentui/react-components";
import "./styles.css"
interface OtpForm {
    otp: string;
}
interface SignForm {
    fullName: string,
    email: string,
    password: string,
    confirmPassword: string
}


interface OTPVerificationProps {
    onSubmit: (f: OtpForm) => void;
    onChangeAuthForm: (f: string) => void;
    handleResend: (f: SignForm) => void;
    signUpFormData: SignForm;
    buttonState: boolean;
}

const useStyles = makeStyles({
    primaryHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
        paddingTop: "1em",
        paddingBottom: "1em",
        textAlign: "center"
    },
    spanColor: {
        color: "#1570EF",
        cursor: "pointer"
    },
});

export const OTPVerfication = (props: OTPVerificationProps) => {
    const classes = useStyles();
    const [otp, setOtp] = React.useState(['', '', '', '']);
    const [remainingTime, setRemainingTime] = React.useState(600);
    const [disableResend, setDisableResend] = React.useState(true);
    const inputRefs: React.MutableRefObject<HTMLInputElement | null>[] = [React.useRef(null), React.useRef(null), React.useRef(null), React.useRef(null)];

    const handleChange = (index: number, value: string) => {
        const initialOtp = [...otp];
        initialOtp[index] = '';
        setOtp(initialOtp)
        if (isNaN(parseInt(value))) return;
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value !== '' && index < inputRefs.length - 1 && inputRefs[index + 1]?.current !== null) {
            inputRefs[index + 1]?.current?.focus();
        }
    };

    React.useEffect(() => {
        if (remainingTime === 0) {
            setDisableResend(false);
        } else {
            const timer = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [remainingTime]);

    const handleResend = () => {
        setDisableResend(true);
        setRemainingTime(600);
        props.handleResend(props.signUpFormData);

    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const enteredOTP = otp.join('');
        const otpForm: OtpForm = { otp: enteredOTP };
        props.onSubmit(otpForm);
    };


    return <div className="verificationContainer">
        <h3 className={classes.primaryHeading}>Email  Verification</h3>
        <p style={{ textAlign: "center" }}>We've sent an email to <b>{props.signUpFormData.email}</b>. Enter the four digit code sent to your email. </p>
        <form className="otpFormContainer"
            onSubmit={handleSubmit}
            style={{ marginTop: "1em", }}
        >
            <div className="tstOtpContainer">
                {otp.map((digit, index) => (
                        <input
                           className="otp-input"
                            key={index}
                            type="text"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            ref={inputRefs[index]}
                        />
                ))}
            </div>
            <div>
                <Button disabled={otp.join('').length !== 4 || props.buttonState}
                    style={{
                        width: '100%', marginTop: "1em", background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px"
                    }}
                    type="submit"
                >
                    Submit
                </Button>
                <Button style={{
                    width: '100%', marginTop: "1em", padding: "8px 16px", borderRadius: "8px"
                }} disabled={disableResend} onClick={handleResend}>
                    {disableResend ? `Resend Email (${Math.floor(remainingTime / 60)}:${remainingTime % 60 < 10 ? '0' : ''}${remainingTime % 60})` : "Resend Email"}
                </Button>
            </div>
        </form>
    </div>

};
