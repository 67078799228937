
import * as React from 'react'
import flyghtcloud_logo from "../../../../assets/icons/landing/logo_green.svg"
import HeaderNavBar from '../../common/HeaderNavBar'
import { Button, Toast, ToastTitle, makeStyles, shorthands, useId, useToastController } from '@fluentui/react-components'
import about_bg from "../../../../assets/icons/landing/about.webp"
import { AuthService } from '../../../../services/openapi'
import { AuthContext } from '../../../../AuthContext'
import ToastErrorIcon from "../../../../assets/icons/toast_error.svg";
import { aboutUsData } from '../../common/AboutUsData'
import OfferCard from './OfferCard'
import offerImg from "../../../../assets/icons/landing/offerImg.webp"
import { useNavigate } from 'react-router-dom'
import Footer from '../../common/Footer'
import BookDemoModal from '../../common/BookDemoModal'
import BrandName from '../../../common/BrandName'

interface aboutUsDataProps {
    id: number,
    title: string,
    info: string,
    icon: string,
}
function AboutUs() {
    const classes = useStyles()
    const toasterId = useId("toaster");
    const loggedMe = React.useContext(AuthContext);
    const navigate = useNavigate();
    const timeout = 2000;
    const { dispatchToast } = useToastController(toasterId);
    const [aboutUsDatas, setAboutUsDatasProps] = React.useState<aboutUsDataProps[] | null>(null);
    const [isLoginDisabled, setIsLoginDisabled] = React.useState(false);
    const [bookDemoModalVisible, setBookDemoModalVisible] = React.useState(false);


    React.useEffect(() => {
        setAboutUsDatasProps(aboutUsData);
    }, []);

    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const login = (d: any) => {
        setIsLoginDisabled(true)
        AuthService.login(d)
            .then((res) => {
                loggedMe.setMe(res)
                setIsLoginDisabled(false)
                if (res.token) {
                    navigate(`/dashboard`);
                }
            })
            .catch((err) => {
                console.table(err)
                notifyError("Either email or password is invalid")
                setTimeout(() => {
                    setIsLoginDisabled(false)
                }, timeout);
            })
    }

    const toggleBookDemoModal = (flag: boolean) => {
        setBookDemoModalVisible(flag)
    }
    return <>
        <div className={classes.headerNavBar}>
            <HeaderNavBar login={login} toasterId={toasterId} icon={flyghtcloud_logo} signInBorder={true} color='black' btnBg="#E5E8EB" btnStatus={isLoginDisabled} />
        </div>
        <div style={{ maxWidth: "90%", margin: "2em auto" }}>
            <div className={classes.bgContainer} style={{ backgroundPosition: "center" }}>
                <h1 className={classes.bgHeader}>About <BrandName /></h1>
                <h3 className={classes.bgSubHeader}><BrandName /> is a SaaS platform developed by ideaForge Technology Limited, a pioneer and pre-eminent market leader in the Indian unmanned aircraft systems (“UAS”) market. With the largest operational deployment of indigenous UAVs across India, an ideaForge manufactured drone takes off every five minutes for surveillance and mapping on average. Our customers have completed over 450,000 flights using our UAVs, solidifying our position as a global leader in the dual-use category (civil and defense) drone manufacturers.</h3>
            </div>
            <div style={{ margin: "0 auto", display: "flex", justifyContent: "space-between", position: "relative" }} >
                <div className={classes.quesContainer}>
                    <h2 className={classes.primaryHeading}>Our Mission</h2>
                    <p>At <BrandName />, our mission is to empower businesses and organizations with the most advanced and innovative drone data analytics solutions. We believe that the power of drone data lies in its ability to provide valuable insights and intelligence. By providing easy-to-use, scalable, and powerful software tools, we aim to help our users unlock the full potential of their drone data.</p>
                </div>
            </div>
            <div style={{ margin: "0.5em auto" }} >
                <h2 className={classes.primaryHeading}>What We Offer</h2>
                <div style={{ display: "flex", position: 'relative', justifyContent: "space-between" }}>
                    <div style={{ width: "48%" }}>
                        {aboutUsDatas && (
                            <div className={classes.grid}>
                                {aboutUsDatas.map((data, index) => (
                                    <div key={index} >
                                        <OfferCard key={index} offerData={data} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div style={{ width: "48%" }}>
                        <img style={{ width: "45%", top: 0, right: 0, position: "absolute", height: "100%", borderRadius: "8px", objectFit: "cover" }} src={offerImg} alt='offer_icon' />
                    </div>
                </div>
            </div>
            <div className={classes.footerConatainer} style={{ margin: "0 auto" }}>
                <h2 className={classes.footerPrimaryHeading}>Ready to see the future of data analytics?</h2>
                <p className={classes.footerInfo}>Sign in to your <BrandName /> account or book a demo to experience the future of drone data analytics firsthand. Whether you're a seasoned professional or just starting your journey with drone data, <BrandName /> has the tools and expertise to support your success.</p>
                <div className={classes.footerBtns}>
                    <Button onClick={() => { toggleBookDemoModal(true) }} className={classes.footerBtn} style={{
                        background: "#617AFA", width: "15%", color: "#ffff", border: "none", padding: "10px", borderRadius: "4px"
                    }}>Connect to Expert</Button>

                </div>
                <div style={{ color: "#586A84" }}>
                    <Footer />
                </div>
            </div>
            <BookDemoModal visible={bookDemoModalVisible} toggleModal={toggleBookDemoModal} />
        </div>
    </>
}
const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999
    },
    bgContainer: {
        backgroundImage: `url(${about_bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
        height: 'calc(45vh - 4rem)',
        ...shorthands.borderRadius("8px"),
        color: "#ffff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    bgHeader: {
        fontSize: "2.4rem",
        maxWidth: "100%",
        fontWeight: 600,
    },
    commonConatainer: {
        marginTop: "2em"
    },
    bgSubHeader: {
        fontSize: "1rem",
        maxWidth: "60%",
        fontWeight: 300,
        marginTop: "2rem",
        marginBottom: "2rem"
    },
    bgBtns: {
        color: "#ffff",
        backgroundColor: "#617AFA",
        ':hover': {
            color: "#617AFA",
            backgroundColor: "#EDF0F2 !important"
        },
    },
    primaryHeading: {
        fontSize: "2rem",
        maxWidth: "100%",
        fontWeight: 600,
        marginTop: "2rem",
        marginBottom: "2rem"
    },
    footerPrimaryHeading: {
        fontSize: "2.5rem",
        maxWidth: "100%",
        fontWeight: 600,
        marginTop: "1.5rem",
        marginBottom: "1rem"
    },
    grid: {
        display: "grid",
        width: "100%",
        gridTemplateColumns: "repeat(auto-fill, minmax(22%, 1fr))",
        gridGap: '1.3em',
    },
    fegrid: {
        display: "grid",
        gridTemplateColumns: 'repeat(9, 3fr)',
        marginTop: "1em",
        marginBottom: "2em",
        gridGap: '1em',
    },
    footerConatainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: "90%",
        width: "80%",
        textAlign: "center",
        marginTop: "3em !important",
        paddingBottom: "3em"
    },
    footerInfo: {
        fontSize: "1rem",
        fontWeight: 500,
        marginTop: ".5em",
        marginBottom: ".5em",
        maxWidth: "70%"
    },
    footerBtns: {
        marginTop: "3em",
        width: "100%",
        marginBottom: "3em"
    },
    footerBtn: {
        ...shorthands.borderRadius("0"),
    },
    quesContainer: {
        maxWidth: "60%",
        width: "55%",
    },
    formContainer: {
        maxWidth: "50%",
        width: "42%",
    },
    woksgrid: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "2em",
        width: "100%",
        justifyContent: "space-between"
    },
})
export default AboutUs