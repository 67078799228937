import { makeStyles, shorthands } from '@fluentui/react-components'
import { useEffect, useState } from 'react'

import DashboardIcon from '../../assets/icons/nav_icons/dashboard.svg'
import { DashboardResponse, DashboardService, TimeRange } from '../../services/openapi'
import { Loading } from '../Loading'
import { PageHeader } from '../PageHeader'
import CreditUsageCard from './CreditUsageCard'
import RecentFlightsCard from './RecentFlightsCard'
import SummaryCard from './SummaryCard'

export default function Dashboard() {
    const classes = useStyles()

    const [info, setInfo] = useState<DashboardResponse | null>();
    const [error, setError] = useState('');
    const [currentFilter, setCurrentFilter] = useState<TimeRange>()

    const dashboardResponse = () => {
        DashboardService.dashboard()
            .then((info) => setInfo(info))
            .catch((err) => setError(`${err}`));
    }

    const clearFilter = () => {
        dashboardResponse()
        setCurrentFilter(undefined)
    }

    const filterDashboardResponse = (t: TimeRange) => {
        DashboardService.dashboard(t).then((info) => setInfo(info))
            .catch((err) => setError(`${err}`));
        setCurrentFilter(t)
    }

    useEffect(() => {
        dashboardResponse();
    }, []);

    if (error) {
        return <div>Could not load dashboard: {error}</div>
    }

    if (!info) {
        return <Loading />;
    }

    return <>
        <PageHeader text="Dashboard" icon={DashboardIcon} />

        <div className={classes.container}>
            <SummaryCard info={info} currentFilter={currentFilter} />

            <div className={classes.dashboardCardsContainer}>
                <RecentFlightsCard dashboardInfo={info} topOffset="24rem" filterDashboardResponse={filterDashboardResponse} currentFilter={currentFilter} clearFilter={clearFilter} />

                <CreditUsageCard transactions={info.transactionBuckets} topOffset="24rem" currentFilter={currentFilter} />
            </div>
        </div>
    </>
}

const useStyles = makeStyles({
    container: {
        ...shorthands.padding('1rem'),
    },
    dashboardCardsContainer: {
        ...shorthands.gap("1rem"),
        paddingTop: "1rem",
        display: "flex",
        alignItems: "flex-start",
    },
    recentFlightsContainer: {
        height: "100%",
    }
})