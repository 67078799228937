import * as React from "react";
import { Input, shorthands, makeStyles, Button } from "@fluentui/react-components";
import { Eye16Regular, EyeOff16Regular } from "@fluentui/react-icons";
import google_icon from "../../assets/icons/landing/google_Icon.svg"
import { API_BASE } from "../../constants";
import { Link } from "react-router-dom";
interface LoginForm {
    email: string;
    password: string;
}

interface LoginProps {
    onSubmit: (f: LoginForm) => void;
    onChangeAuthForm: (f: string) => void;
    buttonState: boolean;
}

const useStyles = makeStyles({
    primaryHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
        paddingTop: "1em",
        paddingBottom: "1em",
        textAlign: "center"
    },
    spanColor: {
        color: "#1570EF",
        cursor: "pointer"
    },
    inputContainer: {
        width: '100%',
        ...shorthands.border("none"),
        ...shorthands.borderRadius("8px"),
    },
    orContainer: {
        marginTop: "1em",
        marginBottom: "1em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#617191"
    },
    line: {
        width: "50%",
        ...shorthands.borderTop("1px", "solid", "#617191")
    }
});

export const Login = (props: LoginProps) => {
    const classes = useStyles();
    const [passwordVisible, setPasswordVisible] = React.useState(false);
    const [data, setData] = React.useState({ email: '', password: '' });

    const handleSignInWithGoogle = () => {
        window.location = `${API_BASE}/api/sso/google_login` as any;
    }

    return <div className="signUpContainer">
        <h3 className={classes.primaryHeading}>Welcome!</h3>
        <form
            onSubmit={(e) => { e.preventDefault() }}
            className="loginForm"
            style={{ marginTop: "1em" }}
        >
            <div style={{ paddingBottom: '1.5em' }}>
                <Input
                    className={classes.inputContainer}
                    appearance="outline"
                    size="large"
                    placeholder="Work Email"
                    value={data.email}
                    onChange={(e) => {
                        setData({ ...data, email: e.target.value })
                    }}
                />
            </div>
            <div>
                <Input
                    className={classes.inputContainer}
                    appearance="outline"
                    size="large"
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    contentAfter={passwordVisible ?
                        <EyeOff16Regular onClick={() => setPasswordVisible(false)} />
                        : <Eye16Regular onClick={() => setPasswordVisible(true)} />}
                    value={data.password}
                    onChange={(e) => {
                        setData({ ...data, password: e.target.value })
                    }}
                />
            </div>
            <div style={{ display: "flex", justifyContent: "end", marginTop: ".5em", alignItems: "center" }}>
                <p style={{ color: "#617191", cursor: "pointer" }} onClick={() => {
                    props.onChangeAuthForm("forgotPassword")
                }}>Forgot Password</p>
            </div>
            <p className="eulaHide" style={{ marginTop: "1em", fontWeight: "500", fontSize: "0.9rem" }}>By signing in, you agree to our <Link className={classes.spanColor} to={`/eula`}            >
                End User License Agreement
            </Link> and  <Link className={classes.spanColor} to={`/privacy-policy`}            >
                    Privacy Policy
                </Link>.</p>
            <div style={{ marginTop: "1rem" }}>
                <Button
                    style={{
                        width: '100%', background: "#5E5CE6", color: "white", padding: "10px", borderRadius: "8px"
                    }}
                    onClick={() => {
                        props.onSubmit(data);
                    }}
                    disabled={!data.email || !data.password || props.buttonState}
                    type="submit"
                >
                    Sign In
                </Button>
            </div>
            <div className={classes.orContainer}>
                <p className={classes.line} style={{ margin: "0 5px", }}></p>
                <p>or</p>
                <p className={classes.line} style={{ margin: "0 5px" }}></p>
            </div>
            <div style={{ marginTop: "1rem" }}>
                <Button onClick={handleSignInWithGoogle} icon={<img src={google_icon} alt='Sign In with Google' />} style={{
                    width: '100%', background: "none", color: "#122239", padding: "10px", border: "1px solid #586A84", borderRadius: "8px"
                }}>Sign In with Google</Button>
            </div>
            <p style={{ marginTop: "1em", fontWeight: "600" }}>Don't have an account? <span onClick={() => {
                props.onChangeAuthForm("SignUp")
            }} className={classes.spanColor}>Sign Up</span> </p>
        </form >
    </div >

};
