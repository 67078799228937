import stockpileAnalysiswithoutGCP from "../../../assets/icons/discover-apps/workFlow_icons/stockpileAnalysiswithoutGCP.png";
import stockpileAnalysiswithGCP from "../../../assets/icons/discover-apps/workFlow_icons/stockpileAnalysisWithGCP.png"
import premiumMiningSolution from "../../../assets/icons/discover-apps/workFlow_icons/premiumMiningSolution.png";
import preAcquisitionLandSurvey from "../../../assets/icons/discover-apps/workFlow_icons/preAcquisitionLandSurvey.png";
import preConstructionLandSurvey from "../../../assets/icons/discover-apps/workFlow_icons/preConstructionLandSurvey.png";
import realEstateMonitoring from "../../../assets/icons/discover-apps/workFlow_icons/realEstateMonitoring.png";
import solarProjectMonitoring from "../../../assets/icons/discover-apps/workFlow_icons/solarProjectMonitoring.png";
import roadConstructionMonitoring from "../../../assets/icons/discover-apps/workFlow_icons/roadConstructionMonitoring.png";
import utilitiesVegetationManagement from "../../../assets/icons/discover-apps/workFlow_icons/utilitiesVegetationManagement.png";
import railwaysVegetationManagement from "../../../assets/icons/discover-apps/workFlow_icons/railwaysVegetationManagement.png";
import forestSolutions from "../../../assets/icons/discover-apps/workFlow_icons/forestSolutions.png";
import deepMetrixIcon from "../../../assets/icons/discover-apps/workFlow_icons/deepMatrix.svg";
import satsureIcon from "../../../assets/icons/discover-apps/workFlow_icons/satsure.svg";
import constraIcon from "../../../assets/icons/discover-apps/workFlow_icons/constra.svg";
import esriIcon from "../../../assets/icons/discover-apps/workFlow_icons/esri.svg";
import dataIgnesionIcon from "../../../assets/icons/discover-apps/dataIgnesion.svg"
import dataAnalystIcon from "../../../assets/icons/discover-apps/dataAnalyst.svg"
import shareIcon from "../../../assets/icons/discover-apps/share_archive.svg"
import visualizationIcon from "../../../assets/icons/discover-apps/visualization.svg"
import dataProcessingIcon from "../../../assets/icons/discover-apps/dataProcessing.svg"
import manageProjectIcon from "../../../assets/icons/discover-apps/manageProject.svg"
import scalabilityIcon from "../../../assets/icons/discover-apps/scalability.svg"
import droneDataIcon from "../../../assets/icons/discover-apps/droneData.svg"
import machineLearningIcon from "../../../assets/icons/discover-apps/machineLearning.svg"


export const recommendedWorkflowsData = [
    {
        id: "1",
        icon: stockpileAnalysiswithoutGCP,
        title: 'Stockpile analysis (without GCP) - from DeepMatrix',
        info: "The Stockpile Analysis workflow from DeepMatrix provides a streamlined process for analysing stockpiles without the need for Ground Control Points (GCP). This innovative solution leverages advanced drone technology and sophisticated data processing algorithms to deliver accurate volume measurements and comprehensive stockpile reports. A stockpile report in the mining industry provides a detailed overview of the quantities and qualities of materials stored at a particular site. This information is crucial for effective resource management, production planning, and financial analysis within the mining sector. DeepMatrix FLY is a cloud-based platform designed to process, analyze, and visualize drone data, offering an end-to-end solution for deriving actionable insights from drone operations.",
        createdBy: "DeepMatrix",
        partner: deepMetrixIcon,
        domain: ['Mining'],
        demoLink: [
            { type: "docs", name: "Stockpile Volume Estimation", path: "/assets/discoverApps_docs/StockpileVolumeEstimation.pdf" },
            { type: "docs", name: "Flyght Cloud DeepMatrix Brochure", path: "/assets/discoverApps_docs/flyghtCloud_DeepMatrix_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud DeepMatrix Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_DeepMatrix_Solution_Dec.pdf" }
        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity for reliable analysis"
            }, {
                title: "Process with DeepMatrix FLY",
                info: "Process your data with FLY for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Geo-tagged raw images (PPK)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            }, {
                title: "Stockpile boundary file",
                info: "File that defines the boundaries and dimensions of a stockpile"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            }, {
                title: "3D Point Cloud",
                info: "Collection of data points representing a 3D space, provided in .laz format"
            },
            {
                title: "Stockpile report (for management)",
                info: "Provides detailed information about a stockpile, including its volume, weight, material composition, and spatial dimensions"
            },
        ],
        tagNames: ["DeepMatrix", "Stockpile", "Without GCPs", "Mining"],
        howItWorks: [
            {
                title: "Data ingestion",
                info: "Upload any geo-tagged data",
                icon: dataIgnesionIcon
            },
            {
                title: "Data Analysis",
                info: "Derive insights from data",
                icon: dataAnalystIcon
            },
            {
                title: "Share / Archive",
                info: "Collaborate & socialize with context",
                icon: shareIcon
            },
            {
                title: "Visualisation",
                info: "Visualize your asset through FLY",
                icon: visualizationIcon
            },
        ],
    },
    {
        id: "2",
        icon: stockpileAnalysiswithGCP,
        title: "Stockpile analysis (with GCP) - from DeepMatrix",
        info: "The Stockpile Analysis workflow from DeepMatrix provides a comprehensive solution for analyzing stockpiles with the inclusion of Ground Control Points (GCP). By integrating GCPs, this workflow enhances the accuracy of volume measurements and stockpile reports, ensuring high precision in data outputs. This solution utilizes advanced drone technology combined with sophisticated data processing algorithms to deliver detailed and reliable stockpile reports. In the mining industry, such reports offer an in-depth overview of the quantities and qualities of materials stored at specific sites, which is essential for effective resource management, production planning, and financial analysis. DeepMatrix FLY, a cloud-based platform, is designed to process, analyze, and visualize drone data, providing an end-to-end solution for deriving actionable insights from drone operations.",
        createdBy: "DeepMatrix",
        partner: deepMetrixIcon,
        domain: ["Mining"],
        demoLink: [
            { name: "Stockpile Volume Estimation", type: "docs", path: "/assets/discoverApps_docs/StockpileVolumeEstimation.pdf" },
            { type: "docs", name: "Flyght Cloud DeepMatrix Brochure", path: "/assets/discoverApps_docs/flyghtCloud_DeepMatrix_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud DeepMatrix Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_DeepMatrix_Solution_Dec.pdf" }

        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity for reliable analysis"
            }, {
                title: "Process with DeepMatrix FLY",
                info: "Process your data with FLY for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Geo-tagged raw images (PPK)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            }, {
                title: "Stockpile boundary file",
                info: "File that defines the boundaries and dimensions of a stockpile"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            }, {
                title: "3D Point Cloud",
                info: "Collection of data points representing a 3D space, provided in .laz format"
            },
            {
                title: "Stockpile report (for management)",
                info: "Provides detailed information about a stockpile, including its volume, weight, material composition, and spatial dimensions"
            },
        ],
        howItWorks: [
            {
                title: "Data ingestion",
                info: "Upload any geo-tagged data",
                icon: dataIgnesionIcon
            },
            {
                title: "Data Analysis",
                info: "Derive insights from data",
                icon: dataAnalystIcon
            },
            {
                title: "Share / Archive",
                info: "Collaborate & socialize with context",
                icon: shareIcon
            },
            {
                title: "Visualisation",
                info: "Visualize your asset through FLY",
                icon: visualizationIcon
            },
        ],
        tagNames: ["DeepMatrix", "Stockpile", "With GCPs", "Mining"]
    },
    {
        id: "3",
        icon: premiumMiningSolution,
        title: "Premium mining solution - from DeepMatrix",
        info: "The Premium Mining Solution from DeepMatrix is tailored to meet the diverse needs of the mining industry, offering applications such as stockpile management, haul road analysis, tailing dam monitoring, bench heights estimation, and more. DeepMatrix FLY is a cloud-based platform designed to process, analyze, and visualize drone data, providing an end-to-end solution for deriving actionable insights from drone operations.",
        createdBy: "DeepMatrix",
        partner: deepMetrixIcon,
        domain: ["Mining"],
        demoLink: [
            { name: "Topo Map", type: "docs", path: "/assets/discoverApps_docs/Topo_Map.pdf" },
            { name: "Stockpile Spotlevel Map", type: "docs", path: "/assets/discoverApps_docs/Stockpile_Spotlevel_Map.pdf" },
            { type: "docs", name: "Stockpile Volume Estimation", path: "/assets/discoverApps_docs/StockpileVolumeEstimation.pdf" },
            { type: "docs", name: "Flyght Cloud DeepMatrix Brochure", path: "/assets/discoverApps_docs/flyghtCloud_DeepMatrix_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud DeepMatrix Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_DeepMatrix_Solution_Dec.pdf" }
        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity for reliable analysis"
            }, {
                title: "Process with DeepMatrix FLY",
                info: "Process your data with FLY for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Geo-tagged raw images (PPK)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            }, {
                title: "Stockpile boundary file",
                info: "File that defines the boundaries and dimensions of a stockpile"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            }, {
                title: "3D Point Cloud",
                info: "Collection of data points representing a 3D space, provided in .laz format"
            },
            {
                title: "Report",
                info: "Including:- Stockpile report, Bench height, Haul road, Contour map"
            },
        ],
        howItWorks: [
            {
                title: "Data ingestion",
                info: "Upload any geo-tagged data",
                icon: dataIgnesionIcon
            },
            {
                title: "Data Analysis",
                info: "Derive insights from data",
                icon: dataAnalystIcon
            },
            {
                title: "Share / Archive",
                info: "Collaborate & socialize with context",
                icon: shareIcon
            },
            {
                title: "Visualisation",
                info: "Visualize your asset through FLY",
                icon: visualizationIcon
            },
        ],
        tagNames: ["DeepMatrix", "Stockpile", "Bench heights", "Contour maps", "Haul road analysis", "sMining"]
    },
    {
        id: "4",
        icon: preAcquisitionLandSurvey,
        title: "Pre-Acquisition land survey - from HUVIAiR (Coming soon)",
        info: "The 'Pre-acquisition Land Survey' workflow from HUVIAiR is designed to revolutionize the initial phase of construction management by leveraging drone technology. This workflow provides real-time data for site surveys, progress monitoring, and inspections, significantly enhancing the efficiency and accuracy of construction projects.",
        info1: "The Constra Scouter module within this workflow is particularly beneficial during the Land Acquisition phase. It enables users to analyze terrain and environmental factors using both satellite and drone imagery, helping to determine the optimal location for a construction project. This comprehensive analysis ensures that all critical factors are considered before finalizing a site, leading to better planning and execution.",
        info2: "Stay tuned for this innovative workflow, which promises to transform how construction projects are managed right from the start.",
        createdBy: "HUVIAiR",
        partner: constraIcon,
        domain: ["Construction", "Real Estate"],
        demoLink: [
            { name: "Constra Scouter - Deliverables", type: "docs", path: "/assets/discoverApps_docs/ConstraScouter-Deliverables.pdf" },
            { type: "docs", name: "Flyght Cloud HUVIAiR Brochure", path: "/assets/discoverApps_docs/flyghtCloud_HUVIAiR_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud CONSTRA by HUVIAiR Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_CONSTRA_by_HUVIAiR_Solution_Deck.pdf" }
        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images ",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity"
            }, {
                title: "Process with Constra Scouter",
                info: "Process your data with Constra Scouter for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Orthomosaic (.geotiff format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            }, {
                title: "3D point cloud (.laz format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            },
            {
                title: "CAD drawings (if any)",
                info: "Elevation drawings (dwg file), Terrace and above terrace floor plans (dwg file), Architectural drawings & floor plans (dwg file), "
            }, {
                title: "Checklist of features to be digitised in .dwg/.pdf",
                info: "A detailed list of specific site features (such as buildings, roads, utilities, vegetation, etc.) that need to be captured and converted into digital formats"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy < 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO"
            }
        ],
        output: [
            {
                title: "CAD Overlays",
                info: "Provide detailed design layers for precise alignment and analysis"
            },
            {
                title: "Topographic drawing",
                info: "Detailed representation of a land area, showing its natural and man-made features along with elevation contours"
            }, {
                title: "Object count report",
                info: "Provides a detailed tally of specific objects or features within a surveyed area"
            }
        ],
        howItWorks: [
            {
                title: "Data Processing",
                info: "Process visual inputs from drones and 360º cameras",
                icon: dataProcessingIcon
            },
            {
                title: "Manage Projects",
                info: "Manage multiple sites, accurately measure progress, manage snags and more",
                icon: manageProjectIcon
            },
            {
                title: "Scalability",
                info: "Launch quickly, and scale with ease",
                icon: scalabilityIcon
            },
        ],
        tagNames: ["HUVIAiR", "Scouting", "Pre - Acquisition", "Construction", "Real Estate"]
    },
    {
        id: "5",
        icon: preConstructionLandSurvey,
        title: "Pre-Construction land survey - from HUVIAiR",
        info: "Pre - Construction Land Survey from HUVIAiR enhances construction management by utilizing UAVs for comprehensive site assessments.This workflow, using the Constra Survey, provides real- time data for site surveys, progress monitoring, and inspections.It offers a clear understanding of the site by evaluating water flow, contours, and existing structures, enabling better design decisions.",
        info1: "It processes visual inputs from drones and 360º cameras, manages multiple sites, accurately measures progress, and handles snag management with ease. The solution ensures rapid deployment and scalability, making it ideal for project exterior monitoring, accurate progress tracking, and inspecting remote or inaccessible areas.",
        createdBy: "HUVIAiR",
        partner: constraIcon,
        domain: ["Construction", "Real Estate"],
        demoLink: [
            { name: "Constra Survey - Delivarables", type: "docs", path: "/assets/discoverApps_docs/ConstraSurvey-Delivarables.pdf" },
            { type: "docs", name: "Flyght Cloud HUVIAiR Brochure", path: "/assets/discoverApps_docs/flyghtCloud_HUVIAiR_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud CONSTRA by HUVIAiR Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_CONSTRA_by_HUVIAiR_Solution_Deck.pdf" }
        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity"
            }, {
                title: "Process with Constra Survey",
                info: "Process your data with Constra Survey for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Orthomosaic (.geotiff format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            }, {
                title: "Digital Surface Model (.geotiff format)",
                info: "A representation of a terrain's surface, provided in .geotiff format "
            },
            {
                title: "3D point cloud (.laz format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            }, {
                title: "CAD drawings (if any)",
                info: "Elevation drawings (dwg file), Terrace and above terrace floor plans (dwg file), Architectural drawings & floor plans (dwg file), "
            },
            {
                title: "Checklist of features to be digitised in .dwg/.pdf",
                info: "A detailed list of specific site features (such as buildings, roads, utilities, vegetation, etc.) that need to be captured and converted into digital formats"
            }, {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy < 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO"
            }
        ],
        output: [
            {
                title: "Digital Terrain Model",
                info: "Represents the bare ground surface, stripped of vegetation and man-made structures, used for topographic analysis and land surveying"
            },
            {
                title: "Contours",
                info: "Lines on map that connect points of equal elevation, used to illustrate the shape and elevation of the terrain"
            }, {
                title: "CAD Overlays",
                info: "Provide detailed design layers for precise alignment and analysis"
            }, {
                title: "Topographic drawing",
                info: "Detailed representation of a land area, showing its natural and man-made features along with elevation contours"
            }, {
                title: "Object count report",
                info: "Provides a detailed tally of specific objects or features within a surveyed area"
            },
        ],
        howItWorks: [
            {
                title: "Data Processing",
                info: "Process visual inputs from drones and 360º cameras",
                icon: dataProcessingIcon
            },
            {
                title: "Manage Projects",
                info: "Manage multiple sites, accurately measure progress, manage snags and more",
                icon: manageProjectIcon
            },
            {
                title: "Scalability",
                info: "Launch quickly, and scale with ease",
                icon: scalabilityIcon
            },
        ],
        tagNames: ["HUVIAiR", "Surveying", "Pre - Construction", "Construction", "Real Estate"]
    }, {
        id: "6",
        icon: realEstateMonitoring,
        title: "Real estate monitoring - from HUVIAiR",
        info: "This workflow from HUVIAiR leverages advanced UAV technology to revolutionise construction management. You will get real-time data for site surveys, progress monitoring, and inspections, ensuring efficient project management. Utilising the Constra Build platform during the construction phase, users can remotely monitor site progress, swiftly identify and resolve issues, and mitigate delays and cost overruns due to rework. ",
        info1: "Key features include project exterior monitoring, accurate tracking of site progress, and inspection of remote and inaccessible areas. This comprehensive solution enhances visibility and control over construction projects, leading to improved outcomes and streamlined operations.",
        createdBy: "HUVIAiR",
        partner: constraIcon,
        domain: ["Construction", "Real Estate"],
        demoLink: [
            { name: "CONSTRA Build-Deliverables", type: "docs", path: "/assets/discoverApps_docs/CONSTRABuild-Deliverables.pdf" },
            { type: "docs", name: "Flyght Cloud HUVIAiR Brochure", path: "/assets/discoverApps_docs/flyghtCloud_HUVIAiR_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud CONSTRA by HUVIAiR Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_CONSTRA_by_HUVIAiR_Solution_Deck.pdf" }

        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity"
            }, {
                title: "Process with Constra Build",
                info: "Process your data with Constra Build for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Orthomosaic (.geotiff format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            }, {
                title: "Digital Surface Model (.geotiff format)",
                info: "A representation of a terrain's surface, provided in .geotiff format"
            },
            {
                title: "3D point cloud (.laz format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            }, {
                title: "CAD drawings (if any)",
                info: "Elevation drawings (dwg file), Terrace and above terrace floor plans (dwg file), Architectural drawings & floor plans (dwg file), "
            },
            {
                title: "Project schedule in excel or MPP format",
                info: "Outlines timelines and milestones, available in Excel or MPP format."
            }, {
                title: "BIM model (if available)",
                info: "Files in .rvt or .nwd format"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO"
            },
        ],
        output: [
            {
                title: "Project structure",
                info: "Towers, floors, apartments, etc."
            },
            {
                title: "CAD Overlays",
                info: "Provide detailed design layers for precise alignment and analysis"
            }, {
                title: "Snag Report",
                info: "Highlights project issues and needed corrections for efficient resolution"
            }, {
                title: "Progress Report",
                info: "Detailed project progress report"
            }
        ],
        howItWorks: [
            {
                title: "Data Processing",
                info: "Process visual inputs from drones and 360º cameras",
                icon: dataProcessingIcon
            },
            {
                title: "Manage Projects",
                info: "Manage multiple sites, accurately measure progress, manage snags and more",
                icon: manageProjectIcon
            },
            {
                title: "Scalability",
                info: "Launch quickly, and scale with ease",
                icon: scalabilityIcon
            },
        ],
        tagNames: ["HUVIAiR", "Construction progress monitoring", "Construction phase", "Real Estate"]
    },
    {
        id: "7",
        icon: roadConstructionMonitoring,
        title: "Road construction monitoring - from HUVIAiR",
        info: "Road construction monitoring from HUVIAiR leverages drone technology to optimize every phase of road construction projects. Using UAVs, it provides real-time data for site surveys, progress monitoring, and inspections.",
        info1: "Constra Build facilitates remote progress monitoring during the construction phase, enabling timely issue identification and resolution to minimize delays and cost overruns. The solution processes visual data from drones and 360º cameras, manages multiple sites efficiently, measures progress accurately, and handles snag management seamlessly. It ensures rapid deployment and scalability, enhancing overall project efficiency.",
        createdBy: "HUVIAiR",
        partner: constraIcon,
        domain: ["Construction", "Real Estate"],
        demoLink: [
            { name: "CONSTRA Build-Deliverables", type: "docs", path: "/assets/discoverApps_docs/CONSTRABuild-Deliverables.pdf" },
            { type: "docs", name: "Flyght Cloud HUVIAiR Brochure", path: "/assets/discoverApps_docs/flyghtCloud_HUVIAiR_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud CONSTRA by HUVIAiR Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_CONSTRA_by_HUVIAiR_Solution_Deck.pdf" }

        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity"
            }, {
                title: "Process with Constra Build",
                info: "Process your data with Constra Build for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Orthomosaic (.geotiff format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            }, {
                title: "Digital Surface Model (.geotiff format)",
                info: "A representation of a terrain's surface, provided in .geotiff format"
            }, {
                title: "3D point cloud (.laz format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            },
            {
                title: "CAD drawings (if any)",
                info: "Elevation drawings (dwg file), Terrace and above terrace floor plans (dwg file), Architectural drawings & floor plans (dwg file), "
            }, {
                title: "Project schedule in excel or MPP format",
                info: "Outlines timelines and milestones, available in Excel or MPP format."
            }, {
                title: "BIM model (if available)",
                info: "Files in .rvt or .nwd format"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "Project structure",
                info: "Towers, floors, apartments, etc."
            },
            {
                title: "CAD Overlays",
                info: "Provide detailed design layers for precise alignment and analysis"
            }, {
                title: "Snag Report",
                info: "Highlights project issues and needed corrections for efficient resolution"
            }, {
                title: "Progress Report",
                info: "Detailed project progress report"
            }
        ],
        howItWorks: [
            {
                title: "Data Processing",
                info: "Process visual inputs from drones and 360º cameras",
                icon: dataProcessingIcon
            },
            {
                title: "Manage Projects",
                info: "Manage multiple sites, accurately measure progress, manage snags and more",
                icon: manageProjectIcon
            },
            {
                title: "Scalability",
                info: "Launch quickly, and scale with ease",
                icon: scalabilityIcon
            },
        ],
        tagNames: ["HUVIAiR", "Construction progress monitoring", "Construction phase", "Road construction"]
    }, {
        id: "8",
        icon: solarProjectMonitoring,
        title: "Solar Project monitoring - from HUVIAiR",
        info: "The Solar Project Monitoring workflow from HUVIAiR uses Constra Build to process visual inputs from drones and 360º cameras, allowing you to manage multiple sites, accurately measure progress, and manage snags efficiently. This solution facilitates quick launches and easy scaling. Key features include project exterior monitoring, accurate site progress tracking, and inspection of remote and inaccessible areas.",
        info1: "Large-scale solar panel management now become easy with this workflow. This enhances various aspects of solar projects by providing real-time data for site surveys, progress monitoring, and inspections. ",
        createdBy: "HUVIAiR",
        partner: constraIcon,
        domain: ["Construction", "Real Estate"],
        demoLink: [
            { name: "CONSTRA Build-Deliverables", type: "docs", path: "/assets/discoverApps_docs/CONSTRABuild-Deliverables.pdf" },
            { type: "docs", name: "Flyght Cloud HUVIAiR Brochure", path: "/assets/discoverApps_docs/flyghtCloud_HUVIAiR_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud CONSTRA by HUVIAiR Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_CONSTRA_by_HUVIAiR_Solution_Deck.pdf" }

        ],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity"
            }, {
                title: "Process with Constra Build",
                info: "Process your data with Constra Build for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        input: [
            {
                title: "Orthomosaic (.geotiff format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            }, {
                title: "Digital Surface Model (.geotiff format)",
                info: "A representation of a terrain's surface, provided in .geotiff format"
            },
            {
                title: "3D point cloud (.laz format)",
                info: "A 3D point cloud is a collecti   on of data points representing a 3D space, provided in .laz format"
            }, {
                title: "CAD drawings (if any)",
                info: "Elevation drawings (dwg file), Terrace and above terrace floor plans (dwg file), Architectural drawings & floor plans (dwg file), "
            }, {
                title: "Project schedule in excel or MPP format",
                info: "Outlines timelines and milestones, available in Excel or MPP format."
            }, {
                title: "BIM model (if available)",
                info: "Files in .rvt or .nwd format"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "Project structure",
                info: "Towers, floors, apartments, etc."
            },
            {
                title: "CAD Overlays",
                info: "Provide detailed design layers for precise alignment and analysis"
            }, {
                title: "Snag Report",
                info: "Highlights project issues and needed corrections for efficient resolution"
            }, {
                title: "Progress Report",
                info: "Detailed project progress report"
            }
        ],
        howItWorks: [
            {
                title: "Data Processing",
                info: "Process visual inputs from drones and 360º cameras",
                icon: dataProcessingIcon
            },
            {
                title: "Manage Projects",
                info: "Manage multiple sites, accurately measure progress, manage snags and more",
                icon: manageProjectIcon
            },
            {
                title: "Scalability",
                info: "Launch quickly, and scale with ease",
                icon: scalabilityIcon
            },
        ],
        tagNames: ["HUVIAiR", "Construction progress monitoring", "Construction phase", "Solar project"]
    },
    {
        id: "9",
        icon: utilitiesVegetationManagement,
        title: "Utilities Vegetation Management - from SatSure",
        info: "Utilities Vegetation Management from SatSure focuses on monitoring and analyzing vegetation near power lines. This workflow facilitates proactive identification of potential risks, assisting utility companies in optimizing maintenance and preventing outages caused by vegetation encroachment.",
        info1: "SatSure  Skies offers high-resolution satellite image change detection products designed for infrastructure, energy, and utility sectors. Utilizing high-resolution drone imagery, machine learning, and cloud computing, SatSure Skies delivers actionable insights to transmission companies. It provides remote information on areas of interest, existing constraints, and potential risks associated with natural calamities.",
        createdBy: "SatSure",
        partner: satsureIcon,
        domain: ["Power", "Utilities"],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity"
            }, {
                title: "Process with SatSure Skies",
                info: "Process your data with SatSure Skies for efficient analysis and real-time project monitoring."
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        demoLink: [
            { type: "docs", name: "Flyght Cloud SatSure Brochure", path: "/assets/discoverApps_docs/flyghtCloud_SatSure_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud SatSure Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_SatSure_Solution_Deck.pdf" }
        ],
        input: [
            {
                title: "GeoTagged images",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "2D and 3D data sets",
                info: "(Orthomosaic, DEM, Point Cloud, etc.)"
            },
            {
                title: "Reports",
                info: "Detailed report including risk analysis"
            }
        ],
        howItWorks: [
            {
                title: "Drone Data",
                info: "Process visual inputs from drones and 360º cameras",
                icon: droneDataIcon
            },
            {
                title: "Machine Learning",
                info: "Generate actionable insights with machine learning an cloud computing.",
                icon: machineLearningIcon
            },
        ],
        tagNames: ["SatSure", "Vegetation Management", "Powerlines", "Utilities"]
    },
    {
        id: "10",
        icon: railwaysVegetationManagement,
        title: "Railways Vegetation Management - from SatSure",
        info: "Railways Vegetation Management from SatSure focuses on monitoring and analyzing vegetation in proximity to railway tracks. This workflow enables proactive identification of potential risks, optimizing maintenance and preventing outages caused by vegetation encroachment. ",
        info1: "SatSure Skies offers high-resolution satellite image change detection products tailored for infrastructure, energy, and utility sectors. Leveraging high-resolution drone imagery, machine learning, and cloud computing, SatSure Skies delivers actionable insights to railway officials, providing remote information on areas of interest, existing constraints, and potential risks associated with natural calamities.",
        createdBy: "SatSure",
        partner: satsureIcon,
        domain: ["Power", "Utilities"],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity"
            }, {
                title: "Process with SatSure Skies",
                info: "Process your data with SatSure Skies for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Report",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        demoLink: [
            { type: "docs", name: "Flyght Cloud SatSure Brochure", path: "/assets/discoverApps_docs/flyghtCloud_SatSure_Brochure.pdf" },
            { type: "docs", name: "Flyght Cloud SatSure Solution Deck", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_SatSure_Solution_Deck.pdf" }
        ],
        input: [
            {
                title: "GeoTagged images",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "2D and 3D data sets",
                info: "(Orthomosaic, DEM, Point Cloud, etc.)"
            },
            {
                title: "Reports",
                info: "Detailed report including risk analysis"
            }
        ],
        howItWorks: [
            {
                title: "Drone Data",
                info: "Process visual inputs from drones and 360º cameras",
                icon: droneDataIcon
            },
            {
                title: "Machine Learning",
                info: "Generate actionable insights with machine learning an cloud computing.",
                icon: machineLearningIcon
            },
        ],
        tagNames: ["SatSure", "Vegetation Management", "Railways", "Infrastructure"]
    },
    {
        id: "11",
        icon: forestSolutions,
        title: "Forest Solutions - from ESRI India (Coming soon)",
        info: "Forest Solutions from ESRI India harnesses drone technology for comprehensive forest management applications. From monitoring and assessing forest health to detecting pests and diseases, this solution empowers forestry professionals with valuable data for informed decision-making.",
        info1: "Site Scan for ArcGIS, part of the ArcGIS Reality suite, offers end-to-end cloud-based drone mapping software that revolutionizes data collection, processing, and analysis. It includes advanced drone analytics, seamless data sharing capabilities, enterprise fleet management, and robust tools for in-depth spatial analysis.",
        createdBy: "ESRI India",
        partner: esriIcon,
        domain: ["Forest"],
        workFlowStages: [
            {
                title: "Upload GeoTagged images",
                info: "Upload GeoTagged images to ensure precise map creation and accurate spatial data analysis"
            },
            {
                title: "Store Data Securely",
                info: "Store data securely to protect sensitive information and maintain data integrity "
            }, {
                title: "Process with ESRI India SiteScan",
                info: "Process your data with SiteScan for efficient analysis and real-time project monitoring"
            },
            {
                title: "Process with ArcGIS",
                info: "Process your data with ArcGIS for efficient analysis and real-time project monitoring"
            }, {
                title: "Generate Comprehensive Dashboard & Reports",
                info: "Get detailed insights and effective project management at your finger tip"
            },
        ],
        demoLink: [
            { name: "Smart Forest Solution", type: "video", path: 'https://www.youtube.com/watch?v=KuAbX3s26U4' },
            { name: "Himachal Pradesh Forest Officer Narrates His Experience", type: "video", path: 'https://www.youtube.com/watch?v=aDOsBTzCaug' },
            { name: "Case Study - Himachal Pradesh Forest (Feature Extraction)", type: "docs", path: "/assets/discoverApps_docs/CaseStudy-HimachalPradeshForest(Feature_Extraction).pdf" },
            { name: "Case Study - Forest FireDetection_Animal Tracking", type: "docs", path: "/assets/discoverApps_docs/caseStudy_forestFireDetection_animalTracking.pdf" },
            { name: "Case Study ForestMapping", type: "docs", path: "/assets/discoverApps_docs/caseStudy_forestMapping.pdf" },
            { name: "Flyght Cloud Esri Solution Deck", type: "docs", path: "/assets/discoverApps_docs/FLYGHTCLOUD_&_Esri_Solution_Deck.pdf" },
            { name: "Smart Forest Solutions Brochure", type: "docs", path: "/assets/discoverApps_docs/smartForestSolutions_Brochure.pdf" },
        ],
        input: [
            {
                title: "GeoTagged images",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
            {
                title: "RGB images",
                info: "With GSD < 5 cms/ pixel @120 mts AGL, Accuracy < 10 cms in x, y and Accuracy< 20 cms in z"
            }, {
                title: "Flight plan with simple grid",
                info: "70 % side overlap for Q6, 60 % side overlap for RYNO)"
            }
        ],
        output: [
            {
                title: "2D and 3D data sets",
                info: "(Orthomosaic, DEM, Point Cloud, etc.)"
            },
            {
                title: "Tree count and height",
                info: "Detailed map including tree count and height"
            }, {
                title: "Topographic or vector map",
                info: "Detailed maps that show the terrain features, contours, and vector data relevant to forest management and planning"
            }, {
                title: "Building/encroachment detection",
                info: "Identifying and mapping structures or unauthorised developments within forested areas"
            }, {
                title: "Change detection",
                info: "Identifies and quantifies changes in forested areas using satellite or drone imagery over time"
            },
        ],
        howItWorks: [
            {
                title: "Drone Data",
                info: "Process visual inputs from drones and 360º cameras",
                icon: droneDataIcon
            },
            {
                title: "Machine Learning",
                info: "Generate actionable insights with machine learning an cloud computing.",
                icon: machineLearningIcon
            },
        ],
        tagNames: ["ESRI India", "Forest solutions", "Forestry"]
    }
]
