import { makeStyles, shorthands } from "@fluentui/react-components";

import campaignImage from "../../assets/campaign.png";
import { DashboardResponse, TimeRange } from "../../services/openapi";

interface Props {
    info: DashboardResponse;
    currentFilter?: TimeRange
}

const label = {
    [TimeRange.PAST_WEEK]: "week",
    [TimeRange.PAST_MONTH]: "month",
}

export default function SummaryCard({ info, currentFilter = TimeRange.PAST_WEEK }: Props) {
    const classes = useStyles()

    const cardsData = [
        {
            id: 1,
            name: "Flights Completed",
            count: info.flightsCompleted,
        },
        {
            id: 2,
            name: "Reports Generated",
            count: info.reportsGenerated,
        },
        {
            id: 3,
            name: "Credits Used",
            count: parseFloat(info.creditsUsed.toFixed(2)),
        }
    ]

    return <div className={classes.container}>
        <div className={classes.greetingContainer}>
            <p className={classes.greetinHeading}>Hello {info.name}</p>
            <p className={classes.greetinSubHeading}>Here is your {label[currentFilter]} summary</p>

            <div>
                <div className={classes.infoContainer}>
                    {cardsData.map(item => <div key={item.id} className={classes.cardContainer}>
                        <p className={classes.cardItemCount}>{item.count}</p>
                        <p className={classes.cardItemName}>{item.name}</p>
                    </div>)}
                </div>

            </div>
        </div>

        <div className={classes.greetingImage}>
            <img src={campaignImage} alt="campaign" />
        </div>
    </div>
}

const useStyles = makeStyles({
    container: {
        height: "14rem",
        minHeight: "14rem",
        backgroundImage: "linear-gradient(90deg, #E5D5F9 0%, #B9D7F9 100%)",
        userSelect: "none",
        display: "flex",
        ...shorthands.gap("2rem"),
        ...shorthands.borderRadius("1rem"),
        ...shorthands.padding("2rem"),
    },
    greetingContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        flexShrink: 0,
    },
    greetinHeading: {
        cursor: "default",
        fontSize: "1.75rem",
        fontWeight: "600",
        color: "#043957",
        lineHeight: "2rem",
    },
    greetinSubHeading: {
        cursor: "default",
        fontSize: "18px",
        color: "#2C2C2C",
        lineHeight: "1.5rem",
    },
    infoContainer: {
        marginTop: "0.75rem",
        display: "flex",
        ...shorthands.gap("2rem"),
    },
    cardContainer: {
        ...shorthands.padding("20px", "32px"),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.50)",
        boxShadow: "-1px 1px 20px 0px rgba(0, 0, 0, 0.10)",
        ...shorthands.borderRadius("14px"),
        ...shorthands.transition("transform", " 0.25s"),
        minWidth: "13rem",
        ":hover": {
            transform: "translateY(-0.5rem)",
        }
    },
    cardItemCount: {
        fontSize: "18px",
        color: "#2C2C2C",
        lineHeight: "1.5rem",
        fontWeight: "600",
        cursor: "default",
    },
    cardItemName: {
        cursor: "default",
        fontSize: "18px",
        color: "#2C2C2C",
        lineHeight: "1.5rem",
    },
    greetingImage: {
        ...shorthands.margin("-2rem"),
        transform: "translateX(-2rem)",
        flexShrink: 1,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
    }
})