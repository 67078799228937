import { Button, Field, Input, Textarea, Toast, ToastTitle, makeStyles, useId, useToastController } from '@fluentui/react-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../AuthContext';
import flyghtcloud_logo from "../../../../assets/icons/landing/logo_green.svg";
import ToastErrorIcon from "../../../../assets/icons/toast_error.svg";
import useUTMParams from '../../../../hooks/useUTMParams';
import { AuthService, LeadRequest, ZohoService } from '../../../../services/openapi';
import { isValidEmail } from '../../../../utils/validators';
import { FASQsData } from '../../common/FaqsData';
import Footer from '../../common/Footer';
import HeaderNavBar from '../../common/HeaderNavBar';
import FaqCategoryList from './FaqCategoryList';

interface Faq {
    question: string;
    answer: string;
}
interface FaqCategory {
    id: number;
    type: string;
    faqs: Faq[];
}

function FAQs() {
    const classes = useStyles();
    const toasterId = useId("toaster");
    const navigate = useNavigate();
    const timeout = 2000;
    const { dispatchToast } = useToastController(toasterId)
    const loggedMe = React.useContext(AuthContext);
    const [faqsDatas, setFaqsDatasProps] = React.useState<FaqCategory[] | null>(null);
    const [data, setData] = React.useState({ question: '', email: '', name: '' });
    const [isformSubmitted, setisFormSubmitted] = React.useState<boolean>(false);
    const [isValidMail, setIsValidMail] = React.useState(false);
    const [isLoginDisabled, setIsLoginDisabled] = React.useState(false);
    const utmParams = useUTMParams();

    React.useEffect(() => {
        setFaqsDatasProps(FASQsData);
    }, []);

    const createLead = (formValues: any) => {
        const data: LeadRequest = {
            data: [
                {
                    "Lead_Source": "FAQ",
                    "Last_Name": formValues.name,
                    "Email": formValues.email,
                    Description: formValues.question,
                    "Form_Page_Url": "https://flyghtcloud.ideaforgetech.com/faqs",
                    "Form_Name": "FAQ - Flyght Cloud",
                    ...utmParams,
                }
            ]
        }

        ZohoService.addLeadToZohoCrm(data).then(() => {
            console.log("Lead created successfully")
        }).catch(err => console.error("Error creating lead", err))
    }

    const onSubmit = (formValues: any) => {
        const hasValidEmail = isValidEmail(data.email)
        setIsValidMail(!hasValidEmail);
        if (hasValidEmail) {
            setisFormSubmitted(true);
            setData({ ...data, question: "", email: "", name: "" });
            createLead(formValues)
        }
    }

    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastErrorIcon} alt="error icon" style={{ marginRight: "0.25rem" }} />}>{err}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const login = (d: any) => {
        setIsLoginDisabled(true)
        AuthService.login(d)
            .then((res) => {
                loggedMe.setMe(res)
                setIsLoginDisabled(false)
                if (res.token) {
                    navigate(`/dashboard`);
                }
            })
            .catch((err) => {
                console.table(err)
                notifyError("Either email or password is invalid")
                setTimeout(() => {
                    setIsLoginDisabled(false)
                }, timeout);
            })
    }



    return <>
        <div className={classes.headerNavBar}>
            <HeaderNavBar login={login} toasterId={toasterId} icon={flyghtcloud_logo} color='black' signInBorder={true} btnBg="#E5E8EB" btnStatus={isLoginDisabled} />

        </div>
        <div style={{ height: "calc(99vh - 3rem)", background: "white", overflow: "auto" }}>
            <h2 className={classes.primaryHeading} style={{ background: "white", height: "8vh", zIndex: 9999, position: "sticky", top: "0" }} >Frequently Asked Questions</h2>
            <div className={classes.faqContainer} style={{ margin: "0 auto", position: "relative" }} >
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <div className={classes.quesContainer}>
                        {faqsDatas && (
                            <div >
                                {faqsDatas.map((data, index) => (
                                    <div key={index} >
                                        <FaqCategoryList key={index} faqsData={data} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className={classes.formContainer} style={{
                        width: "30%",
                        position: "fixed",
                        left: "60%",
                        transform: "(translate(-50%, -50%) !important",

                    }}>
                        <h3 className={classes.cardHeading}>Can't find what you're looking for?</h3>
                        <p className={classes.info}>Don't worry, you can submit your question and we'll get back to  you with an answer.</p>
                        <form
                            style={{
                                flexGrow: 1,
                                margin: 'auto',
                            }}
                            onSubmit={(e) => { e.preventDefault() }}
                        >
                            <div>
                                <div style={{ paddingBottom: '1em' }}>
                                    <h3 className={classes.quesText}>Your question *</h3>
                                    <Textarea
                                        style={{
                                            width: '100%',
                                            border: "1px solid #D1DBE8"
                                        }}
                                        appearance="outline"
                                        size="large"
                                        placeholder="Ask your question here..."
                                        value={data.question}
                                        onChange={(e) => {
                                            setisFormSubmitted(false)
                                            setData({ ...data, question: e.target.value })
                                        }}
                                    />
                                </div>

                                <div style={{ paddingBottom: '1em' }}>
                                    <h3 className={classes.mailText}>Your name *</h3>
                                    <Input
                                        style={{
                                            width: '100%',
                                            border: "1px solid #D1DBE8"
                                        }}
                                        appearance="outline"
                                        size="large"
                                        placeholder="Type your name here"
                                        value={data.name}
                                        onChange={(e) => {
                                            setisFormSubmitted(false)
                                            setData({ ...data, name: e.target.value })
                                        }}
                                    />
                                </div>

                                <div>
                                    <h3 className={classes.mailText}>Your email address *</h3>
                                    <Field validationMessageIcon={null} validationMessage={isValidMail ? "Incorrect email address" : null}>
                                        <Input
                                            style={{
                                                width: '100%',
                                                border: isValidMail ? "1px solid red" : "1px solid #D1DBE8"

                                            }}
                                            appearance="outline"
                                            size="large"
                                            placeholder="Type your email here"
                                            value={data.email}
                                            onChange={(e) => {
                                                setIsValidMail(false)
                                                setisFormSubmitted(false)
                                                setData({ ...data, email: e.target.value })
                                            }}
                                        />
                                    </Field>
                                </div>

                                <div style={{ marginTop: "1rem" }}>
                                    <Button
                                        style={{ width: '100%', background: "#5E5CE6", color: "white", padding: "8px 16px", borderRadius: "8px" }}
                                        appearance="primary"
                                        onClick={() => { onSubmit(data) }}
                                        disabled={!data.question || !data.email || !data.name}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </form>
                        {
                            isformSubmitted ? <div style={{ fontSize: "1vw" }}> <h3 className={classes.cardHeading} style={{ marginTop: "1em" }}>Thank you for reaching out!</h3>
                                <p>We have received your question and will contact you at the provided email address with a response.</p>
                            </div> : null
                        }
                    </div>
                    <div style={{ width: "100%", marginTop: "2em", marginBottom: "1em", textAlign: "center" }}>
                        <Footer />
                    </div>
                </div>

            </div >
        </div >

    </>
}

const useStyles = makeStyles({
    headerNavBar: {
        position: "sticky",
        top: 0,
        width: "100%",
        backgroundColor: "white",
        zIndex: 999

    },
    faqContainer: {
        maxWidth: "92%",
    },
    primaryHeading: {
        fontSize: "1.5rem",
        fontWeight: 600,
        paddingLeft: "2.5em",
        display: "flex",
        alignItems: "center",
    },
    quesContainer: {
        maxWidth: "60%",
        width: "55%",
    },
    formContainer: {
        maxWidth: "50%",
        width: "42%"
    },
    cardHeading: {
        fontSize: "1.2rem",
        fontWeight: 600,
    },
    info: {
        maxWidth: "65%",
        marginTop: "1em"
    },
    quesText: {
        marginTop: "2rem",
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    mailText: {
        marginBottom: ".5rem",
        fontSize: "1rem !important",
        fontWeight: 600,
    },
    cmpTxt: {
        color: "#586A84"
    }
})
export default FAQs