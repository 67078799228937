
import construction_icon from "../../../assets/icons/landing/construction.svg";
import energy_icon from "../../../assets/icons/landing/energy-grid.svg";
import petrolium_icon from "../../../assets/icons/landing/petrolium.svg";
import mining_icon from "../../../assets/icons/landing/mining.svg";
import forestry_icon from "../../../assets/icons/landing/forestry.svg";

export const AnalyticsData = [
    {
        id: 1,
        title: 'Construction',
        icon: construction_icon,
    }, {
        id: 2,
        title: 'Mining',
        icon: mining_icon,
    },
    {
        id: 3,
        title: 'Utilities management',
        icon: energy_icon,
    },
    {
        id: 4,
        title: 'Infrastructure (road/railways)',
        icon: petrolium_icon,
    },
    {
        id: 5,
        title: 'Forestry',
        icon: forestry_icon,
    },
]