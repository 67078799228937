import { makeStyles, shorthands } from "@fluentui/react-components";
import { useContext } from "react";
import NoFlightsFoundImage from "../../../assets/no_flight_found.png";
import { AuthContext } from "../../../AuthContext";
import { PrimaryButton } from "../../common/PrimaryButton";

type EmptyStateProps = {
  stateMessage: "project" | "flight";
};
export default function EmptyState({ stateMessage }: EmptyStateProps) {
  const classes = useStyles();
  const { setIsNewProjectOpen } = useContext(AuthContext);

  let message;
  if (stateMessage === "project") {
    message =
      "Looks like you're starting fresh! Get started by creating your first project and unlocking the full potential of Flyght Cloud.";
  } else if (stateMessage === "flight") {
    message =
      "Ready to take off? Start by uploading your first flight data to begin analyzing and processing your project in Flyght Cloud.";
  }
  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <img
          style={{ height: "80%" }}
          src={NoFlightsFoundImage}
          alt="No Flights Found"
        />
        <div
          className={classes.subContainer}
          style={{
            flexDirection: "column",
            maxWidth: "40%",
            padding: "2em",
          }}
        >
          <p className={classes.subheading}>{message}</p>
          <div style={{ marginTop: "2rem" }} />
          {stateMessage === "project" && <PrimaryButton label="Add a new project" onClick={() => { setIsNewProjectOpen(true) }} />}
        </div>
      </div>
    </div >
  );
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    ...shorthands.margin("5rem"),
    ...shorthands.gap("3.75rem"),
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "calc(50vh - 3rem)",
  },
  subheading: {
    color: "#586A84",
    marginLeft: "3em",
    fontSize: "14px",
    fontWeight: 600,
  },
  subContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btn: {
    paddingLeft: "2em",
    marginTop: "2em",
    width: "60%",
    paddingRight: "2em",
  },
});
